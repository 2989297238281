import FillFormPage from "./pages/fill-form";
import AddNewFormGroupPage from "./pages/add-form-group";
import RegisterOrganisation from "./pages/register-organization";
import LoginPage from "./pages/login-page";
import LoginEmployeePage from "./pages/login-employee-page";
import PasswordResetPage from "./pages/password-reset";
import PasswordEmployeeResetPage from "./pages/password-employee-reset";
import AddNewPersonsPage from "./pages/add-new-persons";
import FormsPage from "./pages/FormsPage";
import FilledFormsPage from "./pages/FilledFormsPage";
import ActiveFormsPage from "./pages/active-forms-page";
import Main from "./pages/main";
import ProfilePage from "./pages/profile";
import EditPerson from "./pages/edit-person";
import { Persons } from "./persons";
import { FilledFormData } from "./components/filled-form-data";
import { UserFilledFormData } from "./components/filled-form-data/user-filled-form-data";
import PasswordChangePage from "./pages/password-change";
import FilledFormDetails from "./pages/filled-form-details";
import UserAllFilledForms from "./pages/user-all-filled-forms";
import AdminSettingsPage from "./pages/admin-settings-page";
import PasswordResetMailPage from "./pages/password-reset-mail";
import ShoppingCart from "./pages/shopping-cart-page";
import ViewAllData from "./pages/view-all-tests";
// import TestMain from "./pages/sat-form-page";
// import AddNewSet from "./pages/sat-form-page/add-new-set";
// import AddNewTest from "./pages/sat-form-page/add-new-test";
// import FillTest from "./pages/sat-form-page/fill-test";
// import TestResult from "./pages/sat-form-page/result";
import OrganizationTerms from "./pages/organization-terms";
import PasswordResetMailLinkExpiredPage from "./pages/password-reset-link-expired";
import AddNewFormPage from "./pages/add-new-form";
import GroupsPage from "./pages/groups-page";
import ResultsPage from "./pages/results-page";
import { APIKeys } from "./pages/api-keys";

export const noHeaderRoutes = [
  {
    path: "/login",
    component: LoginPage,
    public: true,
  },
  {
    path: "/login-employee",
    component: LoginEmployeePage,
    public: true,
  },
  {
    path: "/register",
    component: RegisterOrganisation,
    public: true,
  },
  {
    path: "/password-reset",
    component: PasswordResetPage,
    public: true,
  },
  {
    path: "/password-employee-reset",
    component: PasswordEmployeeResetPage,
    public: true,
  },
  {
    path: "/password-set-new/:token",
    component: PasswordResetMailPage,
    public: true,
  },
  {
    path: "/password-reset-link-expired",
    component: PasswordResetMailLinkExpiredPage,
    public: true,
  },
];

export const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/settings",
    component: AdminSettingsPage,
  },
  // {
  //   path: "/activated-kits",
  //   component: FormsPage,
  // },
  // {
  //   path: "/filled-kits",
  //   component: FilledKits,
  // },
  // {
  //   path: "/forms",
  //   component: TestMain,
  // },
  // {
  //   path: "/forms/set/add",
  //   component: AddNewSet,
  // },
  // {
  //   path: "/forms/add/:scheduledId",
  //   component: AddNewTest,
  // },
  // {
  //   path: "/forms/fill/:scheduledId",
  //   component: FillTest,
  // },
  {
    path: "/forms",
    component: FormsPage,
  },
  {
    path: "/filled-forms",
    component: FilledFormsPage,
  },
  {
    path: "/groups",
    component: GroupsPage,
  },
  {
    path: "/forms/active",
    component: ActiveFormsPage,
  },
  {
    path: "/forms/add",
    component: AddNewFormPage,
  },
  {
    path: "/forms/edit/:id",
    component: AddNewFormPage,
  },
  {
    path: "/forms/display/:id",
    component: AddNewFormPage,
  },
  {
    path: "/form_group/add",
    component: AddNewFormGroupPage,
  },
  {
    path: "/form_group/edit/:id",
    component: AddNewFormGroupPage,
  },
  // {
  //   path: "/tests/result/:resultId",
  //   component: TestResult,
  // },
  {
    path: "/formdata/add/:formId",
    component: FillFormPage,
  },
  {
    path: "/filled-forms/all/user/:personId",
    component: UserAllFilledForms,
  },
  {
    path: "/formdata/fill-scheduled-test/:formId/:scheduledFormId",
    component: FillFormPage,
  },
  {
    path: "/formdata/edit/:formId/:formdataId",
    component: FillFormPage,
  },
  {
    path: "/person/add",
    component: AddNewPersonsPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/password-change",
    component: PasswordChangePage,
  },
  {
    path: "/persons",
    component: Persons,
  },
  {
    path: "/persons/:personId",
    component: EditPerson,
  },
  {
    path: "/formdata/all/:formId",
    component: FilledFormData,
  },
  {
    path: "/formdata/all/:formId/:personId",
    component: UserFilledFormData,
  },
  {
    path: "/formdata/:formDataId",
    component: FilledFormDetails,
  },
  {
    path: "/formdata/z/:formDataId/:invalidateViewAt",
    component: FilledFormDetails,
  },
  {
    path: "/testdata",
    component: ViewAllData,
  },
  {
    path: "/shopping-cart",
    component: ShoppingCart,
  },
  {
    path: "/terms",
    component: OrganizationTerms,
  },
  {
    path: "/results/:formId",
    component: ResultsPage,
  },
  {
    path: "/results",
    component: ResultsPage,
  },
  {
    path: "/profile/api-keys",
    component: APIKeys,
  },
];
