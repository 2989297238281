import React from "react";
import s from "./index.module.css";

export const ColorBox = ({ color, onClick, title, maxChars = 25 }) => (
  <div
    onClick={onClick}
    className={s.size}
    style={{ backgroundColor: color, fontWeight: "bold" }}
    title={title}
  >
    <span
      style={{
        // marginTop: "20px",
        // marginLeft: "20px",
        // marginRight: "20px",
        // marginBottom: "20px",
        padding: "10px",
        fontWeight: "bold",
        display: "block",
      }}
    >
      {title && title.length > maxChars ? title.substr(0, maxChars) + "..." : title}
    </span>
  </div>
);
