import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addApiKey, deleteApiKey, fetchApiKeys } from "../../redux/actions";
import { SingleApiKey } from "./single-api-key";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import Box from "@material-ui/core/Box";
import { List } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Loader from "../../components/loader";
import { alertAdd } from "../../redux/actions";

export const APIKeys = () => {
  const [keys, setKeys] = useState([]);
  const dispatch = useDispatch();
  const [showAddForm, setShowAddForm] = useState(true);
  const [keyName, setKeyName] = useState("");
  const [deletingKey, setDeletingKey] = useState(false);
  const createKey = async () => {
    await dispatch(
      addApiKey({
        label: keyName,
        expires: new Date(
          new Date().getTime() + 100 * 365 * 24 * 3600 * 1000
        ).toISOString(),
      })
    );

    await getAllApiKeys();
    setKeyName("");
  };

  const getAllApiKeys = async () => {
    const response = await dispatch(fetchApiKeys());
    if (response.status === 200) {
      setKeys(response.data.tokens);
    }
  };

  const removeKey = async (id) => {
    setDeletingKey(true);
    const response = await dispatch(deleteApiKey(id));
    if (response.status === 200) {
      setKeys(keys.filter((k) => k.tokenId !== id));
    }
    let deletingKeyName = keys.find(({ tokenId }) => tokenId === id).label;
    setDeletingKey(false);
    dispatch(
      alertAdd({
        text: "Klucz " + deletingKeyName + " został usunięty.",
        isSuccess: true,
      })
    );
  };

  useEffect(() => {
    getAllApiKeys();
  }, []);

  const page = () => (
    <Box p={1}>
      <PageTitle title="Klucze API" />
      {deletingKey && (
        <Box p={1}>
          <Loader loading={true} text="Usuwanie klucza API..." />
        </Box>
      )}
      {showAddForm && (
        <Box>
          <TextField
            label="Podaj nazwę klucza:"
            variant="outlined"
            size="small"
            value={keyName}
            onChange={({ target: { value } }) => setKeyName(value)}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ margin: "4px" }}
            onClick={createKey}
          >
            ok
          </Button>
        </Box>
      )}
      <List>
        {keys.map((apiKey) => (
          <SingleApiKey
            key={apiKey.tokenId}
            apiKey={apiKey}
            removeKey={removeKey}
          />
        ))}
      </List>
    </Box>
  );
  const redirect = () => <Redirect to="/login" />;

  return <Can permission="apikeys" ok={page} not={redirect} />;
};
