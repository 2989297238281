export const FormFieldChecks = {
  isNumberWithNoType: (field) => {
    return (
      field.dataType === "NUMBER" &&
      (!field.configuration.hasOwnProperty("numberType") ||
        field.configuration.numberType === "")
    );
  },
  isNumberWithInvertedRanges: (field) => {
    return (
      field.dataType === "NUMBER" &&
      field.configuration.numberType !== "" &&
      field.configuration.hasOwnProperty("numberType") &&
      field.configuration.hasOwnProperty("minValue") &&
      field.configuration.minValue &&
      !isNaN(field.configuration.minValue) &&
      field.configuration.hasOwnProperty("maxValue") &&
      field.configuration.maxValue &&
      !isNaN(field.configuration.maxValue) &&
      parseFloat(field.configuration.minValue) >
        parseFloat(field.configuration.maxValue)
    );
  },
  isTextWithNoCharLimit: (field) => {
    return (
      field.dataType === "TEXT" &&
      (!field.configuration.hasOwnProperty("size") ||
        field.configuration.size === null ||
        field.configuration.size === "")
    );
  },
  isTextWithUnpositiveCharLimit: (field) => {
    return (
      field.dataType === "TEXT" &&
      field.configuration.hasOwnProperty("size") &&
      field.configuration.size !== null &&
      field.configuration.size !== "" &&
      parseInt(field.configuration.size) <= 0
    );
  },
  isTextWithUnnaturalCharLimit: (field) => {
    return (
      field.dataType === "TEXT" &&
      field.configuration.hasOwnProperty("size") &&
      field.configuration.size !== null &&
      field.configuration.size !== "" &&
      Math.floor(parseFloat(field.configuration.size)) !==
        parseFloat(field.configuration.size)
    );
  },
  isTextWithNoRowsLimit: (field) => {
    return (
      field.dataType === "TEXT" &&
      field.configuration.hasOwnProperty("maxRows") &&
      (field.configuration.maxRows === null ||
        field.configuration.maxRows === "")
    );
  },
  isTextWithUnpositiveRowsLimit: (field) => {
    return (
      field.dataType === "TEXT" &&
      field.configuration.hasOwnProperty("maxRows") &&
      field.configuration.maxRows !== null &&
      field.configuration.maxRows !== "" &&
      parseInt(field.configuration.maxRows) <= 0
    );
  },
  isTextWithUnnaturalRowsLimit: (field) => {
    return (
      field.dataType === "TEXT" &&
      field.configuration.hasOwnProperty("maxRows") &&
      field.configuration.maxRows !== null &&
      field.configuration.maxRows !== "" &&
      Math.floor(parseFloat(field.configuration.maxRows)) !==
        parseFloat(field.configuration.maxRows)
    );
  },
  isFileWithNoFileLimit: (field) => {
    return (
      field.dataType === "FILE" &&
      (!field.configuration.hasOwnProperty("maxNumberOfFiles") ||
        field.configuration.maxNumberOfFiles === null ||
        field.configuration.maxNumberOfFiles === "")
    );
  },
};
