import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  blue: {
    color: "#0021db",
  },
  yellow: {
    color: "#fff200",
  },
}));

const PageTitle = ({ title }) => {
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);

  return (
    <Typography
      // color="text"
      variant="h6"
      component="h1"
      className={
        !(globalTheme === "high-contrast") ? classes.blue : classes.yellow
      }
    >
      {title}
    </Typography>
  );
};

export default PageTitle;
