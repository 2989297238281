import React, { useState, useEffect, useRef, useMemo } from "react";
import { useParams, useHistory, NavLink, Redirect } from "react-router-dom";
import { getAxiosInstance } from "../../redux/common";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TableSortLabel,
  Paper,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@material-ui/core";
import {
  InputLabel,
  FormControl,
  Input,
  IconButton,
  Popover,
  Tooltip,
} from "@material-ui/core";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import PageTitle from "../../components/page-title";
import { useDispatch } from "react-redux";
import { fetchForm } from "../../redux/actions";
import InfoIcon from "@material-ui/icons/Info";
import Can from "../../components/can";
import { ColorBox } from "../../components/color-box";
import fromEntries from "object.fromentries";
import Cookies from "js-cookie";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: 0,
    minWidth: 80,
    display: "block",
  },
  loading: {
    position: "relative",
    height: 0,
    "& > :first-child": {
      position: "absolute",
      zIndex: 1,
      width: "100%",
      border: 0,
    },
  },
  thinColumn: {
    maxWidth: 125,
    wordWrap: "break-word",
  },
  mediumColumn: {
    maxWidth: 200,
    wordWrap: "break-word",
  },
  columnLabel: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  noMargin: {
    margin: 0,
  },
  userInfoCard: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: theme.spacing(1),
  },
  tableContainer: {
    height: `calc(50vh - 55px)`,
    "@media (min-height: 300px)": {
      height: `calc(54vh - 55px)`,
    },
    "@media (min-height: 350px)": {
      height: `calc(60vh - 55px)`,
    },
    "@media (min-height: 400px)": {
      height: `calc(64vh - 55px)`,
    },
    "@media (min-height: 500px)": {
      height: `calc(70vh - 55px)`,
    },
    "@media (min-height: 600px)": {
      height: `calc(76vh - 55px)`,
    },
    "@media (min-height: 700px)": {
      height: `calc(80vh - 55px)`,
    },
    "@media (min-height: 800px)": {
      height: `calc(82vh - 55px)`,
    },
    "@media (min-height: 900px)": {
      height: `calc(84vh - 55px)`,
    },
  },
}));

class Column {
  id = "";
  header = "";
  isSortable = true;
  filter = null;
  decorator = Column.defaultDecorator();
  parseFilter = Column.defaultParseFilter();
  tooltip = null;
  className = null;

  constructor() {}

  static resolve(path, obj, separator = ".") {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }

  static defaultDecorator() {
    return (data, id) => Column.resolve(id, data);
  }

  static defaultParseFilter() {
    return (value) => ({ contains: value });
  }

  renderFilter(value) {
    if (this.filter) {
      return this.filter(value, this.id);
    } else {
      return null;
    }
  }

  renderData(data) {
    if (this.decorator) {
      return this.decorator(data, this.id);
    } else {
      return null;
    }
  }
}

class ColumnBuilder {
  #column;

  constructor() {
    this.reset();
  }

  reset() {
    this.#column = new Column();
  }

  build() {
    let column = this.#column;
    this.reset();
    return column;
  }

  setId(id) {
    this.#column.id = id;
    return this;
  }
  setHeader(header) {
    this.#column.header = header;
    return this;
  }
  setSortable(isSortable) {
    this.#column.isSortable = isSortable;
    return this;
  }
  setFilter(filter) {
    this.#column.filter = filter;
    return this;
  }
  setDecorator(decorator) {
    this.#column.decorator = decorator;
    return this;
  }
  setParseFilter(parseFilter) {
    this.#column.parseFilter = parseFilter;
    return this;
  }
  setTooltip(tooltip) {
    this.#column.tooltip = tooltip;
    return this;
  }
  setClassName(className) {
    this.#column.className = className;
    return this;
  }
}

let reloadTimeout = null;

const emptyResults = {
  data: [],
  pagination: {
    total: 0,
  },
};

const UserInfo = ({ user, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const userName =
    user.name ||
    ((user.firstName && `${user.firstName} `) || "") +
      (user.surname && `${user.surname} `);
  const mail = user.user.email;
  const phone = user.user.phone;
  const hospitalIdentifier = user.hospitalIdentifier;
  const text = (
    <>
      {userName && `${userName} `}
      {userName && <br />}
      {mail && `e-mail: ${mail}`}
      {mail && <br />}
      {phone && `tel: ${phone} `}
    </>
  );
  const history = useHistory();

  return (
    <>
      <IconButton
        color="primary"
        aria-label="informacje o użytkowniku"
        onClick={handleClick}
      >
        <InfoIcon />
      </IconButton>
      {text}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card className={classes.userInfoCard}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Użytkownik
            </Typography>
            <Typography variant="h5" component="h2">
              {userName}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {mail && `e-mail: ${mail}, `} {phone && `tel: ${phone} `}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              {hospitalIdentifier &&
                `szpitalny identyfikator: ${hospitalIdentifier}`}
            </Typography>
            <Typography className={classes.title} color="textSecondary">
              Opis
            </Typography>
            <Typography variant="body2" component="p">
              {user.description}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => history.push(`/persons/${user.id}`)}
            >
              Więcej
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

const ResultRow = ({ columnsDefinition, result }) => {
  return useMemo(
    () => (
      <TableRow>
        {columnsDefinition.map((column) => {
          return (
            <TableCell className={column.className} key={column.id}>
              {column.id && column.renderData(result)}
            </TableCell>
          );
        })}
      </TableRow>
    ),
    [columnsDefinition, result]
  );
};

const Results = () => {
  const classes = useStyles();
  const { formId } = useParams();
  const dispatch = useDispatch();

  const [columnsDefinition] = useState(() => {
    const columnBuilder = new ColumnBuilder();
    const inputFilter = (type = "text") => (value, id) => {
      return (
        <Input
          id={`${id}-filter-input`}
          type={type}
          name={id}
          value={value}
          fullWidth
          style={{ backgroundColor: "whitesmoke" }}
          onChange={handleFilterChange}
        />
      );
    };
    const notFilledText = "<Brak wypełnienia>";
    const groupAndFormColumns = formId
      ? []
      : [
          columnBuilder
            .setId("group.name")
            .setHeader("Project")
            .setFilter(inputFilter())
            .build(),
          columnBuilder
            .setId("form.name")
            .setHeader("Formularz")
            .setFilter(inputFilter())
            .build(),
        ];
    return [
      ...groupAndFormColumns,
      columnBuilder
        .setId("text")
        .setHeader("Komunikat")
        .setClassName(classes.thinColumn)
        .setFilter(inputFilter())
        .setDecorator((result) => {
          const isFilled = result.state !== "MISSED";
          const color = isFilled ? result.color || "white" : "grey";
          let text = isFilled ? result.text : notFilledText;
          if (isFilled && result.formDataId) {
            return (
              <div>
                <NavLink
                  to={`/formdata/${result.formDataId}`}
                  style={{ color: "black" }}
                >
                  <ColorBox color={color} title={text} />
                </NavLink>
              </div>
            );
          } else {
            return (
              <div>
                <ColorBox color={color} title={text} />
              </div>
            );
          }
        })
        .build(),
      columnBuilder
        .setId("totalWeight")
        .setHeader("Punkty")
        .setClassName(classes.thinColumn)
        .setFilter(inputFilter("number"))
        .setDecorator((result) => {
          const isFilled = result.state !== "MISSED";
          const color = isFilled ? result.color || "black" : "grey";
          return (
            <span style={{ color: color }}>
              {isFilled
                ? parseFloat(result.totalWeight.toFixed(2))
                : notFilledText}
            </span>
          );
        })
        .setParseFilter((value) => ({
          is: parseInt(value),
        }))
        .build(),
      columnBuilder
        .setId("filledBy.hospitalIdentifier")
        .setHeader("SU ID")
        .setClassName(classes.mediumColumn)
        .setFilter(inputFilter())
        .setTooltip("Identyfikator Szpitalny")
        .build(),
      columnBuilder
        .setId("completedAt")
        .setHeader("Data wypełnienia")
        .setClassName(classes.mediumColumn)
        .setFilter(inputFilter("date"))
        .setDecorator((result) =>
          result.state !== "MISSED" && result.completedAt
            ? format(new Date(result.completedAt), "yyyy-MM-dd HH:mm")
            : notFilledText
        )
        .setParseFilter((value) => ({
          between: [
            startOfDay(new Date(value)).toISOString(),
            endOfDay(new Date(value)).toISOString(),
          ],
        }))
        .build(),
      columnBuilder
        .setId("filledBy.name")
        .setHeader("Wypełniony przez")
        .setFilter(inputFilter())
        .setDecorator((result) => (
          <UserInfo user={result.filledBy} classes={classes} />
        ))
        .build(),
      columnBuilder
        .setId("filledFormsPerPersonCount")
        .setHeader("Liczba odpowiedzi")
        .setClassName(classes.thinColumn)
        .setFilter(inputFilter("number"))
        .setDecorator((result) => (
          <>
            {result.form && result.filledBy && (
              <NavLink
                to={{
                  pathname: `/formdata/all/${result.form.id}/${result.filledBy.id}`,
                  state: { filledPerUser: result.filledFormsPerPersonCount },
                }}
              >
                {result.filledFormsPerPersonCount}
              </NavLink>
            )}
          </>
        ))
        .setParseFilter((value) => ({
          is: parseInt(value),
        }))
        .build(),
    ];
  });
  const [results, setResults] = useState(emptyResults);
  const [form, setForm] = useState({
    id: formId,
    name: "",
    formGroup: { name: "" },
  });
  const [filter, setFilter] = useState(
    fromEntries(columnsDefinition.map((column) => [column.id, ""]))
  );
  const [sort, setSort] = useState({
    orderBy: "totalWeight",
    order: "-",
  });
  const my = useSelector((s) => s.my);
  const [resultsPerPage, setResultsPerPage] = useState(
    Cookies.get(`results-per-page[${my.user.id}]`) || 10
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [busy, setBusy] = useState(false);
  const isInitialMount = useRef(true);
  const columnsNumber = columnsDefinition.length;

  const reloadThrottled = () => {
    const reload = () => {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        loadResults();
      }
    };
    if (reloadTimeout) {
      clearTimeout(reloadTimeout);
    }

    reloadTimeout = setTimeout(reload, 700);
  };

  const loadResults = () => {
    let filterParam = {};

    if (formId) {
      filterParam = {
        ...filterParam,
        ["form.id"]: { is: parseInt(formId) },
      };
    }

    for (const column of columnsDefinition) {
      if (filter[column.id]) {
        filterParam[column.id] = column.parseFilter(filter[column.id]);
      }
    }

    let params = {
      page: currentPage,
      pageSize: resultsPerPage,
      sort: sort.order + sort.orderBy,
    };
    if (!(Object.keys(filterParam).length === 0)) {
      params.q = JSON.stringify(filterParam);
    }

    setBusy(true);
    getAxiosInstance()
      .post("/api/form_data/latest_for_projects_and_users", params)
      .then((resultsResult) => {
        const results = resultsResult.data;
        setResults(results);
      })
      .catch((e) => {
        console.error("results-page error", e);
        setResults(emptyResults);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  const handleFilterChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFilter((filter) => ({
      ...filter,
      [name]: value,
    }));
  };

  const handleSortChange = (property) => () => {
    setSort((sort) => {
      const isAsc = sort.orderBy === property && sort.order === "";
      return {
        orderBy: property,
        order: isAsc ? "-" : "",
      };
    });
  };

  useEffect(() => {
    loadResults();
  }, [resultsPerPage, currentPage, sort]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      reloadThrottled();
    }
  }, [filter]);

  useEffect(() => {
    if (formId) {
      dispatch(fetchForm(formId)).then((form) => {
        if (form) {
          setForm(form);
        }
      });
    }
    return () => {
      if (reloadTimeout) {
        clearTimeout(reloadTimeout);
        reloadTimeout = null;
      }
    };
  }, []);

  return (
    <>
      <Box p={1}>
        {formId ? (
          <>
            {form.formGroup && (
              <PageTitle title={`Projekt: ${form.formGroup.name}`} />
            )}
            <PageTitle title={`Formularz: ${form.name}`} />
          </>
        ) : (
          <PageTitle title="Formularze" />
        )}
      </Box>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} size="small" aria-label="results list">
          <TableHead>
            <TableRow>
              {columnsDefinition.map((column) => {
                const title = (
                  <>
                    {" "}
                    {column.tooltip ? (
                      <Tooltip title={column.tooltip} arrow>
                        <span>{column.header}</span>
                      </Tooltip>
                    ) : (
                      column.header
                    )}{" "}
                  </>
                );
                return (
                  <TableCell className={column.className} key={column.id}>
                    {column.isSortable ? (
                      <TableSortLabel
                        className={classes.columnLabel}
                        active={sort.orderBy === column.id}
                        direction={sort.order ? "desc" : "asc"}
                        onClick={handleSortChange(column.id)}
                      >
                        {title}
                      </TableSortLabel>
                    ) : (
                      title
                    )}
                    {column.filter && (
                      <FormControl className={classes.formControl}>
                        <InputLabel id={`${column.id}-filter-label`} />
                        {column.renderFilter(filter[column.id])}
                      </FormControl>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {busy && (
              <TableRow className={classes.loading}>
                <TableCell colSpan={columnsNumber}>
                  <Loader loading={true} text="Wczytywanie danych" />
                </TableCell>
              </TableRow>
            )}
            {results.data.map((result) => {
              return (
                <ResultRow
                  key={result.filledBy.id + "/" + result.form.id}
                  columnsDefinition={columnsDefinition}
                  result={result}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="td"
                colSpan={columnsNumber}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} z ${count}`
                }
                labelRowsPerPage="Liczba wyników na stronę:"
                count={results.pagination.total}
                rowsPerPage={resultsPerPage}
                page={currentPage}
                onChangePage={(e, p) => {
                  setCurrentPage(p);
                }}
                onChangeRowsPerPage={(e) => {
                  Cookies.set(
                    `results-per-page[${my.user.id}]`,
                    e.target.value,
                    {
                      sameSite: "strict",
                    }
                  );
                  setResultsPerPage(e.target.value);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

const ResultsPage = () => {
  return (
    <Can
      permission="results-page:view"
      ok={() => <Results />}
      not={() => <Redirect to="/" />}
    />
  );
};

export default ResultsPage;
