import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import Loader from "../../components/loader";
import { alertAdd, login } from "../../redux/actions";
import Cookies from "js-cookie";
import s from "./index.module.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Alert from "@material-ui/lab/Alert";
import ApiVersionSpan from "../../components/api-version-span/api-version-span";
import UnsupportedBrowserAlert from "../../components/unsupported-browser-alert";

const RegisterButton = styled(Button)`
  color: white;
  background-color: orange;
`;

const LoginEmployeePage = ({
  login,
  history,
  alertAdd,
  loggingIn,
  loggedIn,
}) => {
  const [username, setUsername] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);

  const [loginAttemptCounter, setLoginAttemptCounter] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [loginAttemptsLeft, setLoginAttemptsLeft] = useState(0);

  const rememberMeLogin = Cookies.get("rememberMeLogin");

  const [anchorEl, setAnchorEl] = useState(null);
  const [hostname, setHostname] = useState("");

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setHostname(window.location.hostname);
    if (loggedIn) {
      history.push("/");
    } else {
      setUsername(rememberMeLogin);
    }
  }, [loggedIn]);

  useEffect(() => {
    let valid = username && password;
    if (username) {
      const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(username).toLowerCase()
      );
      setEmailValid(emailValid);
      valid = valid && emailValid;
    }
    setValid(valid);
  }, [username, password]);

  const doLogin = async (e) => {
    e.preventDefault();

    let response = {};

    response = await login(
      {
        username,
        password,
      },
      "employee"
    );

    if (response.status === 200) {
      // history.push('/')
    } else if (response.data.name === "not activated") {
      alertAdd({
        text: "Użytkownik nie został aktywowany",
        isError: true,
      });
    } else if (response.data.name === "InvalidEmailError") {
      if (!emailValid) {
        alertAdd({
          text: "Nieprawidłowy format adresu email",
          isError: true,
        });
      } else {
        alertAdd({
          text: "Nieprawidłowy adres email lub hasło",
          isError: true,
        });
      }
    } else if (
      response.data.name === "EmployeeBlockedError" ||
      response.data.attemptsLeft === 0
    ) {
      setDisplayAlert(false);
      alertAdd({
        text: "Konto tymczasowo zablokowane",
        isError: true,
      });
    } else {
      if (response.data.attemptsLeft - 1 > 3 || !response.data.attemptsLeft) {
        setLoginAttemptCounter(loginAttemptCounter + 1);
        alertAdd({
          text: (
            <span>
              Spróbuj poprawnie wpisać hasło lub{" "}
              <a href="/password-employee-reset">
                <strong>Ustaw nowe hasło</strong>
              </a>
            </span>
          ),
          isError: true,
        });
      } else {
        setDisplayAlert(true);
        setLoginAttemptsLeft(response.data.attemptsLeft - 1);
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/");
  };

  return (
    <Can
      permission="login:view"
      ok={() => {
        return (
          <>
            <UnsupportedBrowserAlert />
            <Grid container>
              <Grid item sm={12} xs={12} md={8}>
                <LeftComponentMui />
              </Grid>

              <Grid item sm={12} xs={12} md={4}>
                <Box p={1}>
                  {displayAlert && (
                    <Box textAlign="center">
                      <Alert severity="error">
                        <span>
                          Spróbuj poprawnie wpisać hasło lub{" "}
                          <a href="/password-employee-reset">
                            <strong>Ustaw nowe hasło</strong>
                          </a>
                        </span>
                        <br />
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          Pozostało prób przed zablokowaniem konta:{" "}
                          <strong>{loginAttemptsLeft}</strong>
                        </span>
                      </Alert>
                    </Box>
                  )}

                  <Box textAlign="center">
                    <LockOpen />
                  </Box>

                  <Box as="h1" fontSize={20} textAlign="center">
                    Zaloguj się
                  </Box>

                  <form onSubmit={doLogin} noValidate autoComplete="off">
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></Box>
                    <Box display="flex">
                      <TextField
                        error={!emailValid && !!username}
                        helperText={
                          !emailValid && username
                            ? "Nieprawidłowy format adresu email"
                            : ""
                        }
                        label="Email"
                        variant="outlined"
                        value={username || ""}
                        fullWidth
                        required
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </Box>
                    <Box mt={1}>
                      <TextField
                        label="Hasło"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                aria-label="toggle password visibility"
                              >
                                <Visibility />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box
                      m={1}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <span className={s.hintText}>{"* Pole wymagane"}</span>
                      {/* <Box textAlign="right" fontSize={12} m={1}> */}
                      <NavLink
                        style={{ fontSize: "12px" }}
                        to="/password-employee-reset"
                      >
                        Ustaw nowe hasło
                      </NavLink>
                      {/* </div></Box> */}
                    </Box>
                    <Button
                      type="submit"
                      variant={"contained"}
                      color="primary"
                      fullWidth
                      disabled={!valid}
                    >
                      Zaloguj
                    </Button>
                    <Loader loading={loggingIn} text="Logowanie..." />
                    <hr />
                    {/* Nie masz konta?{" "}
                  <NavLink to="/register">
                    <RegisterButton variant={"contained"} size="small">
                      Zarejestruj się
                    </RegisterButton>
                  </NavLink> */}
                  </form>
                  <a
                    href="/org-media/files/public/regulamin.pdf"
                    target="_blank"
                    className="nav-link"
                  >
                    Regulamin
                  </a>
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  m={2}
                >
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="primary"
                    variant="contained"
                    onClick={handleInstructionClick}
                  >
                    INSTRUKCJA
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        window.open(
                          "/org-media/files/public/instrukcja_koordynatora.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Instrukcja dla{" "}
                      {process.env.REACT_APP_USE_PATIENT === "true"
                        ? "lekarza "
                        : "redaktora "}
                      PDF
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            <ApiVersionSpan />
            </Grid>
          </>
        );
      }}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  activeForms: state.activeForms,
  my: state.my,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data, loginType) => dispatch(login(data, loginType)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginEmployeePage);
