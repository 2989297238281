import React, { useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import MuiTableCell from "@material-ui/core/TableCell";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { buyCartItem, removeCartItem } from "../../redux/shopping-cart/actions";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { alertAdd } from "../../redux/actions";
import AppBarTitle from "../../components/app-bar-title";

const TYPE_TO_NAME_MAP = {
  SMELLTASTE_TEST: "Węch i smak",
  SMELL_TEST: "Węch",
  TASTE_TEST: "Smak",
};

const TableCell = withStyles({
  root: {
    borderBottom: "1px solid black",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },
})(MuiTableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    overflowX: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: "#ff726f !important",
  },
}));

const ShoppingCart = ({
  cartItems,
  buyCartItem,
  removeCartItem,
  alertAdd,
  history,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(null);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const productTypes = [
    {
      type: "TASTE_TEST",
      valueLabel: "SMAK",
    },
    {
      type: "SMELL_TEST",
      valueLabel: "WĘCH",
    },
    {
      type: "SMELLTASTE_TEST",
      valueLabel: "WĘCH I SMAK",
    },
  ];

  const dataNotProvided = (item) => {
    return (
      !item.deliveryInfo ||
      item.deliveryInfo.name === "" ||
      item.deliveryInfo.street === "" ||
      item.deliveryInfo.zipCode === "" ||
      item.deliveryInfo.city === ""
    );
  };
  const wait500ms = async () => {
    await delay(500);
  };

  const page = () => (
    <Box>
      <AppBarTitle value="Koszyk zamówień" />
      {cartItems && cartItems.length === 0 ? (
        <Can
          permission="test-kit:page"
          ok={() => (
            <Box>
              <Box mt={1}>
                <Typography variant="subtitle1">
                  Twój koszyk jest pusty.{" "}
                  <NavLink to="/test-kit">Złóż zamówienie.</NavLink>
                </Typography>
              </Box>
            </Box>
          )}
          not={() => <div></div>}
        />
      ) : (
        <Box>
          <Grid item xs={8}>
            <Box mt={1}>
              <Typography variant="subtitle1">
                <NavLink to="/test-kit">Złóż nowe zamówienie.</NavLink>
              </Typography>
            </Box>
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Zawartość</TableCell>
                    <TableCell align="right">Dane adresowe</TableCell>
                    <TableCell align="right">Dane do faktury</TableCell>
                    <TableCell align="right">Opcje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((item, id) => {
                    item = { ...item, dataNotProvided: dataNotProvided(item) };
                    return (
                      <TableRow
                        key={id}
                        className={item.dataNotProvided ? classes.tableRow : ""}
                      >
                        <TableCell component="th" scope="row">
                          {item.elements.map((e, index) => (
                            <span key={e.testType}>
                              {TYPE_TO_NAME_MAP[e.testType]} x {e.amount}
                              {index !== item.elements.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          {item.deliveryInfo && item.deliveryInfo.name}
                        </TableCell>

                        <TableCell align="right">
                          {item.billingInfo && item.billingInfo.name}
                        </TableCell>

                        <TableCell>
                          <ButtonGroup>
                            <Tooltip
                              placement="top"
                              title={
                                item.dataNotProvided
                                  ? "Uzupełnij dane adresowe"
                                  : ""
                              }
                            >
                              <span dataTipDisable={false}>
                                <Button
                                  style={
                                    item.dataNotProvided
                                      ? {
                                          borderRadius: 20,
                                          backgroundColor: "#cccccc",
                                          color: "#666666",
                                        }
                                      : {
                                          borderRadius: 20,
                                          backgroundColor: "#476bff",
                                          color: "white",
                                        }
                                  }
                                  variant="contained"
                                  size="small"
                                  disabled={
                                    item.dataNotProvided ||
                                    (selectedItemID === item.id && loading)
                                  }
                                  onClick={async () => {
                                    alertAdd({
                                      text: "Dokonywanie zakupu",
                                      timeout: 1000,
                                    });
                                    setSelectedItemID(item.id);
                                    setLoading(true);
                                    await buyCartItem(item.id);
                                    await wait500ms();
                                    setLoading(false);
                                    history.push("/orders");
                                  }}
                                >
                                  {loading && selectedItemID === item.id && (
                                    <CircularProgress
                                      size={14}
                                      style={{ color: "white" }}
                                    />
                                  )}
                                  {!(loading && selectedItemID === item.id) &&
                                    "Kup"}
                                </Button>
                              </span>
                            </Tooltip>
                            <Button
                              style={{
                                borderRadius: 20,
                                backgroundColor: "#476bff",
                                color: "white",
                                marginLeft: "5px",
                              }}
                              variant="contained"
                              size="small"
                              onClick={() => {
                                history.push({
                                  pathname: "/test-kit",
                                  state: {
                                    detail: item,
                                    cartItemEdition: true,
                                  },
                                });
                              }}
                            >
                              Edytuj
                            </Button>

                            <Button
                              style={{
                                borderRadius: 20,
                                backgroundColor: "#476bff",
                                color: "white",
                                marginLeft: "5px",
                              }}
                              variant="contained"
                              size="small"
                              onClick={() => removeCartItem(item.id)}
                            >
                              Usuń
                            </Button>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Box>
      )}
    </Box>
  );
  const redirect = () => <Redirect to="/login" />;

  return <Can permission="shopping-cart:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  cartItems: state.cartItems,
});

const mapDispatchToProps = (dispatch) => ({
  removeCartItem: (cartItemId) => dispatch(removeCartItem(cartItemId)),
  buyCartItem: (cartItemId) => dispatch(buyCartItem(cartItemId)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
