import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchOrganizationCurrentTerms } from "../../redux/organization/actions";
import { logout } from "../../redux/actions";
import TermsAcceptance from "../terms-acceptance";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    textAlign: "center",
    overflowY: "scroll",
  },
  modalBox: {
    display: "inline-block",
    top: "20px",
    transform: "translate(0%, 50%)",
  },
  logoutBtn: {
    float: "right",
    backgroundColor:
      theme.palette.primary.main === "#fff200" ? "black" : "white",
    border:
      theme.palette.primary.main === "#fff200"
        ? "2px solid yellow"
        : "2px solid #000",
  },
  modalBox2: {
    padding: theme.spacing(3),
    textAlign: "center",
    backgroundColor:
      theme.palette.primary.main === "#fff200" ? "black" : "white",
    border:
      theme.palette.primary.main === "#fff200"
        ? "2px solid yellow"
        : "2px solid #000",
  },
}));

const ModalTermsAcceptance = () => {
  const my = useSelector((s) => s.my);
  const organization = useSelector((s) => s.organization);
  const orgCurrentTerms = useSelector((s) => s.orgCurrentTerms);
  const hasUnacceptedTerms =
    my?.user.authorities.localeCompare("ROLE_USER_NO_ACCEPTED_TERMS") === 0;
  const [ready, setReady] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasUnacceptedTerms && organization?.id) {
      dispatch(fetchOrganizationCurrentTerms(organization.id));
    }
  }, [organization?.id, hasUnacceptedTerms]);

  useEffect(() => {
    orgCurrentTerms && setReady(true);
  }, [orgCurrentTerms]);

  if (!hasUnacceptedTerms) {
    return null;
  }

  return (
    ready && (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalStyle}
      >
        <Box className={classes.modalBox}>
          <Button
            className={classes.logoutBtn}
            variant="outlined"
            onClick={() => {
              dispatch(logout({ isUser: true }));
            }}
          >
            Wyloguj
          </Button>
          <Box className={classes.modalBox2}>
            <Typography variant="h6" style={{ clear: "right" }}>
              Prosimy zaakceptować regulamin serwisu. Brak akceptacji
              uniemożliwi korzystanie z serwisu.
            </Typography>
            {orgCurrentTerms ? (
              <TermsAcceptance
                terms={orgCurrentTerms}
                acceptance={true}
                future={false}
              ></TermsAcceptance>
            ) : null}
          </Box>
        </Box>
      </Modal>
    )
  );
};

export default ModalTermsAcceptance;
