import React from "react";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  alert: {
    justifyContent: "center",
    alignItems: "center",
    fontSize:
      theme.typography.body1.fontSize === "1.17rem"
        ? `calc(0.95 * ${theme.typography.body2.fontSize})`
        : theme.typography.body1.fontSize === "1.5rem"
        ? `calc(0.83 * ${theme.typography.body2.fontSize})`
        : "",
  },
  alertHighContrast: {
    color: "#fff200",
    backgroundColor: "inherit",
    border: "2px solid #fff200",
    "& .MuiAlert-icon": {
      color: "#fff200",
    },
  },
  alertHighContrastForHeader: {
    color: "black",
    backgroundColor: "inherit",
    border: "1px solid black",
    "& .MuiAlert-icon": {
      color: "black",
    },
  },
  nonePadding: {
    padding: "0px",
  },
}));

const UnsupportedBrowserAlert = ({ unsupportedBrowser, forHeader = false }) => {
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const alertMessage = `Korzystasz z niewspieranej wersji przeglądarki, niektóre funkcje mogą nie działać prawidłowo. ${
    !forHeader ? "Rekomendujemy zaktualizowanie do najnowszej wersji." : ""
  }`;

  return unsupportedBrowser ? (
    <Tooltip title="Rekomendujemy zaktualizowanie przeglądarki do najnowszej wersji.">
      <Alert
        severity="warning"
        className={`${classes.alert} ${forHeader ? classes.nonePadding : ""} ${
          globalTheme === "high-contrast"
            ? forHeader
              ? classes.alertHighContrastForHeader
              : classes.alertHighContrast
            : ""
        }`}
        classes={{
          message: `${classes.alert} ${forHeader ? classes.nonePadding : ""}`,
        }}
      >
        {alertMessage}
      </Alert>
    </Tooltip>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({
  unsupportedBrowser: state.unsupportedBrowser,
});

export default connect(mapStateToProps, null)(UnsupportedBrowserAlert);
