import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { alertAdd, fetchAllFormsGroups } from "../../redux/actions";
import { getAxiosInstance } from "../../redux/common";
import { checkPermission } from "../../rbac";
import Loader from "../../components/loader";

const MAX_GROUP_AND_FORM_NAME_LENTGH = 25;

const GroupsAndFormsPermissionsDialog = ({
  person,
  fetchAllFormsGroups,
  open,
  handleClose,
  history,
}) => {
  const roles = useSelector((s) => s.roles);
  const formsGroups = useSelector((s) => s.orgFormsGroups);
  const canFetchGroups = checkPermission("groups:fetch", roles);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [forms, setForms] = useState({});
  const [allowedForms, setAllowedForms] = useState([]);
  const [disallowedForms, setDisallowedForms] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (canFetchGroups) {
      fetchAllFormsGroups();
    }
  }, [canFetchGroups]);

  useEffect(() => {
    if (currentGroup === null && selectedGroups.length > 0) {
      setCurrentGroup(selectedGroups.at(-1).id);
    }
  }, [selectedGroups]);

  const handleClosePanel = () => {
    setSelectedGroups([]);
    setAllowedForms([]);
    setDisallowedForms([]);
    setCurrentGroup(null);
    handleClose();
    history.push("/persons");
  };

  const unselectAllFormsForGroup = (groupId) => {
    forms[groupId].map(({ id }) => {
      allowedForms.find((form) => form === id) && removeFromAllowedForms(id);
      disallowedForms.find((form) => form === id) &&
        removeFromDisallowedForms(id);
    });
  };

  const selectGroup = (group) => {
    if (!Object.keys(forms).find((f) => f === group.id)) {
      getAxiosInstance()
        .get(`/api/form_group/${group.id}/forms`)
        .then((result) => {
          setForms((prevState) => ({
            ...prevState,
            [group.id]: result.data,
          }));
        });
    }
    setCurrentGroup(group.id);
    setSelectedGroups((prevState) => [...prevState, group]);
  };

  const unselectGroup = (group) => {
    setSelectedGroups((prevState) =>
      prevState.filter(({ id }) => id !== group.id)
    );
    unselectAllFormsForGroup(group.id);
    currentGroup === group.id && setCurrentGroup(null);
  };

  const addToAllowedForms = (formId) => {
    setAllowedForms((prevState) => [...prevState, formId]);
    disallowedForms.find((form) => form === formId) &&
      removeFromDisallowedForms(formId);
  };

  const addToDisallowedForms = (formId) => {
    setDisallowedForms((prevState) => [...prevState, formId]);
    allowedForms.find((form) => form === formId) &&
      removeFromAllowedForms(formId);
  };

  const removeFromAllowedForms = (formId) => {
    setAllowedForms((prevState) => prevState.filter((form) => form !== formId));
  };

  const removeFromDisallowedForms = (formId) => {
    setDisallowedForms((prevState) =>
      prevState.filter((form) => form !== formId)
    );
  };

  const save = async () => {
    const groupsIds = selectedGroups.map(({ id }) => id);
    const forms = {
      allow: allowedForms,
      disallow: disallowedForms,
    };

    await getAxiosInstance()
      .post(`/api/form_group/person_to_groups/${person.id}`, groupsIds)
      .then(
        (result) => {
          getAxiosInstance()
            .put(`/api/forms/person_to_forms/${person.id}`, forms)
            .then(
              (result2) => {
                dispatch(
                  alertAdd({
                    text:
                      "Edycja uprawnień do projektów i formularzy przebiegła pomyślnie.",
                    isSuccess: true,
                  })
                );
                handleClosePanel();
              },
              (reason) => {
                dispatch(
                  alertAdd({
                    text: "Błąd edycji uprawnień do projektów i formularzy.",
                    isError: true,
                  })
                );
                handleClosePanel();
              }
            );
        },
        (reason) => {
          dispatch(
            alertAdd({
              text: "Błąd edycji uprawnień do projektów i formularzy.",
              isError: true,
            })
          );
          handleClosePanel();
        }
      );
  };

  const displayName = (name) => {
    return name.length > MAX_GROUP_AND_FORM_NAME_LENTGH ? (
      <Tooltip title={name}>
        <div>{name.substr(0, MAX_GROUP_AND_FORM_NAME_LENTGH) + "..."}</div>
      </Tooltip>
    ) : (
      name
    );
  };

  const FormRow = ({ form, group }) => {
    return (
      <TableRow key={form.id}>
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!allowedForms.find((id) => id === form.id)}
                onChange={(e) =>
                  e.target.checked
                    ? addToAllowedForms(form.id)
                    : removeFromAllowedForms(form.id)
                }
                color="primary"
              />
            }
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!disallowedForms.find((id) => id === form.id)}
                onChange={(e) =>
                  e.target.checked
                    ? addToDisallowedForms(form.id)
                    : removeFromDisallowedForms(form.id)
                }
                color="primary"
              />
            }
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            disabled
            control={
              <Checkbox checked={group.enableAllFormAccess} color="primary" />
            }
          />
        </TableCell>
        <TableCell>{form.name}</TableCell>
      </TableRow>
    );
  };

  const SingleGroup = ({ children, value, index }) => {
    return (
      <div hidden={value !== index} id={`single-group-${index}`}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  const FormsPanel = () => {
    return (
      <Box>
        {selectedGroups.map((group) => {
          return (
            <SingleGroup key={group.id} value={currentGroup} index={group.id}>
              {forms[group.id] !== undefined ? (
                forms[group.id].length > 0 ? (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Przydziel dostęp</TableCell>
                        <TableCell>Zabierz dostęp</TableCell>
                        <TableCell>Dostęp przydzielony automatycznie</TableCell>
                        <TableCell>Nazwa formularza</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {forms[group.id].map((form) => (
                        <FormRow key={form.id} form={form} group={group} />
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  "Brak formularzy dla tego projektu"
                )
              ) : (
                <Loader loading={true} text="Wczytywanie danych" />
              )}
            </SingleGroup>
          );
        })}
      </Box>
    );
  };

  const personData = `${person.name ? person.name : ""} ${
    person.mail ? `(${person.mail})` : ""
  }`;

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      onClose={(reason) => {
        if (!reason === "backdropClick") {
          handleClosePanel();
        }
      }}
    >
      <DialogTitle>
        <div>
          <Typography variant="h6" gutterBottom>
            Panel edycji uprawnień do projektów i formularzy użytkownika{" "}
            {personData}
          </Typography>
        </div>
      </DialogTitle>
      <Grid container justify="center">
        <Grid item container xs={5} alignItems="center" direction="column">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Projekty
            </Typography>
          </Grid>
          <Grid item>
            <Box>
              {formsGroups.map((group) => (
                <div key={group.id}>
                  <Checkbox
                    checked={!!selectedGroups.find(({ id }) => id === group.id)}
                    onChange={(e) =>
                      e.target.checked
                        ? selectGroup(group)
                        : unselectGroup(group)
                    }
                    color="primary"
                  />
                  <Button
                    onClick={() => setCurrentGroup(group.id)}
                    disabled={!selectedGroups.find(({ id }) => id === group.id)}
                    style={{
                      textTransform: "none",
                      backgroundColor:
                        currentGroup === group.id ? "lightgrey" : "",
                    }}
                  >
                    {displayName(group.name)}
                  </Button>
                </div>
              ))}
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={1} justify="center">
          <Divider orientation="vertical" />
        </Grid>
        <Grid item container xs={5} alignItems="center" direction="column">
          <Typography variant="h6" gutterBottom>
            Formularze
          </Typography>
          <FormsPanel />
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          type="cancel"
          variant="contained"
          onClick={() => {
            setSelectedGroups([]);
            setAllowedForms([]);
            setDisallowedForms([]);
            setCurrentGroup(null);
            handleClosePanel();
            history.push("/persons");
          }}
        >
          Anuluj edycję uprawnień
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={save}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllFormsGroups: () => dispatch(fetchAllFormsGroups()),
});

export default connect(
  null,
  mapDispatchToProps
)(GroupsAndFormsPermissionsDialog);
