import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// dragaMode = either 'clone' or 'move'

const useStyles = makeStyles((theme) => ({
  muiTextarea: {
    background: "white",
  },
  isDanger: {
    borderColor: "#ff3860",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "solid lightgrey",
  },
}))(TextField);

const TextControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.TEXT,
      dragMode,
      defaultLabel: "Tekst",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const globalTheme = useSelector((s) => s.globalTheme);
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const [value, setValue] = useState(filledValue || "");
  useEffect(() => {
    setValue(filledValue);
  }, [filledValue]);

  const classes = useStyles();

  const clicked = () => {
    markSelected &&
      markSelected({
        id,
        dataType: ItemSubtypes.TEXT,
        configuration,
      });
  };

  const updateValue = (value) => {
    setValue(value);
    if (onChange) {
      onChange({
        id,
        value,
      });
    }
  };

  const renderInputField = () => {
    return (
      <>
        <CssTextField
          placeholder={placeholder}
          id={id}
          value={value || ""}
          onChange={({ target: { value } }) => {
            if (configuration?.size && value.length <= configuration.size) {
              updateValue(value);
            }
          }}
          onClick={clicked}
          disabled={disabled}
          fullWidth
          variant={"outlined"}
          size="small"
          error={
            checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id)
          }
          // className={
          //   checkBlankedFields &&
          //   blankedRequiredFields.find((fieldId) => fieldId === id)
          //     ? "input is-danger"
          //     : "input"
          // }
          placeholder={placeholder}
          maxLength={configuration?.size}
        />
        {/* <input
          style={{ color: disabled ? "black" : "" }}
          type="text"
          className={
            checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id)
              ? "input is-danger"
              : "input"
          }
          name={name}
          placeholder={placeholder}
          id={id}
          value={value || ""}
          onChange={({ target: { value } }) => updateValue(value)}
          onClick={clicked}
          disabled={disabled}
          maxLength={configuration?.size}
        /> */}
        {checkBlankedFields &&
          blankedRequiredFields.find((fieldId) => fieldId === id) && (
            <p
              class={globalTheme !== "high-contrast" && "help is-danger"}
              style={{
                color: globalTheme === "high-contrast" && "#df9700",
              }}
              className={`${classes.fontSize} ${
                globalTheme !== "high-contrast" && "help is-danger"
              }`}
            >
              To pole musi zostać wypełnione
            </p>
          )}
      </>
    );
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Tekst"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Tekst"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={drag}>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={`label ${classes.fontSize}`}
        ></label>
      )}
      {configuration?.maxRows > 1 ? (
        disabled && !isJustUser && !value ? (
          <div className={`${s.hintText} ${classes.fontSize}`}>
            Pole niewypełnione przez{" "}
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "pacjenta"
              : "użytkownika"}
          </div>
        ) : (
          <>
            <CssTextField
              // className={classnames(classes.muiTextarea)}
              rowsMax={configuration?.maxRows}
              multiline={true}
              placeholder={placeholder}
              id={id}
              value={value}
              onChange={({ target: { value } }) => {
                if (configuration?.size && value.length <= configuration.size) {
                  updateValue(value);
                }
              }}
              onClick={clicked}
              disabled={disabled}
              fullWidth
              variant={"outlined"}
              size="small"
              error={
                checkBlankedFields &&
                blankedRequiredFields.find((fieldId) => fieldId === id)
              }
            />

            {checkBlankedFields &&
              blankedRequiredFields.find((fieldId) => fieldId === id) && (
                <p className="help is-danger">To pole musi zostać wypełnione</p>
              )}
          </>
        )
      ) : disabled && !isJustUser && !value ? (
        <div className={s.hintText}>
          Pole niewypełnione przez{" "}
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjenta"
            : "użytkownika"}
        </div>
      ) : (
        renderInputField()
      )}
      <label
        className={`${s.hintText} ${classes.fontSizeHintText}`}
        style={{ color: globalTheme === "high-contrast" && "yellow" }}
      >
        {configuration.size &&
          value &&
          `Pozostało znaków do wprowadzenia: ${
            configuration.size - value.length
          } `}
      </label>

      {!(!isJustUser && !value) && (
        <label
          className={`${s.hintText} ${classes.fontSizeHintText}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          &nbsp;&nbsp;&nbsp;
          {configuration.maxRows &&
            `Maksymalna ilość wierszy: ${configuration.maxRows}`}
        </label>
      )}
    </div>
  );
};

export default TextControl;
