import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// dragaMode = either 'clone' or 'move'

const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));
const LocationControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.LOCATION,
      dragMode,
      defaultLabel: "Lokalizacja",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const classes = useStyles();
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const [value, setValue] = useState(filledValue || "");
  const globalTheme = useSelector((s) => s.globalTheme);
  useEffect(() => {
    setValue(filledValue);
  }, [filledValue]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.LOCATION,
      configuration,
    });

  const updateValue = (value) => {
    setValue(value);

    if (onChange) {
      onChange({
        id,
        value,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Lokalizacja"
        />
      </div>
    );
  }

  const placeholder = filledFormMode
    ? ""
    : showSelfDescription
    ? "Lokalizacja"
    : (configuration && configuration.text) || "Np. 52.2 14.2";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={drag}>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}
      {disabled && !isJustUser && !value ? (
        <div
          className={`${s.hintText} ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          Pole niewypełnione przez{" "}
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjenta"
            : "użytkownika"}
        </div>
      ) : (
        <>
          <input
            style={{
              color: globalTheme === "high-contrast" ? "yellow" : "",
              border: globalTheme === "high-contrast" && "1px solid yellow",
              backgroundColor: globalTheme === "high-contrast" && "black",
            }}
            type="text"
            disabled={disabled}
            className={`${
              checkBlankedFields &&
              blankedRequiredFields.find((fieldId) => fieldId === id)
                ? "input is-danger"
                : "input"
            } ${classes.fontSize}`}
            name={name}
            placeholder={placeholder}
            id={id}
            value={value}
            onChange={({ target: { value } }) => updateValue(value)}
            onClick={clicked}
          />
          {checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id) && (
              <p
                class={globalTheme !== "high-contrast" && "help is-danger"}
                style={{
                  color: globalTheme === "high-contrast" && "#df9700",
                }}
                className={`${classes.fontSize} ${
                  globalTheme !== "high-contrast" && "help is-danger"
                }`}
              >
                To pole musi zostać wypełnione
              </p>
            )}
        </>
      )}
    </div>
  );
};

export default LocationControl;
