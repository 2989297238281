import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchAllowedUsersToForm,
  fetchForm,
  updateUsersAccessToForm,
} from "../../redux/actions";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import { addDays, endOfDay, startOfDay } from "date-fns";

import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton, TextField, Tooltip } from "@material-ui/core";
import Loader from "../../components/loader";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Clear } from "@material-ui/icons";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const getTimezoneOffset = (date) => {
  return date.getTimezoneOffset() * 60_000;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    minWidth: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
  },
}));

const FormPermissionsPanel = ({
  form,
  open,
  hide,
  orgForms,
  fetchAllowedUsersToForm,
  allowedUsersToFormFetched,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);

  const allowedUsersToForm = useSelector((s) => s.allowedUsersToForm) || [];

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersFiltered, setSelectedUsersFiltered] = useState([]);

  const [busy, setBusy] = useState(false);

  const [userFilter, setUserFilter] = useState("");
  const [formStartDate, setFormStartDate] = useState(null);
  const [formEndDate, setFormEndDate] = useState(null);
  const [isExpired, setExpired] = useState(false);
  const [fetchedStartDate, setFetchedStartDate] = useState(false);
  const [openAssignUserDialog, setOpenAssignUserDialog] = useState(false);
  const [userIdToAssign, setUserIdToAssign] = useState(null);
  const [isEditorSelected, setEditorSelected] = useState(false);

  const [sortDirection, setSortDirection] = useState("desc");

  const my = useSelector((s) => s.my);
  const isEditor = my?.user.authorities.localeCompare("ROLE_EDITOR") === 0;

  useEffect(() => {
    fetchAllowedUsersToForm(form.id);
  }, [open]);

  useEffect(() => {
    allowedUsersToFormFetched && setSelectedUsers(allowedUsersToForm);
  }, [allowedUsersToForm, allowedUsersToFormFetched]);

  useEffect(() => {
    async function fetchThisForm() {
      let response = await dispatch(fetchForm(form.id));
      setFormStartDate(response.schedule?.startDate || null);
      setFormEndDate(response.schedule?.endDate || null);
      setFetchedStartDate(true);
    }
    fetchThisForm();
  }, []);

  useEffect(() => {
    const filterNormalized = userFilter.trim().toLowerCase();

    setSelectedUsersFiltered(
      selectedUsers.filter((u) => {
        return (
          filterNormalized === "" ||
          (u.firstName || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.surname || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.mail || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.phone || "").toLowerCase().indexOf(filterNormalized) !== -1
        );
      })
    );
  }, [selectedUsers, userFilter, sortDirection]);

  useEffect(() => {
    setExpired(isFormExpired());
  }, [formEndDate]);

  const onSelect = (id) => {
    let date = null;
    if (form.defaultAssignmentDuration > 0) {
      date = defaultValidDate();
    }

    setSelectedUsers(
      selectedUsers.map((selectedUser) =>
        selectedUser.userId === id
          ? form.defaultAssignmentDuration > 0 && date
            ? {
                ...selectedUser,
                selected: true,
                ...(form.defaultAssignmentDuration > 0 &&
                  date &&
                  !selectedUser.validSince && { validSince: date.validSince }),
                ...(form.defaultAssignmentDuration > 0 &&
                  date &&
                  !selectedUser.validTo && { validTo: date.validTo }),
              }
            : {
                ...selectedUser,
                selected: true,
              }
          : selectedUser
      )
    );
  };

  const onEditorSelect = (id) => {
    setSelectedUsers(
      selectedUsers.map((selectedUser) =>
        selectedUser.userId === id
          ? {
              ...selectedUser,
              selected: true,
            }
          : selectedUser
      )
    );
  };

  const onEditorUnselect = (id) => {
    setSelectedUsers(
      selectedUsers.map((selectedUser) =>
        selectedUser.userId === id
          ? {
              ...selectedUser,
              selected: false,
            }
          : selectedUser
      )
    );
  };

  const onUnselect = (id) => {
    setSelectedUsers(
      selectedUsers.map((selectedUser) =>
        selectedUser.userId === id
          ? {
              ...selectedUser,
              selected: false,
              // validTo: null,
              // validSince: null,
            }
          : selectedUser
      )
    );
  };

  const defaultValidDate = () => {
    let localISOTime;
    let tmpStartDate = new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    );
    tmpStartDate.setUTCHours(0, 0, 0, 0);
    tmpStartDate = new Date(tmpStartDate.toUTCString());
    localISOTime = tmpStartDate.toISOString().slice(0, -1);
    localISOTime =
      formStartDate < localISOTime || !formStartDate
        ? localISOTime
        : formStartDate;

    let endDate = new Date(
      endOfDay(
        addDays(new Date(localISOTime), form.defaultAssignmentDuration - 1)
      ) - getTimezoneOffset(new Date(localISOTime))
    )
      .toISOString()
      .slice(0, -1);

    return { validSince: localISOTime, validTo: endDate };
  };

  const handleValidSinceChange = (newValidSince, id) => {
    const validSinceBeforeActualTime =
      startOfDay(newValidSince) < startOfDay(new Date());

    const updateValidTo = form.defaultAssignmentDuration > 0;

    let localISOTime;
    if (validSinceBeforeActualTime) {
      let tmpStartDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      );
      tmpStartDate.setUTCHours(0, 0, 0, 0);
      tmpStartDate = new Date(tmpStartDate.toUTCString());
      localISOTime = tmpStartDate.toISOString().slice(0, -1);
    } else {
      localISOTime = new Date(
        startOfDay(newValidSince) - getTimezoneOffset(newValidSince)
      )
        .toISOString()
        .slice(0, -1);
    }

    setSelectedUsers(
      selectedUsers.map((selectedUser) =>
        selectedUser.userId === id
          ? { ...selectedUser, validSince: localISOTime }
          : selectedUser
      )
    );

    let endDate;
    if (updateValidTo) {
      endDate = new Date(
        endOfDay(
          addDays(new Date(localISOTime), form.defaultAssignmentDuration - 1)
        ) - getTimezoneOffset(new Date(localISOTime))
      )
        .toISOString()
        .slice(0, -1);

      setSelectedUsers(
        selectedUsers.map((selectedUser) =>
          selectedUser.userId === id
            ? { ...selectedUser, validSince: localISOTime, validTo: endDate }
            : selectedUser
        )
      );
    } else {
      let validTo = selectedUsers.find(
        (selectedUser) => selectedUser.userId === id
      ).validTo;

      if (validTo) {
        const validToBeforeValidSince = validTo < localISOTime;

        if (validToBeforeValidSince) {
          const endDate = new Date(endOfDay(new Date(localISOTime))) // no offset here as it isn't accounted for inside
            .toISOString()
            .slice(0, -1);

          setSelectedUsers(
            selectedUsers.map((selectedUser) =>
              selectedUser.userId === id
                ? {
                    ...selectedUser,
                    validSince: localISOTime,
                    validTo: endDate,
                  }
                : selectedUser
            )
          );
        }
      }
    }
  };

  const handleValidToChange = (newValidTo, id) => {
    let validSince = selectedUsers.find(
      (selectedUser) => selectedUser.userId === id
    ).validSince;

    if (!validSince) {
      const now = new Date();
      validSince = new Date(startOfDay(now) - getTimezoneOffset(now))
        .toISOString()
        .slice(0, -1);
    }

    const validSinceDate = new Date(validSince);

    const validToAfterValidSince =
      Date.parse(newValidTo) > Date.parse(validSinceDate);

    const chosenDate = validToAfterValidSince ? newValidTo : validSinceDate;

    const localISOTime = new Date(
      endOfDay(chosenDate) - getTimezoneOffset(chosenDate)
    )
      .toISOString()
      .slice(0, -1);

    setSelectedUsers(
      selectedUsers.map((selectedUser) =>
        selectedUser.userId === id
          ? { ...selectedUser, validTo: localISOTime }
          : selectedUser
      )
    );
  };

  const handleClose = () => {
    hide();
  };

  const handleSort = () => {
    const t = selectedUsers.sort((u1, u2) =>
      sortDirection === "desc"
        ? u2.activeNow - u1.activeNow
        : u1.activeNow - u2.activeNow
    );
    setSelectedUsers(t);
    setSortDirection(sortDirection === "desc" ? "asc" : "desc");
  };

  const isFormExpired = () => {
    let today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    today.setUTCHours(0, 0, 0, 0);
    today = new Date(today.toUTCString());
    today = today.toISOString().slice(0, -1);
    if (today <= formEndDate || !formEndDate) {
      return false;
    } else {
      return true;
    }
  };

  const formExpired = () => {
    if (isExpired) {
      return (
        <Tooltip placement="top" title="Formularz jest przedawniony">
          <span style={{ color: "red" }}>*</span>
        </Tooltip>
      );
    }
  };

  const setUserIdAndOpenPanel = (userId, editor = false) => {
    setOpenAssignUserDialog(true);
    setUserIdToAssign(userId);
    setEditorSelected(editor);
  };

  const tableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Użytkownik</TableCell>
          <TableCell align="right" style={{ textAlign: "center" }}>
            Przypisany do formularza
          </TableCell>
          <TableCell align="right">
            <Grid container style={{ flexWrap: "nowrap" }}>
              <label>Ma aktualnie dostęp</label>
              {sortDirection === "asc" ? (
                <ArrowDropUpIcon onClick={() => handleSort()} />
              ) : (
                <ArrowDropDownIcon onClick={() => handleSort()} />
              )}{" "}
            </Grid>
          </TableCell>
          <TableCell align="right">Data rozpoczęcia wypełnienia</TableCell>
          <TableCell align="right">Data zakończenia wypełnienia</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        Lista
        {process.env.REACT_APP_USE_PATIENT === "true"
          ? " pacjentów "
          : " użytkowników "}
        którzy mogą wypełniać formularz "{form.name}" {formExpired()}:
      </Typography>
    </Box>
  );

  const subtitle =
    form.defaultAssignmentDuration > 0 ? (
      <Box mt={1}>
        <Typography variant="body2" gutterBottom>
          Sugerowany czas dla nowych użytkowników to{" "}
          {form.defaultAssignmentDuration} dni.
        </Typography>
      </Box>
    ) : null;

  const body = (
    <Box p={1}>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenAssignUserDialog(false);
        }}
        open={openAssignUserDialog}
        text={`Ten formularz jest przedawniony, czy na pewno chcesz dodać do niego użytkownika?`}
        yesAction={() =>
          isEditorSelected
            ? onEditorSelect(userIdToAssign)
            : onSelect(userIdToAssign)
        }
        noAction={() => {}}
      />
      <TextField
        type="text"
        placeholder="Filtruj"
        size="small"
        variant="outlined"
        value={userFilter}
        onChange={({ target: { value } }) => setUserFilter(value)}
      />
      <br />
      <br />
      <Typography variant="h6" gutterBottom>
        {process.env.REACT_APP_USE_PATIENT === "true"
          ? "Pacjenci:"
          : "Użykownicy:"}
      </Typography>

      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          {tableHead()}

          <TableBody>
            {selectedUsersFiltered
              .filter(({ roles }) => roles.includes("ROLE_USER"))
              .map((p) => {
                const isUserSelected = p.selected;

                return (
                  <TableRow key={p.userId}>
                    <TableCell component="th" scope="row">
                      {(p.firstName || p.surname) &&
                        `${p.firstName || ""} ${p.surname || ""}`}
                      <br />
                      {p.mail
                        ? `${p.mail || ""}`
                        : p.phone
                        ? `${p.phone || ""}`
                        : ""}
                    </TableCell>
                    <TableCell align="right" style={{ textAlign: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isUserSelected}
                            onChange={(e) =>
                              e.target.checked
                                ? isExpired
                                  ? setUserIdAndOpenPanel(p.userId)
                                  : onSelect(p.userId)
                                : onUnselect(p.userId)
                            }
                            name="checkedB"
                            color="primary"
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="right" style={{ textAlign: "center" }}>
                      {p.activeNow ? (
                        <CheckCircleIcon style={{ color: "green" }} />
                      ) : (
                        <CancelIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Box style={{ display: "flex" }}>
                        <MuiPickersUtilsProvider
                          locale={plLocale}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            InputProps={{
                              readOnly: true,
                            }}
                            size="small"
                            margin="none"
                            id={`start-date-picker-dialog-${p.userId}`}
                            format="dd/MM/yyyy"
                            value={p.validSince || null}
                            inputVariant="outlined"
                            onChange={(e) =>
                              handleValidSinceChange(e, p.userId)
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            cancelLabel={"Anuluj"}
                            okLabel={"Zatwierdź"}
                            disabled={!isUserSelected}
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                        <Tooltip
                          title={
                            p.validSince
                              ? "Zresetuj datę rozpoczęcia wypełnienia"
                              : ""
                          }
                        >
                          <span>
                            <IconButton
                              style={{ padding: "8px" }}
                              onClick={() =>
                                setSelectedUsers(
                                  selectedUsers.map((selectedUser) =>
                                    selectedUser.userId === p.userId
                                      ? {
                                          ...selectedUser,
                                          validSince: null,
                                        }
                                      : selectedUser
                                  )
                                )
                              }
                              disabled={!isUserSelected || !p.validSince}
                            >
                              <Clear />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell align="right">
                      <Box style={{ display: "flex" }}>
                        <MuiPickersUtilsProvider
                          locale={plLocale}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            InputProps={{
                              readOnly: true,
                            }}
                            size="small"
                            margin="none"
                            id={`end-date-picker-dialog-${p.userId}`}
                            format="dd/MM/yyyy"
                            value={p.validTo || null}
                            inputVariant="outlined"
                            onChange={(e) => handleValidToChange(e, p.userId)}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            cancelLabel={"Anuluj"}
                            okLabel={"Zatwierdź"}
                            disabled={!isUserSelected}
                            fullWidth
                          />
                        </MuiPickersUtilsProvider>
                        <Tooltip
                          title={
                            p.validTo
                              ? "Zresetuj datę zakończenia wypełnienia"
                              : ""
                          }
                        >
                          <span>
                            <IconButton
                              style={{ padding: "8px" }}
                              onClick={() =>
                                setSelectedUsers(
                                  selectedUsers.map((selectedUser) =>
                                    selectedUser.userId === p.userId
                                      ? { ...selectedUser, validTo: null }
                                      : selectedUser
                                  )
                                )
                              }
                              disabled={!isUserSelected || !p.validTo}
                            >
                              <Clear />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {!isEditor && (
        <div>
          <Typography variant="h6" gutterBottom>
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "Lekarze:"
              : "Redaktorzy:"}
          </Typography>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Użytkownik</TableCell>
                  <TableCell align="right">Aktywny</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {selectedUsersFiltered
                  .filter(({ roles }) => roles.includes("ROLE_EDITOR"))
                  .map((p) => {
                    const isUserSelected = p.selected;

                    return (
                      <TableRow key={p.userId}>
                        <TableCell component="th" scope="row">
                          {(p.firstName || p.surname) &&
                            `${p.firstName || ""} ${p.surname || ""}`}
                          <br />
                          {p.mail
                            ? `${p.mail || ""}`
                            : p.phone
                            ? `${p.phone || ""}`
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isUserSelected}
                                onChange={(e) =>
                                  e.target.checked
                                    ? isExpired
                                      ? setUserIdAndOpenPanel(p.userId, true)
                                      : onEditorSelect(p.userId)
                                    : onEditorUnselect(p.userId)
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Box>
  );

  const save = async () => {
    setBusy(true);
    const usersWithAcces = selectedUsers.map((u) => ({
      ...(u.userId && { userId: u.userId }),
      selected: u.selected,
      ...(u.validSince && { validSince: u.validSince }),
      ...(u.validTo && { validTo: u.validTo }),
    }));
    await dispatch(updateUsersAccessToForm(form.id, usersWithAcces));
    await dispatch(fetchForm(form.id));
    setBusy(false);
    hide();
  };

  const foot = (
    <Grid>
      <Button
        variant={"contained"}
        color="primary"
        onClick={save}
        disabled={busy}
      >
        {busy && <CircularProgress size={24} style={{ color: "white" }} />}
        {!busy && "Zapisz"}
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button variant={"contained"} onClick={() => hide()}>
        Anuluj
      </Button>
    </Grid>
  );

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      style={{ overflow: "scroll" }}
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {fetchedStartDate && allowedUsersToForm ? (
          <>
            {subtitle}
            {body} {foot}
          </>
        ) : (
          <Box p={1}>
            <Loader loading={true} text="Pobieranie listy użytkowników..." />
          </Box>
        )}
        <hr />
      </Box>
    </Modal>
  );
};

export default connect(
  (state) => ({
    orgForms: state.orgForms,
    allowedUsersToForm: state.allowedUsersToForm,
    allowedUsersToFormFetched: state.allowedUsersToFormFetched,
    allowedUsersToFormFetching: state.allowedUsersToFormFetching,
  }),
  (dispatch) => ({
    fetchAllowedUsersToForm: (formId) =>
      dispatch(fetchAllowedUsersToForm(formId)),
  })
)(FormPermissionsPanel);
