import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  copyFormToFormGroup,
  fetchAllowedUsersToGroup,
  updateUsersAccessToGroup,
  fetchUserAllAvailableForms,
  fetchOrgUsers,
} from "../../redux/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { TextField } from "@material-ui/core";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    position: "absolute",
    overflow: "scroll",
    height: "75%",
    display: "block",
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UserAccessToGroup = ({
  group,
  currentFormProject,
  option,
  open,
  setOpen,
  allowedUsersToGroup,
  fetchAllowedUsersToGroup,
  userAllAvailableForms,
  fetchUserAllAvailableForms,
  orgFormsGroups,
  fetchFormsGroups,
  copyFormToFormGroup,
  moveFormToFormGroup,
  // users,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);
  const [usersAccessGroup, setUsersAccessGroup] = useState([]);
  const [usersAccessGroupFiltered, setUsersAccessGroupFiltered] = useState([]);
  const [openRemovingPanel, setOpenRemovingPanel] = useState(false);

  const users = useSelector((s) => s.orgUsers);

  const my = useSelector((s) => s.my);
  const isOwner = my.user.authorities.indexOf("ROLE_OWNER") !== -1;
  const isEditor = my.user.authorities.localeCompare("ROLE_EDITOR") === 0;

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [busy, setBusy] = useState(false);
  const [userFilter, setUserFilter] = useState("");
  const [unselectedUser, setUnselectedUser] = useState({});
  const [usersFormsInProject, setUsersFormsInProject] = useState([]);
  const [formsReady, setFormsReady] = useState(false);

  // useEffect(() => {
  //   console.log("1");
  //   dispatch(fetchOrgUsers());
  // }, []);

  useEffect(() => {
    if (open) {
      fetchAllowedUsersToGroup(group.id);
      dispatch(fetchOrgUsers());
    }
  }, [open]);

  useEffect(() => {
    setSelectedUsers(allowedUsersToGroup);
  }, [allowedUsersToGroup]);

  useEffect(() => {
    if (unselectedUser.id) {
      setFormsReady(false);
      fetchUserAllAvailableForms(unselectedUser.id, group.id);
    }
  }, [unselectedUser]);

  useEffect(() => {
    if (unselectedUser) {
      setUsersFormsInProject(userAllAvailableForms);
      setFormsReady(true);
    }
  }, [userAllAvailableForms]);

  const onSelect = (id) => {
    setSelectedUsers([...selectedUsers, id]);
  };

  const onUnselect = (id) => {
    setSelectedUsers(selectedUsers.filter((u) => u !== id));
  };

  useEffect(() => {
    if (open) {
      if (isOwner) {
        setUsersAccessGroup(
          users.filter(
            (orgUser) => !orgUser.user.authorities.includes("ROLE_OWNER")
          )
        );
      } else {
        setUsersAccessGroup(
          users.filter((orgUser) =>
            orgUser.user.authorities.includes("ROLE_USER")
          )
        );
      }
    }
  }, [users]);

  useEffect(() => {
    const filterNormalized = userFilter.trim().toLowerCase();

    setUsersAccessGroupFiltered(
      usersAccessGroup.filter((u) => {
        return (
          filterNormalized === "" ||
          (u.firstName || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.surname || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.mail || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.phone || "").toLowerCase().indexOf(filterNormalized) !== -1
        );
      })
    );
  }, [usersAccessGroup, userFilter]);

  const handleClose = () => {
    setOpen(false);
  };

  const populateTable = (users) => {
    return users
      .filter(({ user }) => !user.removedAt)
      .map(({ id, firstName, surname, user }) => {
        return (
          <TableRow key={id}>
            <TableCell component="th" scope="row">
              {(firstName || surname) && `${firstName || ""} ${surname || ""}`}
              <br />
              {user.email
                ? `${user.email || ""}`
                : user.phone
                ? `${user.phone || ""}`
                : ""}
            </TableCell>
            <TableCell align="right" style={{ textAlign: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      (selectedUsers && selectedUsers.includes(id)) || false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        onSelect(id);
                      } else {
                        setUnselectedUser(
                          usersAccessGroup.find((u) => u.id === id)
                        );
                        setOpenRemovingPanel(true);
                      }
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                // label={
                //   firstName || surname
                //     ? `${firstName || ""} ${surname || ""}, ${
                //         user.email || user.phone
                //       }`
                //     : `${user.email || user.phone}`
                // }
              />
            </TableCell>
          </TableRow>
        );
      });
  };

  const tableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>Użytkownik</TableCell>
          <TableCell align="right" style={{ textAlign: "center" }}>
            Przypisany do projektu
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        Lista
        {process.env.REACT_APP_USE_PATIENT === "true"
          ? " pacjentów "
          : " użytkowników "}
        którzy należą do projektu "{group.name}":
      </Typography>
    </Box>
  );
  const body = (
    <Box p={1}>
      <TextField
        type="text"
        placeholder="Filtruj"
        size="small"
        variant="outlined"
        value={userFilter}
        onChange={({ target: { value } }) => setUserFilter(value)}
      />
      <br />
      <br />
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          {tableHead()}
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="h6" gutterBottom>
                  {process.env.REACT_APP_USE_PATIENT === "true"
                    ? "Pacjenci:"
                    : "Użykownicy:"}
                </Typography>
              </TableCell>
            </TableRow>
            {populateTable(
              usersAccessGroupFiltered.filter(({ user }) =>
                user.authorities.includes("ROLE_USER")
              )
            )}
            <TableRow></TableRow>
            {!isEditor && (
              <>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6" gutterBottom>
                      {process.env.REACT_APP_USE_PATIENT === "true"
                        ? "Lekarze:"
                        : "Redaktorzy:"}
                    </Typography>
                  </TableCell>
                </TableRow>
                {populateTable(
                  usersAccessGroupFiltered.filter(({ user }) =>
                    user.authorities.includes("ROLE_EDITOR")
                  )
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const save = async () => {
    setBusy(true);
    await dispatch(updateUsersAccessToGroup(group.id, selectedUsers));
    setBusy(false);
    setOpen(false);
  };

  const foot = (
    <Grid>
      <Button
        disabled={busy}
        variant={"contained"}
        color="primary"
        onClick={save}
      >
        Zapisz&nbsp;&nbsp;&nbsp;
        {busy && <CircularProgress size={24} />}
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button
        disabled={busy}
        variant={"contained"}
        onClick={() => setOpen(false)}
      >
        Anuluj
      </Button>
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
        open={open}
        onClose={handleClose}
      >
        <Box style={modalStyle} className={classes.paper}>
          {title}
          {body}
          <hr />
          {foot}
        </Box>
      </Modal>

      {formsReady && (
        <ConfirmDialogMUI
          handleClose={() => {
            setOpenRemovingPanel(false);
            setUnselectedUser({});
            setUsersFormsInProject([]);
          }}
          open={openRemovingPanel}
          text={
            <div>
              Czy chcesz usunąć z projektu {group.name} użytkownika:{" "}
              {unselectedUser.firstName && unselectedUser.surname
                ? `${unselectedUser.firstName} ${unselectedUser.surname}`
                : `${unselectedUser.mail}`}
              {usersFormsInProject.length > 0 &&
                `, który należy do następujących aktywnych formularzy:`}
              <ul style={{ listStyleType: "circle", marginLeft: "15px" }}>
                {usersFormsInProject.map((form, index) => (
                  <li key={index} style={{ fontSize: "14pt" }}>
                    {form}
                  </li>
                ))}
              </ul>
              {isEditor &&
                `${
                  process.env.REACT_APP_USE_PATIENT === "true"
                    ? "Pacjent"
                    : "Użytkownik"
                } może należeć do ${
                  usersFormsInProject.length > 0 ? "innych " : ""
                }aktywnych formularzy.`}
            </div>
          }
          yesAction={() => {
            onUnselect(unselectedUser.id);
          }}
          noAction={() => {
            setOpenRemovingPanel(false);
            setUnselectedUser({});
            setUsersFormsInProject([]);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  allowedUsersToGroup: state.allowedUsersToGroup,
  userAllAvailableForms: state.userAllAvailableForms,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAllowedUsersToGroup: (groupId) =>
    dispatch(fetchAllowedUsersToGroup(groupId)),
  copyFormToFormGroup: (formId, groupId) =>
    dispatch(copyFormToFormGroup(formId, groupId)),
  fetchUserAllAvailableForms: (id, groupId) =>
    dispatch(fetchUserAllAvailableForms(id, groupId)),
  // moveFormToFormGroup: (formId, targetGroupId) =>
  //   dispatch(moveFormToFormGroup(formId, targetGroupId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserAccessToGroup);
