import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "../alert";
import { useSelector } from "react-redux";

const Alerts = () => {
  const alerts = useSelector((s) => s.alerts || []);
  const baseFontsSize = useSelector((s) => s.baseFontsSize);

  return (
    <div className="fixed-container">
      <Container>
        {alerts.map((alert) => (
          <Box m={baseFontsSize === 2 ? 10 : 5} key={alert.id}>
            {/* 123 */}
            <Alert {...alert} />
          </Box>
        ))}
      </Container>
    </div>
  );
};

export default Alerts;
