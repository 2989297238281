import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataAsCSV,
  fetchFilledFormsExtended,
  fetchForm,
  fetchFormTemplate,
  fetchAllowedUsersToForm,
} from "../../redux/actions";
import Select from "@material-ui/core/Select";
import { Box, TextField, Tooltip, InputAdornment } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import format from "date-fns/format";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { NavLink } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { endOfDay, startOfDay } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ColorBox } from "../color-box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "./index.module.css";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from "@material-ui/icons/Event";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import ClearIcon from "@material-ui/icons/Clear";
import TablePagination from "@material-ui/core/TablePagination";
import Cookies from "js-cookie";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: "1em",
    color: theme.palette.primary.main === "#fff200" && "yellow",
    backgroundColor: theme.palette.primary.main === "#fff200" && "black",
    border: theme.palette.primary.main === "#fff200" && "1px solid yellow",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 0,
      },
    },
  },
  filter: {
    background:
      theme.palette.primary.main === "#fff200"
        ? theme.palette.background
        : "whitesmoke",
    border: theme.palette.primary.main === "#fff200" && "1px solid yellow",
  },
  table: {
    width: "auto",
    tableLayout: "auto",
  },
  select: {
    "&:after": {
      borderBottomColor: theme.palette.primary.main === "#fff200" && "#fff200",
    },
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main === "#fff200" && "#fff200",
    },
    "& .Mui-disabled": {
      "&:after": {
        borderBottomColor:
          theme.palette.primary.main === "#fff200" && "#878214",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.main === "#fff200" && "#878214",
      },
    },
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
}));

registerLocale("pl", pl);

export const FilledFormData = (props) => {
  const dispatch = useDispatch();

  const formId = parseInt(
    props?.match?.params.formId ? props?.match?.params.formId : props.formId,
    10
  );
  const filledFormsExtended = useSelector((s) => s.filledFormsExtended[formId]);
  const filledFormsExtendedLength = useSelector(
    (s) => s.filledFormsExtendedLength[formId]
  );
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const globalTheme = useSelector((s) => s.globalTheme);
  const my = useSelector((s) => s.my);
  const personId = useSelector((s) => s.my.id);
  const userId = useSelector((s) => s.my.user.id);
  const formData = useSelector((s) => s.formData);
  const allowedUsersToForm = useSelector((s) => s.allowedUsersToForm);
  const [columns, setColumns] = useState([]);
  const [editions, setEditions] = useState([]);
  const [selectedEdition, setSelectedEdition] = useState();
  const orgForms = useSelector((s) => s.orgForms);
  const [form, setForm] = useState();
  const [id2dataType, setId2dataType] = useState({});
  const [showMissedForms, setShowMissedForms] = useState(
    props.showMissed !== undefined ? props.showMissed : false
  );

  const userLoggedIn = useSelector((s) => s.loggedIn);
  const [filterModel, setFilterModel] = useState({ data: {}, weight: {} });
  const [columns2, setColumns2] = useState([]);

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Cookies.get(`filled-form-per-page[${my.user.id}]`) || 100
  );

  const showMissedFilter =
    props.showMissedFilter !== undefined ? props.showMissedFilter : true;

  useEffect(() => {
    if (allowedUsersToForm?.length > 0) {
      const isUserAllowed = allowedUsersToForm?.find(
        ({ userId }) => userId === personId
      );
      if (isUserAllowed?.selected === false && !isJustUser) {
        props.history.push(`/forms`);
      }
    }
  }, [allowedUsersToForm]);

  useEffect(() => {
    dispatch(fetchAllowedUsersToForm(formId));
    if (selectedEdition) {
      if (formId != selectedEdition) {
        props.history.push(`/formdata/all/${selectedEdition}`);
      }
    }
  }, [selectedEdition]);

  useEffect(() => {
    if (!userLoggedIn) {
      props.history.push(`/login`);
    }
    const currentlySelected = editions.find((d) => d.id === formId);
    if (currentlySelected) {
      setSelectedEdition(currentlySelected.id);
    }
  }, [formData, editions]);

  useEffect(() => {
    if (filledFormsExtended) {
      const allCells = filledFormsExtended.reduce((all, current) => {
        return [...all, ...current.items];
      }, []);

      let columns = allCells.reduce(
        (acc, { id, label, dataType, color, weight }) => {
          return acc.concat([
            {
              id,
              label,
              dataType,
              sortable: true,
              renderCell: (row) => {
                const rowValues = row.values[id] || {};

                const color = rowValues.color;
                const text = rowValues.text;
                const alternativeAnswer = rowValues.alternativeAnswer;

                let value = "";

                if (rowValues.arrayValue) {
                  value = rowValues.arrayValue.join(", ");
                } else if (alternativeAnswer !== null) {
                  value = alternativeAnswer;
                } else if (rowValues.value !== null) {
                  value = rowValues.value;
                }

                return (
                  <span
                    {...(color ? { style: { color } } : {})}
                    {...(text ? { title: text } : {})}
                  >
                    {dataType !== "FILE"
                      ? value
                      : rowValues?.files?.map((file) => <p>{file.name}</p>)}
                  </span>
                );
              },
              renderFilter: ({ id, dataType, ...rest }) => {
                switch (dataType) {
                  case "DATE":
                    return (
                      <TextField
                        type="date"
                        className={classes.filter}
                        onChange={({ target: { value } }) => {
                          if (value) {
                            setFilterModel((f) => ({
                              ...f,
                              data: {
                                ...f.data,
                                [id]: value,
                              },
                            }));
                          } else {
                            setFilterModel((f) => {
                              const { [id]: x, ...rest } = f.data;
                              return {
                                ...f,
                                data: rest,
                              };
                            });
                          }
                        }}
                      />
                    );
                  default:
                    return (
                      <TextField
                        type="text"
                        className={classes.filter}
                        // value={filterModel.data[id] || ""}
                        onChange={({ target: { value } }) => {
                          if (value) {
                            setFilterModel((f) => ({
                              ...f,
                              data: {
                                ...f.data,
                                [id]: value,
                              },
                            }));
                          } else {
                            setFilterModel((f) => {
                              const { [id]: x, ...rest } = f.data;
                              return {
                                ...f,
                                data: rest,
                              };
                            });
                          }
                        }}
                      ></TextField>
                    );
                }
              },
            },
            (dataType === "DICTIONARY" || dataType === "NUMBER") &&
              !isJustUser &&
              allCells.find((cell) => cell.id === id && cell.weight) && {
                id: `fieldWeight.${id}`,
                fieldID: id,
                label: `Suma punktów - ${label}`,
                sortable: true,

                renderCell: (row) => {
                  const rowValues = row.values[id] || {};

                  const color = rowValues.color;
                  const weight = rowValues.weight;

                  return (
                    <Grid container direction="column" alignItems="center">
                      <Grid>
                        <span style={{ color: color ? color : "black" }}>
                          {weight && parseFloat(weight.toFixed(2))}
                        </span>
                      </Grid>
                    </Grid>
                  );
                },
                renderFilter: () => (
                  <TextField
                    size="small"
                    type="number"
                    className={classes.filter}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value) {
                        setFilterModel((f) => ({
                          ...f,
                          weight: {
                            ...f.weight,
                            [id]: value,
                          },
                        }));
                      } else {
                        setFilterModel((f) => {
                          const { [id]: x, ...rest } = f.weight;
                          return {
                            ...f,
                            weight: rest,
                          };
                        });
                      }
                    }}
                  />
                ),
              },
          ]);
        },
        []
      );

      columns = columns
        .filter((column) => !!column?.label)
        .filter(({ id, label, key }, index, all) => {
          return (
            !!label &&
            all.findIndex((x) => {
              return !x.key?.startsWith("weight") ? x.id === id : x.key === key;
            }) === index
          );
        });

      setColumns((currentColumns) => {
        // make sure we don't show less columns than before
        // this could happen is there's no data after filtering
        // dynamic column generation should be changed to be based on
        // form template instead of just on form data returned
        const currentColumnsLabels = currentColumns.map(({ label }) => label);
        return [
          ...currentColumns,
          // + all columns not yet in currentColumns
          ...columns
            .filter(
              ({ label: newColumnLabel }) =>
                currentColumnsLabels.indexOf(newColumnLabel) === -1
            )
            .map((c) => {
              if (c.id.includes("fieldWeight")) {
                return {
                  ...c,
                  key: c.id,
                };
              } else {
                return {
                  ...c,
                  key: `data.${c.id}`,
                };
              }
            }),
        ];
      });
    }
  }, [filledFormsExtended, filterModel]);

  useEffect(() => {
    setColumns2({
      status: {
        key: "status",
        label: "",
        renderCell: ({ text, color, state }) => {
          if (state === "MISSED") {
            text = "<Brak wypełnienia>";
            color = "gray";
          }
          return <ColorBox title={text} color={color} />;
        },
      },
      createdBy: {
        key: "createdBy",
        label: "Wypełniony przez",
        sortable: true,
        renderCell: (row) => <span>{row.createdBy}</span>,
      },
      completedAt: {
        key: "completedAt",
        label: "Data wypełnienia",
        sortable: true,
        renderCell: (row) =>
          row.completedAt ? (
            <span>{format(new Date(row.completedAt), "yyyy-MM-dd HH:mm")}</span>
          ) : null,
        renderFilter: () => (
          <MuiPickersUtilsProvider locale={plLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              size="small"
              style={{ color: "red" }}
              className={`${classes.filter} ${classes.font} ${classes.root}`}
              id="start-date-picker-dialog"
              // label="Rok urodzenia"
              format="dd/MM/yyyy"
              invalidDateMessage={"Nieprawidłowy format"}
              keyboardIcon={
                <EventIcon
                  style={{
                    color: globalTheme === "high-contrast" && "#fff200",
                  }}
                />
              }
              // inputVariant="outlined"
              value={filterModel.completedAt || null}
              onChange={(value) => {
                if (value) {
                  setFilterModel((f) => ({ ...f, completedAt: value }));
                } else {
                  setFilterModel(({ completedAt, ...rest }) => rest);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    style={{ height: "15px", paddingLeft: "0px" }}
                    onClick={() => {
                      setFilterModel((f) => ({ ...f, completedAt: null }));
                    }}
                  >
                    <ClearIcon
                      size="small"
                      style={{
                        color: globalTheme === "high-contrast" && "#fff200",
                      }}
                    />
                  </IconButton>
                ),
              }}
              InputAdornmentProps={{
                position: "start",
              }}
              inputProps={{
                readOnly: true,
              }}
              cancelLabel={"Anuluj"}
              okLabel={"Zatwierdź"}
              // views={["year"]}
            />
          </MuiPickersUtilsProvider>
          // <TextField
          //   type="date"
          //   className={`${classes.filter} ${classes.font}`}
          //   value={filterModel.completedAt}
          //   onChange={({ target: { value } }) => {
          //     if (value) {
          //       setFilterModel((f) => ({ ...f, completedAt: value }));
          //     } else {
          //       setFilterModel(({ completedAt, ...rest }) => rest);
          //     }
          //   }}
          // />
        ),
      },
      filledBy: {
        key: "filledBy",
        label: "Wypełniający",
        sortable: true,
        renderCell: (row) => {
          const filledBy = row.filledBy;

          return (
            <Grid container direction="column">
              <Grid item>{filledBy.name} </Grid>
              {"  "}
              <Grid item>
                {filledBy.mail
                  ? filledBy.mail
                  : filledBy.phone
                  ? filledBy.phone
                  : ""}
              </Grid>{" "}
              <Grid item>
                <CustomTooltip
                  placement="bottom"
                  title={
                    <div>
                      <span>
                        {`Szpitalny identyfikator: ${
                          filledBy.hospitalIdentifier || ""
                        }`}
                      </span>
                      <br />
                      <span>{`Adres: ${filledBy.address?.street || ""}`}</span>
                      <br />
                      <span>{`Miejscowość: ${
                        filledBy.address?.city || ""
                      }`}</span>
                      <br />
                      <span>{`Kod pocztowy: ${
                        filledBy.address?.zipCode || ""
                      }`}</span>
                      <br />
                      <span>{`Poczta: ${filledBy.address?.post || ""}`}</span>
                      <br />
                    </div>
                  }
                >
                  <IconButton className={classes.icon}>
                    <PermIdentityIcon className={classes.icon} />
                  </IconButton>
                </CustomTooltip>
                {!isJustUser && (
                  <CustomTooltip title="Przejdź do wszystkich ankiet użytkownika">
                    <IconButton
                      component={NavLink}
                      to={`/filled-forms/all/user/${row.filledBy.id}`}
                    >
                      <ArrowForwardIcon className={classes.icon} />
                    </IconButton>
                  </CustomTooltip>
                )}
              </Grid>
            </Grid>
          );
        },
        renderFilter: () => (
          <TextField
            size="small"
            className={classes.filter}
            value={filterModel.filledBy || ""}
            onChange={(e) => {
              const value = e.target.value;

              if (value) {
                setFilterModel((f) => ({ ...f, filledBy: value }));
                // setFilterModel((f) => ({
                //   ...f,
                //   "filledBy.name": {
                //     contains: value,
                //   },
                // }));
              } else {
                setFilterModel(({ filledBy, ...rest }) => rest);
              }
            }}
          />
        ),
      },
      totalWeight: {
        key: "totalWeight",
        label: "Suma punktów",
        sortable: true,
        renderCell: (row) => (
          <span style={{ color: row.color ? row.color : "black" }}>
            {row.totalWeight && parseFloat(row.totalWeight.toFixed(2))}
          </span>
        ),
        renderFilter: () => (
          <TextField
            size="small"
            type="number"
            className={classes.filter}
            value={filterModel.totalWeight}
            onChange={(e) => {
              const value = e.target.value;

              if (value) {
                setFilterModel((f) => ({
                  ...f,
                  totalWeight: value,
                }));
              } else {
                setFilterModel(({ totalWeight, ...rest }) => rest);
              }
            }}
          />
        ),
      },
      formName: {
        key: "formName",
        label: "Formularz",
        sortable: true,
        renderCell: (row) => <span>{row.form.name}</span>,
        renderFilter: () => (
          <TextField
            size="small"
            className={classes.filter}
            value={filterModel.formName || ""}
            onChange={(e) => {
              const value = e.target.value;

              if (value) {
                setFilterModel((f) => ({ ...f, formName: value }));
              } else {
                setFilterModel(({ formName, ...rest }) => rest);
              }
            }}
          />
        ),
      },
      groupName: {
        key: "groupName",
        label: "Projekt",
        sortable: true,
        renderCell: (row) => <span>{row.group.name}</span>,
        renderFilter: () => (
          <TextField
            size="small"
            className={classes.filter}
            value={filterModel.groupName || ""}
            onChange={(e) => {
              const value = e.target.value;

              if (value) {
                setFilterModel((f) => ({ ...f, groupName: value }));
              } else {
                setFilterModel(({ groupName, ...rest }) => rest);
              }
            }}
          />
        ),
      },
      scheduledExecutionStart: {
        key: "scheduledExecutionStart",
        label: "Okno czasowe",
        sortable: true,
        renderCell: (row) => {
          if (!row.scheduledExecutionStart) {
            return null;
          }

          const start = new Date(row.scheduledExecutionStart);
          const end = new Date(row.scheduledExecutionEnd);
          const timeStart = format(start, "HH:mm");
          const timeEnd = format(end, "HH:mm");

          const date = format(start, "yyyy-MM-dd");
          return <span>{`${date} ${timeStart} - ${timeEnd}`}</span>;
        },
      },
      details: {
        key: "details",
        label: "Opcje",
        sortable: false,
        renderCell: (row) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // flexWrap: "wrap",
            }}
          >
            {row.state !== "MISSED" ? (
              <NavLink
                className={
                  globalTheme === "high-contrast"
                    ? classes.aalertHighContrast
                    : undefined
                }
                to={{
                  pathname: `/formdata/${row.id}`,
                  state: { scheduleDetails: row },
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Szczegóły
              </NavLink>
            ) : null}

            {((row.filledBy.id === personId &&
              row.id &&
              new Date(row.editableUntil) >= new Date()) ||
              row.state === "SAVED") && (
              <NavLink
                className={
                  globalTheme === "high-contrast"
                    ? classes.aalertHighContrast
                    : undefined
                }
                to={{
                  pathname: `/formdata/edit/${row.form.id}/${row.id}`,
                  state: { endDate: row.editableUntil, edit: true },
                }}
              >
                {row.state === "SAVED"
                  ? "Edytuj wersję roboczą"
                  : "Edytuj odpowiedzi"}
              </NavLink>
            )}
          </div>
        ),
      },
    });
  }, [filterModel]);

  useEffect(() => {
    setId2dataType(
      columns.reduce(
        (all, current) => ({
          ...all,
          [current.id]: current.dataType,
        }),
        {}
      )
    );
  }, [columns]);

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    Cookies.set(`filled-form-per-page[${my.user.id}]`, event.target.value, {
      sameSite: "strict",
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const preDataColumns = [
    columns2.status,
    ...(isJustUser ? [] : [columns2.totalWeight]),
    columns2.groupName,
    columns2.formName,
    columns2.scheduledExecutionStart,
  ];

  const postDataColumns = [
    columns2.completedAt,
    columns2.filledBy,
    columns2.details,
  ];

  const [direction, setDirection] = useState("asc");
  const [activeSortColmn, setActiveSortColmn] = useState("completedAt");

  useEffect(() => {
    const model = {
      ...(filterModel.filledBy
        ? {
            ["filledBy"]: {
              contains: filterModel.filledBy,
            },
          }
        : {}),
      ...(filterModel.completedAt
        ? {
            ["completedAt"]: {
              between: [
                startOfDay(new Date(filterModel.completedAt)).toISOString(),
                endOfDay(new Date(filterModel.completedAt)).toISOString(),
              ],
            },
          }
        : {}),
      ...(filterModel.totalWeight !== undefined
        ? {
            ["totalWeight"]: {
              is: parseFloat(filterModel.totalWeight),
            },
          }
        : {}),
      ...(filterModel.groupName
        ? {
            ["group.name"]: {
              contains: filterModel.groupName,
            },
          }
        : {}),
      ...(filterModel.formName
        ? {
            ["form.name"]: {
              contains: filterModel.formName,
            },
          }
        : {}),

      ...Object.entries(filterModel.data).reduce((all, [id, value]) => {
        let rule;
        switch (id2dataType[id]) {
          case "NUMBER":
            rule = { is: parseFloat(value) };
            break;
          case "DATE":
            rule = {
              between: [
                startOfDay(new Date(value)).toISOString(),
                endOfDay(new Date(value)).toISOString(),
              ],
            };
            break;
          default:
            rule = { contains: value };
            break;
        }
        return {
          ...all,
          [`data.${id}`]: {
            ...rule,
          },
        };
      }, {}),
      // FIX ME!
      ...Object.entries(filterModel.weight).reduce((all, [id, value]) => {
        const rule = { is: parseFloat(value) };
        return {
          ...all,
          [`fieldWeight.${id}`]: {
            ...rule,
          },
        };
      }, {}),
    };

    const q = JSON.stringify(model);

    dispatch(
      fetchFilledFormsExtended(formId, {
        sort: `${direction === "asc" ? "-" : ""}${activeSortColmn}`,
        ...(q !== "{}" ? { q } : {}),
        showMissedForms,
        page: pageNr,
        pageSize: rowsPerPage,
      })
    );
  }, [
    activeSortColmn,
    direction,
    filterModel,
    showMissedForms,
    rowsPerPage,
    pageNr,
  ]);

  const changeDirection = (columnKey) => {
    if (activeSortColmn === columnKey) {
      setDirection(direction === "asc" ? "desc" : "asc");
    } else {
      setActiveSortColmn(columnKey);
      setDirection("asc");
    }
  };

  useEffect(() => {
    if (formId) {
      fetchForm(formId);
    }
  }, [orgForms, fetchForm]);

  useEffect(() => {
    const f = orgForms.find(({ id }) => id === formId);
    if (f) {
      setForm(f);
    }
  }, [orgForms]);

  useEffect(() => {
    if (form && form.layoutId) {
      dispatch(fetchFormTemplate(form.layoutId));
    }
  }, [form]);

  useEffect(() => {
    const loToDicValues = () => {
      let dic = [];
      formData.layoutElementObject.children.map((c) => {
        c.children.map((c) => {
          if (c.dataType === "DICTIONARY") {
            dic.push({
              id: c.id,
              dictionaryValues: c.configuration.dictionary.dictionaryValues,
            });
          }
        });
      });
      return dic;
    };
  }, [formData]);

  const getCSV = async (e, includePersonalData) => {
    e.preventDefault();

    const data = await dispatch(
      fetchDataAsCSV({ formId, includePersonalData, showMissedForms })
    );

    const element = document.createElement("a");
    const file = new Blob([data], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "data.csv";
    document.body.appendChild(element);
    element.click();
  };
  const data = (filledFormsExtended || []).map(({ items, ...row }) => {
    const baseValues = {
      filledByName: {
        id: "filledByName",
        label: "filledByName",
        value: row.filledBy.name,
      },
      completedAt: {
        id: "completedAt",
        label: "completedAt",
        value: format(new Date(row.completedAt), "yyyy-MM-dd HH:mm"),
      },
    };

    return {
      ...row,
      values: {
        ...baseValues,
        ...items.reduce((obj, current) => {
          return {
            ...obj,
            [current.id]: current,
          };
        }, {}),
      },
    };
  });

  const classes = useStyles();

  const columnHeaderMaxChars = 240 / (columns.length + 1);
  const columnHeaderTextLimit = columnHeaderMaxChars - 3;

  return (
    <Box>
      {editions.length > 1 && (
        <div>
          Wybrany formularz występował w kilku wersjach. Wybierz tę, której
          wyniki chcesz zobaczyć:{" "}
          <Select
            native
            value={selectedEdition ? selectedEdition : ""}
            onChange={({ target: { value } }) => setSelectedEdition(value)}
          >
            {editions.map((edition) => (
              <option key={edition.id} value={edition.id}>
                {edition.name} (v.{edition.edition})
              </option>
            ))}
          </Select>
        </div>
      )}

      <a
        href="#"
        onClick={getCSV}
        className={
          globalTheme === "high-contrast"
            ? classes.aalertHighContrast
            : undefined
        }
      >
        Pobierz CSV
      </a>
      {" | "}
      <a
        href="#"
        onClick={(e) => getCSV(e, true)}
        className={
          globalTheme === "high-contrast"
            ? classes.aalertHighContrast
            : undefined
        }
      >
        Pobierz CSV z danymi osobowymi
      </a>
      <br />

      {showMissedFilter ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={showMissedForms}
              color="primary"
              onChange={(e) => setShowMissedForms(!showMissedForms)}
            />
          }
          label="Pokazuj niewypełnione"
        />
      ) : null}

      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {[...preDataColumns, ...columns, ...postDataColumns]
                .filter((s) => s)
                .map((colDef) => {
                  const columnHeaderExceedsLimit =
                    colDef.label.length > columnHeaderMaxChars;

                  const columnHeader = columnHeaderExceedsLimit
                    ? `${colDef.label.slice(0, columnHeaderTextLimit)}...`
                    : colDef.label;

                  const contents = colDef.sortable ? (
                    <span onClick={() => changeDirection(colDef.key)}>
                      <TableSortLabel
                        active={colDef.key === activeSortColmn}
                        direction={direction}
                      />
                      {columnHeader}
                    </span>
                  ) : (
                    <span>{columnHeader}</span>
                  );

                  const tableCell = (
                    <TableCell
                      key={colDef.key}
                      className={colDef.sortable ? classes.cursor : ""}
                      style={{
                        padding: "8px",
                        border:
                          globalTheme === "high-contrast" &&
                          "1px solid #fff200",
                      }}
                    >
                      {columnHeaderExceedsLimit ? (
                        <Tooltip title={colDef.label}>{contents}</Tooltip>
                      ) : (
                        contents
                      )}
                    </TableCell>
                  );

                  return tableCell;
                })}
            </TableRow>
            <TableRow>
              {[...preDataColumns, ...columns, ...postDataColumns]
                .filter((s) => s)
                .map((colDef, colIndex) => (
                  <TableCell
                    key={colIndex}
                    style={{
                      padding: "8px",
                      border:
                        globalTheme === "high-contrast" && "1px solid #fff200",
                    }}
                  >
                    {colDef.renderFilter ? colDef.renderFilter(colDef) : null}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, id) => {
              return (
                <>
                  <TableRow key={id}>
                    {preDataColumns.map((colDef, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          padding: "8px",
                          border:
                            globalTheme === "high-contrast" &&
                            "1px solid #fff200",
                        }}
                      >
                        {colDef && colDef.renderCell
                          ? colDef.renderCell(row)
                          : "-"}
                      </TableCell>
                    ))}
                    {columns.map((colDef, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          padding: "8px",
                          border:
                            globalTheme === "high-contrast" &&
                            "1px solid #fff200",
                        }}
                      >
                        {colDef && colDef.renderCell
                          ? colDef.renderCell(row)
                          : "-"}
                      </TableCell>
                    ))}
                    {postDataColumns.map((colDef, colIndex) => (
                      <TableCell
                        key={colIndex}
                        style={{
                          padding: "8px",
                          border:
                            globalTheme === "high-contrast" &&
                            "1px solid #fff200",
                        }}
                      >
                        {colDef && colDef.renderCell
                          ? colDef.renderCell(row)
                          : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        className={classes.select}
        page={pageNr}
        rowsPerPageOptions={pages}
        rowsPerPage={rowsPerPage}
        count={filledFormsExtendedLength}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={"Wierszy na stronę:"}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
      />
    </Box>
  );
};
