import React, { useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

// dragaMode = either 'clone' or 'move'

const useStyles = makeStyles((theme) => ({
  muiTextarea: {
    background: "white",
  },
  isDanger: {
    borderColor: "#ff3860",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));

const LabelControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.LABEL,
      dragMode,
      defaultLabel: "Etykieta",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const globalTheme = useSelector((s) => s.globalTheme);
  const classes = useStyles();
  const [value, setValue] = useState("");

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.LABEL,
      configuration,
    });

  const updateValue = (value) => {
    setValue(value);

    if (onChange) {
      onChange({
        id,
        value,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Etykieta"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Etykieta"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div ref={drag}>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={`label ${classes.fontSize}`}
        ></label>
      )}
      {/*<input*/}
      {/*    type={configuration.labelType}*/}
      {/*    className={classnames('input')}*/}
      {/*    name={name}*/}
      {/*    placeholder={placeholder}*/}
      {/*    id={id}*/}
      {/*    value={value}*/}
      {/*    onChange={({target: {value}}) => updateValue(value)}*/}
      {/*    onClick={clicked}*/}
      {/*/>*/}
    </div>
  );
};

export default LabelControl;
