import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ColorBox } from "../../../color-box";
import { ColorPicker } from "../../../color-picker";
import TextField from "@material-ui/core/TextField";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 850,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
  },
}));

const OPERATORS = [
  {
    type: "GREATER_THAN",
    description: "Większa niż",
  },
  {
    type: "LESS_THAN",
    description: "Mniejsza niż",
  },
  {
    type: "EQUALS",
    description: "Równa",
  },
  {
    type: "BETWEEN",
    description: "Pomiędzy",
  },
];

const NumberPropertiesRuleModal = ({
  openRuleModal,
  displayMode,
  fieldRules,
  handleClose,
  setFieldRules,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [tmpFieldRules, setTmpFieldRules] = useState(fieldRules || []);
  const [addNewRule, setAddNewRule] = useState(false);
  const [editedRule, setEditedRule] = useState({});

  const [operator, setOperator] = useState("");
  const [arg1, setArg1] = useState("");
  const [arg2, setArg2] = useState("");
  const [color, setColor] = useState("");
  const [text, setText] = useState("");
  const [weight, setWeight] = useState("");

  const [showColorPicker, setShowColorPicker] = useState(false);

  const removeRule = (index) => {
    const newRules = [...tmpFieldRules];
    newRules.splice(index, 1);
    setTmpFieldRules(newRules);
    setFieldRules(newRules);
  };

  const editRule = (index) => {
    setEditedRule(tmpFieldRules[index]);
  };

  useEffect(() => {
    if (
      !(
        editedRule &&
        Object.keys(editedRule).length === 0 &&
        editedRule.constructor === Object
      )
    ) {
      setOperator(editedRule.operator || "");
      setArg1(editedRule.arg1 || "");
      setArg2(editedRule.arg2 || "");
      setColor(editedRule.color || "");
      setText(editedRule.text || "");
      setWeight(editedRule.weight || "");
    }
  }, [editedRule]);

  const ruleEditorPanel = (
    <Box m={1}>
      <Box p={1}>
        <Grid item xs={3}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="select-outlined-label">Wartość w polu</InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined"
              value={operator}
              onChange={(event) => setOperator(event.target.value)}
              label="Wartość w polu"
            >
              {OPERATORS.map((operator, index) => {
                return (
                  <MenuItem key={index} value={operator.type}>
                    {operator.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Box>
      {operator && (
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box p={1} display="flex">
              <TextField
                label={operator !== "BETWEEN" ? "Wartość" : "Wartość 1"}
                variant="outlined"
                type="number"
                value={arg1 || ""}
                size="small"
                onChange={({ target: { value } }) => setArg1(value)}
              />
              {operator === "BETWEEN" && (
                <TextField
                  label="Wartość 2"
                  variant="outlined"
                  type="number"
                  value={arg2 || ""}
                  size="small"
                  onChange={({ target: { value } }) => setArg2(value)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            {" "}
            <Box p={1} display="flex">
              {showColorPicker && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <InputLabel style={{ color: "black" }}>
                    Wybierz kolor:{" "}
                  </InputLabel>
                  &nbsp;&nbsp;&nbsp;
                  <a onClick={() => setShowColorPicker(false)}>Wybierz</a>
                  <ColorPicker
                    value={color}
                    onChange={(c) => {
                      setColor(c);
                    }}
                  />
                </div>
              )}
              {!showColorPicker && (
                <>
                  <InputLabel style={{ color: "black" }}>
                    Ustawiony kolor: &nbsp;&nbsp;&nbsp;
                  </InputLabel>
                  <ColorBox
                    onClick={() => setShowColorPicker(true)}
                    color={color}
                  />
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box p={1}>
              <TextField
                label={"Tekst komunikatu"}
                variant="outlined"
                multiline
                rows={2}
                rowsMax={4}
                value={text || ""}
                size="small"
                onChange={({ target: { value } }) => setText(value)}
              />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box p={1}>
              <TextField
                label={"Waga"}
                variant="outlined"
                type="number"
                value={weight || ""}
                size="small"
                onChange={({ target: { value } }) => setWeight(value)}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      <Box p={1}>
        <Button
          type="submit"
          variant={"contained"}
          sizme="small"
          style={{
            backgroundColor: "blue",
            color: "white",
          }}
          onClick={() => {
            const newRule =
              arg2 !== ""
                ? { operator, arg1, arg2, color, text, weight }
                : { operator, arg1, color, text, weight };

            setOperator("");
            setArg1("");
            setArg2("");
            setColor("");
            setText("");
            setWeight("");

            const editedRuleIndex = tmpFieldRules.findIndex(
              (rule) => rule === editedRule
            );

            if (!(editedRuleIndex > -1)) {
              setTmpFieldRules((fieldRules) => [...fieldRules, newRule]);
              setAddNewRule(false);
            } else {
              let tmpFieldR = [...tmpFieldRules];
              tmpFieldR[editedRuleIndex] = newRule;
              setTmpFieldRules(tmpFieldR);
              setEditedRule({});
            }
          }}
        >
          {!(
            editedRule &&
            Object.keys(editedRule).length === 0 &&
            editedRule.constructor === Object
          )
            ? "Edytuj"
            : "Zapisz"}
        </Button>
        <Button
          type="cancel"
          variant={"contained"}
          sizme="small"
          style={{
            backgroundColor: "lightgrey",
            color: "black",
            marginLeft: "10px",
          }}
          onClick={() => {
            setOperator("");
            setArg1("");
            setArg2("");
            setColor("");
            setText("");
            setWeight("");
            setAddNewRule(false);
            setEditedRule({});
          }}
        >
          Anuluj
        </Button>
      </Box>
    </Box>
  );

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        Definiowanie reguł:
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {!displayMode && (
        <Box m={1}>
          <Button
            variant={"contained"}
            color="primary"
            size="small"
            // onClick={() => setFieldRules({ test: "t" })}
            onClick={() => setAddNewRule(true)}
          >
            Dodaj
          </Button>
        </Box>
      )}

      {(addNewRule ||
        !(
          editedRule &&
          Object.keys(editedRule).length === 0 &&
          editedRule.constructor === Object
        )) &&
        ruleEditorPanel}
    </Box>
  );

  const foot = (
    <Grid>
      <Box m={1}>
        {tmpFieldRules.length === 0 ? (
          <InputLabel style={{ color: "black" }}>Brak reguł</InputLabel>
        ) : (
          <InputLabel style={{ color: "black" }}>Lista reguł:</InputLabel>
        )}
        {tmpFieldRules.map((rule, index) => (
          <Box p={1} key={index}>
            {index + 1}. Jeśli wartość w polu jest{" "}
            {OPERATORS.find(
              (operator) => operator.type === rule.operator
            ).description.toLocaleLowerCase()}{" "}
            {rule.arg1} {rule.operator === "BETWEEN" ? `a ${rule.arg2} ` : ""}
            {rule.color && (
              <>
                &nbsp;ustaw kolor na &nbsp; <ColorBox color={rule.color} />
                &nbsp;
              </>
            )}
            {rule.text && `oraz ustaw tekst na: "${rule.text}",`}
            {rule.weight && ` waga reguły wynosi:`}{" "}
            <b>{rule.weight && `${rule.weight}.`}</b>
            {!displayMode && (
              <>
                &nbsp; &nbsp; <a onClick={() => editRule(index)}>Edytuj</a>
                &nbsp; &nbsp; <a onClick={() => removeRule(index)}>Usuń</a>
              </>
            )}
          </Box>
        ))}
        <hr />
        <Button
          type="submit"
          variant={"contained"}
          sizme="small"
          style={{
            backgroundColor: !displayMode ? "blue" : "",
            color: !displayMode ? "white" : "",
          }}
          disabled={displayMode}
          onClick={() => {
            setFieldRules(tmpFieldRules);
            handleClose();
          }}
        >
          Zapisz
        </Button>
        <Button
          type="cancel"
          variant={"contained"}
          sizme="small"
          style={{
            backgroundColor: "lightgrey",
            color: "black",
            marginLeft: "10px",
          }}
          onClick={() => {
            handleClose();
          }}
        >
          {!displayMode ? "Anuluj" : "Powrót"}
        </Button>
      </Box>
    </Grid>
  );

  return (
    <Modal
      open={openRuleModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {body}
        <hr />
        {foot}
      </Box>
    </Modal>
  );
};
export default NumberPropertiesRuleModal;
