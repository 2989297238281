import React from "react";
import { ChromePicker } from "react-color";

export const ColorPicker = ({ label, value, onChange, required }) => {
  return (
    <div className="field">
      <label className="label">
        {label}
        {required && <span className="has-text-danger">*</span>}
      </label>

      {/*<div className={s.size} style={{backgroundColor: value}}/>*/}

      <ChromePicker
        color={value}
        onChangeComplete={(color, event) => {
          onChange(color.hex);
        }}
        disableAlpha={true}
      />
    </div>
  );
};
