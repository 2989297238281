import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  activateForm,
  duplicateForm,
  fetchFilledForms,
  fetchForms,
  fetchFormsGroups,
  fetchScheduledForms,
  removeForm,
  updateForm,
  updateRequestedSharingByOwner,
} from "../../redux/actions";
import { Link, Redirect } from "react-router-dom";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import { Box, Paper } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { checkPermission } from "../../rbac";
import Checkbox from "@material-ui/core/Checkbox";
import SingleForm from "./single-form";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Cookies from "js-cookie";
import Loader from "../../components/loader";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    border:
      theme.palette.primary.main === "#fff200" &&
      `1px solid ${theme.palette.primary.main}`,
  },
}));

const GreenButton = styled(Button)`
  background-color: #4caf50;
`;

const Page = ({
  history,
  orgForms,
  orgFormsGroups,
  orgFormsLength,
  loggedIn,
  removeForm,
  fetchFilledForms,
  fetchFormsGroups,
  filledForms,
  updateForm,
  activateForm,
  duplicateForm,
  fetchingForms,
}) => {
  const dispatch = useDispatch();
  const scheduledForms = useSelector((s) => s.scheduledForms);
  const forms = useSelector((s) => s.orgForms);
  const roles = useSelector((s) => s.roles);
  const my = useSelector((s) => s.my);
  // const formsGroups = useSelector((s) => s.orgFormsGroups);
  const canFetchForms = checkPermission("forms:fetch", roles);

  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Cookies.get(`forms-filled-per-page[${my.user.id}]`) || 100
  );
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  useEffect(() => {
    if (loggedIn) {
      dispatch(
        fetchScheduledForms({
          page: pageNr,
          pageSize: rowsPerPage / 2,
          ...(orderBy
            ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
            : {}),
        })
      );
    }
  }, [loggedIn, fetchScheduledForms, pageNr, rowsPerPage, order, orderBy]);

  useEffect(() => {
    // fetchFormsGroups();
    if (loggedIn && canFetchForms) {
      dispatch(
        fetchForms({
          page: pageNr,
          pageSize: rowsPerPage,
          ...(orderBy
            ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
            : {}),
        })
      );
    }
  }, [
    loggedIn,
    fetchForms,
    canFetchForms,
    pageNr,
    rowsPerPage,
    order,
    orderBy,
  ]);

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    Cookies.set(`forms-filled-per-page[${my.user.id}]`, event.target.value, {
      sameSite: "strict",
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const fill = (formId, scheduledFormId) => {
    if (scheduledFormId) {
      history.push(
        `/formdata/fill-scheduled-test/${formId}/${scheduledFormId}`
      );
    } else {
      history.push(`/formdata/add/${formId}`);
    }
  };
  const schedule = (id) => {
    history.push(`/forms/schedule/${id}`);
  };
  const edit = (id) => {
    history.push(`/forms/edit/${id}`);
  };
  const remove = (id) => {
    removeForm(id);
  };

  const classes = useStyles();

  const columns = [
    { field: "orderNumber", headerName: "Nr zamówienia" },
    { field: "code", headerName: "Nr zestawu" },
    {
      field: "isSharingWithOwnerRequested",
      headerName: "Chcę widzieć wyniki",
      renderer: ({ id, isSharingWithOwnerRequested }) => {
        return (
          <Checkbox
            checked={Boolean(isSharingWithOwnerRequested)}
            onChange={() => {
              dispatch(
                updateRequestedSharingByOwner(id, !isSharingWithOwnerRequested)
              );
            }}
          />
        );
      },
    },
    {
      headerName: "Opcje",
      renderer: ({ id }) => (
        <Link to={`/fill-scheduled-test/${id}`}>Wypełnij</Link>
      ),
    },
  ];

  const rows = [];

  const headerCells = [
    { id: "name", label: "Nazwa" },
    { id: "projectName", label: "Project" },
    { id: "status", label: "Status" },
    { id: "userCount", label: "Użytkownicy" },
    { id: "answerCount", label: "Odpowiedzi" },
  ];

  const page = () => (
    <Box>
      <Box p={1}>
        <PageTitle title="Formularze" />
      </Box>

      {fetchingForms ? (
        <Box p={3}>
          <Loader loading={true} text="Pobieranie listy formularzy..." />
        </Box>
      ) : (
        <Box p={1}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headerCells.map(({ label, id }) => (
                    <TableCell key={id} className={classes.tableCell}>
                      <TableSortLabel
                        active={orderBy === id}
                        direction={orderBy === id ? order : "asc"}
                        onClick={() => {
                          handleSortRequest(id);
                        }}
                      >
                        {label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {forms.map((form, index) => {
                  return (
                    <SingleForm
                      filledFormsDisplay={true}
                      key={index}
                      form={form}
                      remove={remove}
                      edit={edit}
                      schedule={schedule}
                      fill={fill}
                      fetchFilledForms={fetchFilledForms}
                      filledForms={filledForms}
                      updateForm={updateForm}
                      activateForm={activateForm}
                      duplicateForm={duplicateForm}
                      history={history}
                    />
                  );
                })}

                {scheduledForms.map((scheduledForm, index) => {
                  return (
                    <SingleForm
                      filledFormsDisplay={true}
                      key={index}
                      form={scheduledForm.form}
                      scheduledForm={scheduledForm}
                      remove={remove}
                      edit={edit}
                      schedule={schedule}
                      fill={fill}
                      fetchFilledForms={fetchFilledForms}
                      filledForms={filledForms}
                      updateForm={updateForm}
                      activateForm={activateForm}
                      duplicateForm={duplicateForm}
                      history={history}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ justifyContent: "flex-end" }}
            component="div"
            page={pageNr}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={orgFormsLength + scheduledForms.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={"Wierszy na stronę:"}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count}`
            }
          />
        </Box>
      )}
    </Box>
  );

  const redirect = () => <Redirect to="/forms/active" />;

  return <Can permission="forms-page:view" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  orgFormsLength: state.orgFormsLength,
  orgFormsGroups: state.orgFormsGroups,
  loggedIn: state.loggedIn,
  filledForms: state.filledForms,
  fetchingForms: state.fetchingForms,
});

const mapDispatchToProps = (dispatch) => ({
  activateForm: (form) => dispatch(activateForm(form)),
  removeForm: (formId) => dispatch(removeForm(formId)),
  fetchFilledForms: (formId) => dispatch(fetchFilledForms(formId)),
  fetchFormsGroups: () => dispatch(fetchFormsGroups()),
  updateForm: (id, payload) => dispatch(updateForm(id, payload)),
  duplicateForm: (id) => dispatch(duplicateForm(id)),
});

const FilledFormsPage = connect(mapStateToProps, mapDispatchToProps)(Page);
export default FilledFormsPage;
