import { DONE, FAILED, START } from "../../constants";
import { getAxiosInstance } from "../common";
import { login_done } from "../actions";

export const FETCH_MY = "FETCH_MY";
export const UPDATE_MY = "UPDATE_MY";
export const AFTER_FIRST_LOGIN = "AFTER_FIRST_LOGIN";

export const fetchMyProfile = () => async (dispatch) => {
  dispatch({ type: FETCH_MY + START });
  try {
    const result = await getAxiosInstance().get(`/api/persons/my`);
    dispatch({ type: FETCH_MY + DONE, payload: result.data });

    return result;
  } catch (payload) {
    dispatch({ type: FETCH_MY + FAILED, payload });
    return false;
  }
};

export const updateMyProfile = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_MY + START });
  try {
    const result = await getAxiosInstance().put(`/api/persons/my`, payload);
    dispatch({ type: UPDATE_MY + DONE, payload: result.data });

    return result;
  } catch (payload) {
    dispatch({ type: UPDATE_MY + FAILED, payload });
    return payload.response;
  }
};

export const updateMyProfileExt = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_MY + START });
  try {
    const { avatarImage, ...properties } = payload;

    const formData = new FormData();
    formData.append("avatarImage", avatarImage);
    formData.append(
      "properties",
      new Blob([JSON.stringify(properties)], {
        type: "application/json",
      })
    );

    const result = await getAxiosInstance().put(`/api/persons/ext`, formData);
    dispatch({ type: UPDATE_MY + DONE, payload: result.data });

    return result;
  } catch (payload) {
    dispatch({ type: UPDATE_MY + FAILED, payload });
    return payload.response;
  }
};

export const changeMyPassword = (payload) => async (dispatch) => {
  try {
    const result = await getAxiosInstance().put(
      `/api/auth/change_password`,
      payload
    );

    const my = await getAxiosInstance().get("/api/persons/my");

    dispatch(
      login_done({
        data: result.data,
        my: my.data,
      })
    );

    return result.data;
  } catch (payload) {
    return false;
  }
};

export const setFirstLoginToFalse = () => async (dispatch) => {
  try {
    const result = await getAxiosInstance().put(
      "/api/persons/after_first_login"
    );
    dispatch({ type: AFTER_FIRST_LOGIN + DONE });

    return result;
  } catch (e) {
    return false;
  }
};
