import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { alertTimeout } from "../../redux/actions";
import Alert from "@material-ui/lab/Alert";
import { drawerWidth } from "../header/header";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  alert: {
    "@media (min-width: 580px)": {
      marginLeft: `${drawerWidth}px`,
      marginRight: "50px",
    },
  },
}));

const Alert2 = ({
  id,
  isError,
  isSuccess,
  isWarning,
  isInfo,
  text,
  timeout = 3000,
  timeoutFunc,
  alertTimeout,
  persistent = false,
}) => {
  const [timer, setTimer] = useState();
  const [severity, setSeverity] = useState("info");
  const classes = useStyles();

  useEffect(() => {
    if (isError) {
      setSeverity("error");
    }
    if (isWarning) {
      setSeverity("warning");
    }
    if (isSuccess) {
      setSeverity("success");
    }
    if (isInfo) {
      setSeverity("info");
    }
  }, [isError, isSuccess, isWarning]);

  useEffect(() => {
    if (timeout && !persistent) {
      setTimeout(() => {
        alertTimeout(id);
      }, timeout);

      setTimer(id);
    }
  }, [timeout]);

  return (
    <Alert
      className={classes.alert}
      severity={severity}
      onClick={() => alertTimeout(id)}
    >
      {text}
    </Alert>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    alertTimeout: (payload) => dispatch(alertTimeout(payload)),
  })
)(Alert2);
