import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

export class TextProperties extends React.Component {
  constructor() {
    super();
    this.state = {
      size: null,
      maxRows: "1",
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.values.size !== state.size ||
      props.values.maxRows !== state.maxRows
    ) {
      return {
        size: props.values.size,
        maxRows: props.values.maxRows,
      };
    }
    return null;
  }

  setMaxChars(size) {
    this.props.formEdited(true);
    this.setState({ size });
    this.props.setValues({
      ...this.props.values,
      size: size,
    });
  }

  setMaxRows(maxRows) {
    this.props.formEdited(true);
    this.setState({ maxRows });
    this.props.setValues({
      ...this.props.values,
      maxRows: maxRows,
    });
  }

  render() {
    return (
      <Box>
        <Box mt={1}>
          <TextField
            disabled={this.props.displayMode}
            error={this.state.maxChars === "" || this.state.maxChars === null}
            id="outlined-number"
            label="Liczba znaków:"
            type="number"
            size="small"
            fullWidth
            value={this.state.size || ""}
            variant="outlined"
            onChange={(e) => {
              this.setMaxChars(e.target.value);
            }}
          />
        </Box>
        <Box mt={1}>
          <TextField
            disabled={this.props.displayMode}
            id="outlined-number"
            label="Liczba wierszy:"
            type="number"
            size="small"
            fullWidth
            value={this.state.maxRows || ""}
            style={{ color: "white" }}
            variant="outlined"
            onChange={(e) => {
              this.setMaxRows(e.target.value);
            }}
          />
        </Box>
      </Box>
    );
  }
}
