import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { setFirstLoginToFalse } from "../../redux/person/actions";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    display: "block",
    right: "4px",
    top: "4px",
    color:
      theme.palette.primary.main === "#fff200"
        ? "yellow"
        : theme.palette.grey[500],
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  marginButton: {
    margin: "5px",
  },
  dialogBorder: {
    border: theme.palette.primary.main === "#fff200" ? "1px solid yellow" : "",
  },
}));

const InstructionsInfo = () => {
  const my = useSelector((state) => state.my);
  const firstLogin = useSelector((state) => state.firstLogin);
  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");
  const isEditor = my?.user.authorities.localeCompare("ROLE_EDITOR") === 0;
  const hasUnacceptedTerms =
    my?.user.authorities.localeCompare("ROLE_USER_NO_ACCEPTED_TERMS") === 0;

  const dispatch = useDispatch();
  const classes = useStyles();

  let instructionPathForRole = "";
  if (isOwner) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_wlasciciela_organizacji.pdf";
  } else if (isEditor) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_koordynatora.pdf";
  } else if (isUser) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_uzytkownika.pdf";
  }

  const handleCloseFirstLoginDialog = (event, reason) => {
    reason !== "backdropClick" && dispatch(setFirstLoginToFalse());
  };

  if (!my || hasUnacceptedTerms) {
    return null;
  }

  return (
    <Dialog
      classes={{
        paper: classes.dialogBorder,
      }}
      open={firstLogin}
      onClose={handleCloseFirstLoginDialog}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseFirstLoginDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        W prawym górnym rogu znajdują się ikony pomocy. Kliknięcie na przycisk
        "POMOC" otworzy{" "}
        {isUser
          ? "menu, z którego można przejść do pełnej instrukcji bądź filmu instruktażowego. "
          : "pełną instrukcję w formacie PDF. "}
        Po kliknięciu przycisku ze znakiem zapytania otworzy się krótka
        instrukcja dotycząca aktualnego widoku aplikacji. Pełna instrukcja
        {isUser
          ? " oraz film są również dostępne poniżej."
          : " jest również dostępna poniżej."}
        <Box className={classes.infoBox}>
          <Button
            className={classes.marginButton}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => window.open(instructionPathForRole, "_blank")}
          >
            POMOC
          </Button>
          {isUser && (
            <Button
              className={classes.marginButton}
              variant="contained"
              size="small"
              color="primary"
              onClick={() =>
                window.open("/org-media/files/public/zrodlo.mp4", "_blank")
              }
            >
              FILM
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default InstructionsInfo;
