import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import styles from "./index.module.css";

// dragaMode = either 'clone' or 'move'

const QRCodeControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  showSelfDescription,
  configuration = {},
  rowIndex,
  index,
  setIsDragging,
  filledValue,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.QRCODE,
      dragMode,
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
  });

  const [value, setValue] = useState(filledValue || "");
  useEffect(() => {
    setValue(filledValue);
  }, filledValue);

  isSelected = true;

  const placeholder = showSelfDescription ? "QR Code" : configuration.text;
  return (
    <div ref={drag}>
      <div disabled={disabled} className={styles.qrcode} name={name} id={id}>
        {placeholder}
        {configuration.required && " *"}
      </div>
    </div>
  );
};

export default QRCodeControl;
