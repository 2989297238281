import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { connect, useSelector } from "react-redux";
import {
  copyFormToFormGroup,
  fetchFormsGroups,
  moveFormToFormGroup,
} from "../../redux/actions";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
  },
}));

const FormToGroup = ({
  form,
  currentFormProject,
  option,
  open,
  setOpen,
  fetchFormsGroups,
  copyFormToFormGroup,
  moveFormToFormGroup,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const orgFormsGroups = useSelector((s) => s.orgFormsGroups);
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    setSelectedGroup(null);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const renderMenuItem =
    orgFormsGroups &&
    (currentFormProject
      ? orgFormsGroups.filter((formGroup) => {
          return formGroup.id !== currentFormProject.id;
        })
      : orgFormsGroups
    ).map((formGroup) => {
      return (
        <MenuItem key={formGroup.id} value={formGroup}>
          {formGroup.name}
        </MenuItem>
      );
    });

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        {option === "copy"
          ? "Kopiowanie formularza do projektu"
          : "Przenoszenie formularza do innego projektu"}
      </Typography>
    </Box>
  );
  const body = (
    <Box p={1}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="select-outlined-label">Wybierz projekt</InputLabel>
        <Select
          labelId="select-outlined-label"
          id="select-outlined"
          value={selectedGroup || ""}
          fullWidth
          onChange={({ target: { value } }) => setSelectedGroup(value)}
          label="Wybierz projekt"
        >
          {renderMenuItem}
        </Select>
      </FormControl>
    </Box>
  );
  const foot = (
    <Grid>
      <Button
        variant={"contained"}
        color="primary"
        disabled={selectedGroup === null}
        onClick={() => {
          if (option === "copy") {
            copyFormToFormGroup(form.id, selectedGroup.id);
            setOpen(false);
          } else {
            moveFormToFormGroup(form.id, selectedGroup.id);
            setOpen(false);
          }
        }}
      >
        Zapisz
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button variant={"contained"} onClick={() => setOpen(false)}>
        Anuluj
      </Button>
    </Grid>
  );

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {body}
        <hr />
        {foot}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchFormsGroups: () => dispatch(fetchFormsGroups()),
  copyFormToFormGroup: (formId, groupId) =>
    dispatch(copyFormToFormGroup(formId, groupId)),
  moveFormToFormGroup: (formId, targetGroupId) =>
    dispatch(moveFormToFormGroup(formId, targetGroupId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FormToGroup);
