import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import format from "date-fns/format";
import Duration from "duration";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 2,
    paddingLeft: 3,
    right: 0,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  lastTableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 0,
    paddingLeft: 3,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  button: {
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
    backgroundColor: theme.palette.primary.main === "#fff200" && "black",
    color: theme.palette.primary.main === "#fff200" && "#fff200",
  },
  smallScreenTableCell: {
    [theme.breakpoints.down("xs")]: {
      wordWrap: "break-word",
      maxWidth: "65px",
    },
  },
}));

const SingleScheduledForm = ({
  scheduledForm,
  fill,
  fetchFilledForms,
  filledForms,
}) => {
  const [timeToGo, setTimeToGo] = useState("");
  const form = scheduledForm.form;
  const [expired, setExpired] = useState(false);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const classes = useStyles();

  const setRemainingTime = (duration) => {
    if (duration.seconds < 0) {
      setTimeToGo("---");
      setExpired(true);
    } else {
      setTimeToGo(
        `${duration.hours}`.padStart(2, "0") +
          ":" +
          `${duration.minutes % 60}`.padStart(2, "0") +
          ":" +
          `${duration.seconds % 60}`.padStart(2, "0")
      );
    }
  };

  useEffect(() => {
    if (fetchFilledForms && form && form.id) {
      fetchFilledForms(form.id);
    }
  }, [fetchFilledForms, form]);

  useEffect(() => {
    const duration = new Duration(new Date(), new Date(scheduledForm.endDate));
    setRemainingTime(duration);

    const timerId = setInterval(() => {
      const duration = new Duration(
        new Date(),
        new Date(scheduledForm.endDate)
      );

      setRemainingTime(duration);
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        numeric="true"
        className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
      >
        {form.name}
      </TableCell>
      <TableCell
        numeric="true"
        className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
        style={{ maxWidth: smallScreen && "45px" }}
      >
        {format(new Date(scheduledForm.startDate), "yyyy-MM-dd HH:mm")}
      </TableCell>
      <TableCell
        className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
        style={{ maxWidth: smallScreen && "45px" }}
      >
        {format(new Date(scheduledForm.endDate), "yyyy-MM-dd HH:mm")}
      </TableCell>
      <TableCell
        className={`${classes.tableCell} ${classes.smallScreenTableCell}`}
      >
        {timeToGo}
      </TableCell>
      <TableCell
        className={classes.lastTableCell}
        style={{ right: 0 }}
        style={{ wordWrap: "break-word" }}
        // style={{ position: "sticky", right: 0 }}
      >
        <Tooltip title="Kliknij by wypełnić formularz.">
          <Button
            variant="outlined"
            style={{ padding: smallScreen && "0px 0px" }}
            // size="small"
            className={`${classes.button} ${classes.fontSize} `}
            disabled={expired}
            onClick={() => fill(form.id, scheduledForm.id)}
            // className="button"
          >
            Wypełnij
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default SingleScheduledForm;
