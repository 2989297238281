import React from "react";
import s from "./index.module.css";

import { createControl, ItemSubtypes } from "../../DnDTypes";

const components = [
  ItemSubtypes.TEXT,
  ItemSubtypes.TEXTAREA,
  ItemSubtypes.NUMBER,
  ItemSubtypes.DATE,
  ItemSubtypes.FILE,
  // ItemSubtypes.SIGN,
  ItemSubtypes.LOCATION,
  ItemSubtypes.TIME,
  ItemSubtypes.DICTIONARY,
  ItemSubtypes.LABEL,
  // ItemSubtypes.DATETIME,
  // ItemSubtypes.GROUP,
  // ItemSubtypes.REPEATING_GROUP,
  // ItemSubtypes.BOOL,
  // ItemSubtypes.PESEL,
  // ItemSubtypes.QRCODE
];

const Sidebar = () => (
  <aside className={"menu" + s.scroll}>
    <p className="menu-label">Komponenty</p>
    <ul className="menu-list">
      {components.map((name, index) => {
        return (
          <li key={index} className={s.item}>
            {createControl({
              subtype: name,
              dragMode: "clone",
              showSelfDescription: true,
              sidebarMode: true,
            })}
          </li>
        );
      })}
    </ul>
  </aside>
);
export default Sidebar;
