import React, { useEffect, useRef, useState } from "react";
import s from "./index.module.css";
import { ColorBox } from "../color-box";
import { ColorPicker } from "../color-picker";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles, InputAdornment } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import MuiPhoneNumber from "material-ui-phone-number";

const OPERATORS = {
  GREATER_THAN: "większa niż",
  LESS_THAN: "mniejsza niż",
  EQUALS: "równa",
  BETWEEN: "pomiędzy",
};

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
export const RuleEditor = ({
  onDone,
  onCancel,
  editedRule,
  rules,
  setRules,
  setEditedRule,
  phone,
}) => {
  const ref = useRef();
  const classes = useStyles();

  const [active, setActive] = useState(false);
  const [operator, setOperator] = useState(editedRule?.operator || "");
  const [color, setColor] = useState(editedRule?.color || "");
  const [text, setText] = useState(editedRule?.text || "");
  const [smsMessage, setSmsMessage] = useState(editedRule?.smsMessage || "");
  const [arg1, setArg1] = useState(editedRule?.arg1 || "");
  const [arg2, setArg2] = useState(editedRule?.arg2 || "");
  const [smsPhone, setSmsPhone] = useState(
    editedRule?.smsPhone ? editedRule?.smsPhone : phone ? phone : ""
  );
  const [showColorPicker, setshowColorPicker] = useState(false);
  const [valid, setValid] = useState(false);

  const [phoneLostFocus, setPhoneLostFocus] = useState(false);
  useEffect(() => {
    if (
      arg1 !== "" &&
      (text || color || smsMessage) &&
      operator &&
      (operator !== "BETWEEN" || arg2 !== "")
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [text, color, arg1, arg2, smsMessage, operator]);

  // useEffect(() => {
  //   setshowColorPicker(false);
  // }, [color]);

  const editRule = () => {
    let tmpRulesArray = [...rules];

    const editedRuleIndex = rules.findIndex((r) => r === editedRule);
    tmpRulesArray[editedRuleIndex] = {
      operator,
      arg1,
      ...(operator === "BETWEEN" ? { arg2 } : {}),
      ...(color ? { color } : {}),
      ...(text || text === "" ? { text } : {}),
      ...(smsMessage || smsMessage === "" ? { smsMessage } : {}),
      ...(smsPhone || smsPhone === ""
        ? { smsPhone: smsPhone?.replace(/[^+\d]+/g, "") }
        : {}),
    };
    setRules(tmpRulesArray);
    setEditedRule({});
  };
  const doneHandler = () => {
    const ruleData = {
      operator,
      arg1,
      ...(operator === "BETWEEN" ? { arg2 } : {}),
      ...(color ? { color } : {}),
      ...(text || text === "" ? { text } : {}),
      ...(smsMessage || smsMessage === "" ? { smsMessage } : {}),
      ...(smsPhone || smsPhone === ""
        ? { smsPhone: smsPhone?.replace(/[^+\d]+/g, "") }
        : {}),
    };

    onDone(ruleData);
  };
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <span>Jeśli suma punktów formularza jest</span>
      </Grid>
      <Grid item>
        <FormControl>
          <Select
            value={operator}
            onChange={(e) => {
              setOperator(e.target.value);
            }}
            displayEmpty
            style={{ marginTop: 2 }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>Wybierz</em>
            </MenuItem>
            {Object.keys(OPERATORS).map((keyName, i) => (
              <MenuItem value={keyName} key={i}>
                {OPERATORS[keyName]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>{" "}
      {operator && (
        <>
          <Grid item xs={5} md={2}>
            <TextField
              fullWidth
              id="standard-number"
              label={operator === "BETWEEN" ? "wartość 1" : "wartość"}
              type="number"
              variant="outlined"
              size="small"
              value={arg1}
              onChange={({ target: { value: arg1 } }) =>
                setArg1(arg1.replace(/,/g, "."))
              }
            />
          </Grid>
          {operator === "BETWEEN" && (
            <>
              <span>a</span>
              <Grid item xs={5} md={2}>
                <TextField
                  fullWidth
                  id="standard-number"
                  label="wartość 2"
                  type="number"
                  variant="outlined"
                  size="small"
                  value={arg2}
                  onChange={({ target: { value: arg2 } }) =>
                    setArg2(arg2.replace(/,/g, "."))
                  }
                />
              </Grid>
            </>
          )}
          <Grid item>
            <span>ustaw kolor na:</span>
          </Grid>
          {showColorPicker && (
            <Grid item className={classes.outerDiv}>
              <ColorPicker value={color} onChange={(c) => setColor(c)} />
            </Grid>
          )}
          <Grid item className={classes.outerDiv}>
            <ColorBox onClick={() => setshowColorPicker(true)} color={color} />
          </Grid>
          <Grid item>
            {showColorPicker && (
              <a onClick={() => setshowColorPicker(false)}>Wybierz</a>
            )}
          </Grid>
          <Grid item>
            <span>oraz tekst na:</span>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              id="standard-text"
              placeholder={"Wprowadź tekst."}
              type="text"
              variant="outlined"
              size="small"
              value={text}
              onChange={({ target: { value: text } }) => setText(text)}
              InputLabelProps={{ shrink: false }}
            />
          </Grid>
          <Grid item>
            <span>wiadomość SMS:</span>
          </Grid>
          <Grid item xs={6} md={4} lg={2} direction="row">
            <TextField
              fullWidth
              id="standard-text"
              placeholder={"Wprowadź treść SMS."}
              type="text"
              multiline
              rows={2}
              rowsMax={4}
              variant="outlined"
              size="small"
              value={smsMessage}
              onChange={({ target: { value: text } }) => setSmsMessage(text)}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      display: "flex",
                      alignItems: "baseline",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <span style={{ fontSize: "12px" }}>
                            Jedna wiadomość SMS zawiera około 140 znaków.
                          </span>
                          <br />
                          <br />
                          <span style={{ fontSize: "12px" }}>
                            {`Możesz używać następujących parametrów: {imie}, {nazwisko}, {identyfikator}, {telefon}, {suma_punktow}.`}
                          </span>
                        </div>
                      }
                    >
                      <HelpIcon color="disabled" fontSize="small" />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />

            <span className={s.hintText}>
              {`Ilośc znaków Twojej wiadomości SMS: ${smsMessage?.length}`}
            </span>
          </Grid>
          <Grid item>
            <span>kontaktowy numer telefonu:</span>
          </Grid>
          <Grid item>
            <MuiPhoneNumber
              name="phone"
              label="Numer telefonu"
              data-cy="user-phone"
              defaultCountry={"pl"}
              fullWidth
              variant="outlined"
              value={smsPhone || ""}
              onChange={(e, { dialCode }) => {
                if (e) {
                  setSmsPhone(e !== "+" + dialCode ? e : null);
                }
                setPhoneLostFocus(false);
              }}
            />
            {/* <MuiPhoneNumber
              name="phone"
              variant="outlined"
              data-cy="user-phone"
              defaultCountry={"pl"}
              regions={"europe"}
              autoFormat={false}
              countryCodeEditable={false}
              fullWidth
              size="small"
              value={smsPhone || ""}
              onChange={(e) => {
                if (e !== "+48") {
                  setSmsPhone(e);
                } else {
                  setSmsPhone("");
                }
                setPhoneLostFocus(false);
              }}
              onFocus={() => setPhoneLostFocus(false)}
              onBlur={() => setPhoneLostFocus(true)}
              error={
                phoneLostFocus &&
                smsPhone &&
                smsPhone.length !== 12 &&
                smsPhone !== "+48"
              }
              helperText={
                phoneLostFocus &&
                smsPhone &&
                smsPhone.length !== 12 &&
                smsPhone !== "+48" &&
                "Nieprawidłowy format numeru telefonu"
              }
              inputProps={{
                maxLength: 12,
              }}
            /> */}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!valid}
              onClick={!editedRule ? doneHandler : editRule}
              size="small"
            >
              {editedRule ? `Edytuj` : `Dodaj`}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={onCancel} size="small">
              Anuluj
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};
