import React, { useState } from "react";
import { format } from "date-fns";
import * as classnames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { alertAdd } from "../../redux/actions";
import { ListItem } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  passedDate: {
    color: theme.palette.warning,
  },
  token: {
    overflow: "hidden",
    overflowWrap: "break-word",
  },
}));

export const SingleApiKey = ({ apiKey, removeKey }) => {
  const [showToken, setShowToken] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const expirationDate = new Date(apiKey.expires);
  const tokenIsExpired = expirationDate < new Date();
  const dispatch = useDispatch();

  const classes = useStyles();

  const handleClose = () => {
    setTooltipOpen(false);
  };

  const handleOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <ListItem key={apiKey.tokenId}>
      Etykieta: {apiKey.label}, data ważności:&nbsp;
      <span
        className={classnames({
          [classes.passedDate]: tokenIsExpired,
        })}
      >
        {format(expirationDate, "yyyy-MM-dd HH:mm:ss")}
      </span>
      &nbsp;
      <Tooltip
        open={tooltipOpen}
        placement="top"
        title={showToken ? "Ukryj" : "Pokaż"}
      >
        <span
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
          onClick={handleClose}
        >
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setShowToken(!showToken);
            }}
            size="small"
          >
            {showToken ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </span>
      </Tooltip>
      &nbsp;
      <CopyToClipboard
        text={apiKey.token}
        onCopy={() => {
          dispatch(
            alertAdd({
              text: "Klucz skopiowany do schowka",
              timeout: 1000,
              isInfo: true,
            })
          );
        }}
      >
        <Tooltip placement="top" title="Skopiuj do schowka">
          <span>
            <IconButton
              size="small"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </span>
        </Tooltip>
      </CopyToClipboard>
      &nbsp;
      <Tooltip placement="top" title="Usuń">
        <span>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              removeKey(apiKey.tokenId);
            }}
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      {showToken && (
        <div className={classes.token}>
          <br />
          <span>{apiKey.token}</span>
          <br />
          <br />
        </div>
      )}
    </ListItem>
  );
};
