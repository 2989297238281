import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllMyTests } from "../../redux/actions";

const ViewAllData = () => {
  const dispatch = useDispatch();
  const allMyTests = useSelector((s) => s.allMyTests);

  useEffect(() => {
    dispatch(fetchAllMyTests());
  }, [allMyTests]);

  return <div>Wyniki wszystkich testów</div>;
};

export default ViewAllData;
