import { Box, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import React, { useState } from "react";

export const MessageWelcomeFarewell = ({
  displayMode,
  welcomeFarewellMessages,
  setWelcomeFarewellMessages,
}) => (
  <Box>
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
          Wiadomości powitalne{" "}
          <Tooltip
            title={`Wiadomość, która zostanie wysłana do ${
              process.env.REACT_APP_USE_PATIENT === "true"
                ? "pacjenta"
                : "użytkownika"
            } w przypadku dołączenia go do formularza`}
          >
            <HelpIcon
              style={{
                color: "lightgrey",
                width: 20,
                height: 20,
              }}
            ></HelpIcon>
          </Tooltip>
        </Typography>
        <Box>
          <Box pb={2}>
            <TextField
              disabled={displayMode}
              label="Powitalna wiadomość SMS"
              variant="outlined"
              type="text"
              multiline
              value={welcomeFarewellMessages.welcomeSmsMessage || ""}
              fullWidth
              rows={2}
              rowsMax={4}
              onChange={({ target: { value } }) =>
                setWelcomeFarewellMessages({
                  ...welcomeFarewellMessages,
                  welcomeSmsMessage: value,
                })
              }
            />
            <p>Jedna wiadomość SMS zawiera około 140 znaków.</p>
            <p>
              Ilość znaków Twojej powitalnej wiadomości SMS:{" "}
              {welcomeFarewellMessages.welcomeSmsMessage.length}
            </p>
          </Box>
          <TextField
            disabled={displayMode}
            label="Powitalna wiadomość Email"
            variant="outlined"
            type="text"
            multiline
            value={welcomeFarewellMessages.welcomeEmailMessage || ""}
            fullWidth
            rows={2}
            rowsMax={4}
            onChange={({ target: { value } }) =>
              setWelcomeFarewellMessages({
                ...welcomeFarewellMessages,
                welcomeEmailMessage: value,
              })
            }
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h6" gutterBottom>
          Wiadomości końcowe{" "}
          <Tooltip title="Wiadomość, która zostanie wysłana do użytkownika w przypadku odłączenia go od formularza lub zakończenia aktualnego dostępu">
            <HelpIcon
              style={{
                color: "lightgrey",
                width: 20,
                height: 20,
              }}
            ></HelpIcon>
          </Tooltip>
        </Typography>
        <Box>
          <Box pb={2}>
            <TextField
              disabled={displayMode}
              label="Końcowa wiadomość SMS"
              variant="outlined"
              type="text"
              multiline
              value={welcomeFarewellMessages.farewellSmsMessage || ""}
              fullWidth
              rows={2}
              rowsMax={4}
              onChange={({ target: { value } }) =>
                setWelcomeFarewellMessages({
                  ...welcomeFarewellMessages,
                  farewellSmsMessage: value,
                })
              }
            />
            <p>Jedna wiadomość SMS zawiera około 140 znaków.</p>
            <p>
              Ilość znaków Twojej końcowej wiadomości SMS:{" "}
              {welcomeFarewellMessages.farewellSmsMessage.length}
            </p>
          </Box>
          <TextField
            disabled={displayMode}
            label="Końcowa wiadomość Email"
            variant="outlined"
            type="text"
            multiline
            value={welcomeFarewellMessages.farewellEmailMessage || ""}
            fullWidth
            rows={2}
            rowsMax={4}
            onChange={({ target: { value } }) =>
              setWelcomeFarewellMessages({
                ...welcomeFarewellMessages,
                farewellEmailMessage: value,
              })
            }
          />
        </Box>
      </Grid>
    </Grid>
  </Box>
);
