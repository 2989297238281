import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export const NotificationReminder = ({
  displayMode,
  timeBeforeEnd,
  setTimeBeforeEndFilling,
  notification,
  setNotification,
  timesToSend,
  setTimesToSend,
}) => {
  return (
    <Grid>
      <Typography variant="h6" gutterBottom>
        Ustawienia powiadomień przed końcem wypełniania
      </Typography>
      <Box p={1}>
        <Box mt={1}>
          <Grid item xs={3}>
            <TextField
              id="outlined-number"
              label="Ilość powiadomień"
              type="text"
              variant="outlined"
              size="small"
              inputProps={{ min: 0, inputMode: "numeric", pattern: "[0-9]*" }}
              disabled={displayMode}
              value={timesToSend}
              onChange={(e) => {
                if (e.target.value >= 0) {
                  setTimesToSend(e.target.value);
                }
              }}
            />
          </Grid>
        </Box>

        <hr />
        <>
          {timesToSend &&
            [...Array(parseInt(timesToSend))].map((e, index) => {
              return (
                <Box m={2} key={index}>
                  <Grid item xs={3}>
                    <Typography variant="h6" gutterBottom>
                      {`Powiadomienie: ${index + 1}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={displayMode}
                      id="outlined-number"
                      label="Czas"
                      helperText="Ile minut przed upłynięciem daty wypełnienia ma przyjść powiadomienie"
                      type="text"
                      variant="outlined"
                      size="small"
                      inputProps={{
                        min: 0,
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                      value={(timeBeforeEnd && timeBeforeEnd[index]) || ""}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          let tmpArray = [...timeBeforeEnd];
                          tmpArray[index] = e.target.value;
                          setTimeBeforeEndFilling(tmpArray);
                        }
                      }}
                      fullWidth
                    />
                  </Grid>
                  <br />
                  <Grid item xs={3}>
                    <TextField
                      disabled={displayMode}
                      label="Treść powiadomienia"
                      multiline
                      variant="outlined"
                      rows={2}
                      rowsMax={4}
                      fullWidth
                      value={(notification && notification[index]) || ""}
                      onChange={(e) => {
                        let tmpArray = [...notification];
                        tmpArray[index] = e.target.value;
                        setNotification(tmpArray);
                      }}
                    />
                  </Grid>
                  <hr />
                </Box>
              );
            })}
        </>
        {/* <Box mt={1}>
          <Grid item xs={3}>
            <TextField
              label="Treść powiadomienia"
              multiline
              variant="outlined"
              rows={2}
              rowsMax={4}
              fullWidth
              value={smsNotification}
              onChange={(e) => setSmsNotification(e.target.value)}
            />
          </Grid>
        </Box> */}
      </Box>
    </Grid>
  );
};
