import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";
import { logout } from "../../redux/actions";

const IdleTimerContainer = () => {
  const idleTimerRef = useRef(null);

  const dispatch = useDispatch();
  const my = useSelector((state) => state.my);

  const [openIdleModal, setOpenIdleModal] = useState(false);
  const sessionTimeoutRef = useRef(null);

  const [counter, setCounter] = useState(60);
  const [startCountingDown, setStartCountingDown] = useState(false);

  useEffect(() => {
    if (startCountingDown) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, startCountingDown]);

  const onIdle = () => {
    setOpenIdleModal(true);
    setStartCountingDown(true);
    sessionTimeoutRef.current = setTimeout(logOut, 60 * 1000);
  };

  const stayActive = () => {
    setOpenIdleModal(false);
    setCounter(60);
    setStartCountingDown(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const logOut = () => {
    setOpenIdleModal(false);
    clearTimeout(sessionTimeoutRef.current);
    const isUser = my.user.authorities.localeCompare("ROLE_USER") === 0;
    dispatch(logout({ isUser }));
  };

  return (
    <div>
      <ConfirmDialogMUI
        handleClose={() => setOpenIdleModal(false)}
        open={openIdleModal}
        text={`Automatyczne wylogowanie nastąpi za ${counter} ${
          counter > 4 || counter === 0
            ? "sekund"
            : counter < 5 && counter > 1
            ? "sekundy"
            : "sekundę"
        }`}
        yesAction={logOut}
        noAction={stayActive}
        yesLabel="Wyloguj"
        noLabel="Pozostań na stronie"
      />
      <IdleTimer
        ref={idleTimerRef}
        timeout={60 * 1000 * 14}
        onIdle={onIdle}
      ></IdleTimer>
    </div>
  );
};

export default IdleTimerContainer;
