import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 2,
    paddingLeft: 3,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  button: {
    fontSize: theme.typography.body1.fontSize,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
    backgroundColor: theme.palette.primary.main === "#fff200" && "black",
    color: theme.palette.primary.main === "#fff200" && "#fff200",
  },
}));

const SingleForm = ({
  form,
  fill,
  fetchFilledForms,
  filledForms,
  history,
  preventFilling = false,
}) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        {form.name}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Tooltip title="Wypełnione formularze. Kliknij by obejrzeć.">
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => {
              history.push(`/formdata/all/${form.id}`);
            }}
            // className="button"
          >
            {form.filledFormCount || 0}
          </Button>
        </Tooltip>
      </TableCell>
      {!preventFilling && (
        <TableCell align="right" className={classes.tableCell}>
          <Tooltip title="Kliknij by wypełnić formularz.">
            <Button
              variant="outlined"
              className={`${classes.button} ${classes.fontSize} `}
              onClick={() => fill(form.id)}
              // className="button"
            >
              Wypełnij
            </Button>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default SingleForm;
