import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchActiveForms,
  fetchActiveFormsAll,
  fetchForms,
  fetchScheduledForms,
  fetchOrganization,
  fetchOrgUsers,
  fetchPlannedForms,
  fetchPlannedFormsAll,
  fetchFilledFormsExtended,
  fetchArchivedForms,
  fetchSavedFormData,
  fetchFormsScheduledForFuture,
  fetchFilledFormData,
} from "../redux/actions";
import {
  fetchOrganizationFutureTerms,
  fetchTermsAcceptedByUser,
} from "../redux/organization/actions";
import Can from "../components/can";
import { NavLink, Redirect } from "react-router-dom";
import { checkPermission } from "../rbac";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Box, Button } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import PageTitle from "../components/page-title";
import Duration from "duration";
import Loader from "../components/loader";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TermsAcceptance from "../components/terms-acceptance";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  closeIconOnTop: {
    "align-self": "flex-start",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  alertHighContrast: {
    "& .MuiAlert-icon": {
      color: theme.palette.text.primary,
    },
    backgroundColor: "#000",
    border: "1px solid #fff200",
    color: theme.palette.text.primary,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  buttonContrast: {
    backgroundColor: "black",
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "black",
    },
  },
}));

const Main = ({
  fetchOrgUsers,
  activeForms,
  plannedForms,
  archivedForms,
  savedForms,
  history,
  match,
  scheduledForFutureForms,
  allFilledForms,
}) => {
  const my = useSelector((state) => state.my);
  const roles = useSelector((state) => state.roles);
  const forms = useSelector((state) => state.orgForms);
  const termsAcceptedByUser = useSelector((s) => s.termsAcceptedByUser);
  const orgFutureTerms = useSelector((s) => s.orgFutureTerms);
  const fetchingOrgFutureTerms = useSelector((s) => s.fetchingOrgFutureTerms);
  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const canFetchForms = checkPermission("forms:fetch", roles);

  const dispatch = useDispatch();

  const [archivedForm, setArchivedForm] = useState({});
  const filledFormsExtended = useSelector(
    (s) => archivedForm && s.filledFormsExtended[archivedForm.id]
  );
  const [initialization, setInitialization] = useState(true);

  const [
    openPlannedActiveFormsAlert,
    setOpenPlannedActiveFormsAlert,
  ] = useState(true);
  const [
    expandPlannedActiveFormsAlert,
    setExpandPlannedActiveFormsAlert,
  ] = useState(false);

  const [openSavedFormsAlert, setOpenSavedFormsAlert] = useState(true);
  const [expandSavedFormsAlert, setExpandSavedFormsAlert] = useState(false);

  const [openFilledFormsAlert, setOpenFilledFormsAlert] = useState(true);
  const [expandFilledFormsAlert, setExpandFilledFormsAlert] = useState(false);

  const [futureTermsAccepted, setFutureTermsAccepted] = useState(false);

  const globalTheme = useSelector((s) => s.globalTheme);
  const baseFontSize = useSelector((s) => s.baseFontSize);

  const classes = useStyles();

  useEffect(() => {
    if (checkPermission("admin:fetchOrgUsers", roles)) {
      fetchOrgUsers();
    }
  }, [roles]);

  // useEffect(() => {
  //   if (!isUser && canFetchForms) {
  //     dispatch(
  //       fetchForms({
  //         page: 0,
  //         pageSize: 100,
  //       })
  //     );
  //     dispatch(fetchScheduledForms());
  //   }
  // }, [isUser, canFetchForms]);

  useEffect(() => {
    if (checkPermission("admin:fetchOrgReports", roles)) {
    }
  }, [roles]);

  useEffect(() => {
    if (isUser) {
      dispatch(fetchActiveFormsAll());
      dispatch(fetchPlannedFormsAll());
      dispatch(
        fetchArchivedForms({
          page: 0,
          pageSize: 10,
        })
      );
      dispatch(
        fetchSavedFormData({
          page: 0,
          pageSize: 10,
        })
      );
      dispatch(
        fetchFormsScheduledForFuture({
          page: 0,
          pageSize: 10,
        })
      );
      dispatch(
        fetchFilledFormData({
          page: 0,
          pageSize: 10,
        })
      );
    }
  }, []);

  useEffect(() => {
    let plannedActiveForms = plannedForms
      ? plannedForms.filter(
          ({ form, endDate }) =>
            form.status === "ACTIVE" &&
            new Duration(new Date(), new Date(endDate)).seconds > 0
        )
      : [];

    plannedActiveForms = plannedActiveForms.filter(
      ({ endDate }) => new Duration(new Date(), new Date(endDate)).seconds > 0
    );

    if (plannedForms && activeForms) {
      // setInitialization(true);
      if (
        activeForms?.length === 1 &&
        plannedActiveForms?.length === 0 &&
        my.user.authorities.localeCompare("ROLE_USER") === 0
      ) {
        setTimeout(() => {
          // setInitialization(false);
          history.push(`/formdata/add/${activeForms[0].id}`);
        }, 1000);
      } else if (
        activeForms?.length === 0 &&
        plannedActiveForms?.length === 1 &&
        plannedActiveForms.filter(
          ({ endDate }) =>
            new Duration(new Date(), new Date(endDate)).seconds > 0
        ).length === 1 &&
        my.user.authorities.localeCompare("ROLE_USER") === 0
      ) {
        setTimeout(() => {
          // setInitialization(false);
          history.push({
            pathname: `/formdata/fill-scheduled-test/${plannedActiveForms[0].form.id}/${plannedActiveForms[0].id}`,
            state: { endDate: plannedActiveForms[0].endDate },
          });
        }, 1000);
      }
      setTimeout(() => {
        setInitialization(false);
      }, 1000);
    }

    if (
      plannedActiveForms?.length === 0 &&
      activeForms?.length === 0 &&
      archivedForms?.filter(({ status }) => status === "ACTIVE").length === 1
    ) {
      setArchivedForm(
        archivedForms?.filter(({ status }) => status === "ACTIVE")[0]
      );
      dispatch(
        fetchFilledFormsExtended(
          archivedForms?.filter(({ status }) => status === "ACTIVE")[0].id,
          {
            sort: `completedAt`,
          }
        )
      );
    }
  }, [activeForms, plannedForms, archivedForms]);

  useEffect(() => {
    if (isUser && !orgFutureTerms && !fetchingOrgFutureTerms) {
      dispatch(fetchOrganizationFutureTerms());
    }
  }, [isUser]);

  useEffect(() => {
    isUser && !termsAcceptedByUser && dispatch(fetchTermsAcceptedByUser());
  }, [isUser]);

  useEffect(() => {
    if (isUser && termsAcceptedByUser && orgFutureTerms) {
      const founded = termsAcceptedByUser.find(
        ({ terms }) => terms.id === orgFutureTerms.id
      );
      if (founded && !founded.revokedAt) {
        setFutureTermsAccepted(true);
      } else {
        setFutureTermsAccepted(false);
      }
    }
  }, [termsAcceptedByUser, orgFutureTerms]);

  function parseISOString(s) {
    let date = new Date(s);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    let hour = date.getHours();
    // let hour =
    //   date.getHours().toString().length === 1
    //     ? "0" + date.getHours()
    //     : date.getHours();
    let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return (
      <>
        {
          <strong
            style={{ color: "inherit" }}
          >{`${dt}-${month}-${year}`}</strong>
        }{" "}
        godzina:{" "}
        {<strong style={{ color: "inherit" }}>{`${hour}:${minutes}`}</strong>}
      </>
    );
  }

  var remainingActiveForms;
  if (plannedForms?.length < 3) {
    remainingActiveForms = activeForms?.slice(
      3 - plannedForms.length,
      activeForms.length
    );
  } else {
    remainingActiveForms = activeForms;
  }

  const loggedUserMainPage = () => (
    <Box>
      {initialization && isUser ? (
        <Box p={3}>
          {<Loader loading={true} text="Pobieranie formularzy" />}
        </Box>
      ) : (
        <Box p={1}>
          <PageTitle title="Dzisiejsze akcje" />

          {!my.firstName && (
            <Box mt={1}>
              <Alert
                icon={<PersonIcon />}
                severity="warning"
                className={`${
                  globalTheme === "high-contrast" && classes.alertHighContrast
                } ${classes.fontSize}`}
              >
                Prosimy o wypełnienie profilu.{" "}
                <NavLink
                  to="/profile"
                  className={`${
                    globalTheme === "high-contrast" &&
                    classes.aalertHighContrast
                  } ${classes.fontSize}`}
                >
                  PRZEJDŹ DO PROFILU
                </NavLink>
              </Alert>
            </Box>
          )}

          {orgFutureTerms && !futureTermsAccepted && (
            <Box mt={1}>
              <Alert
                severity="warning"
                className={`${
                  globalTheme === "high-contrast" && classes.alertHighContrast
                } ${classes.fontSize}`}
              >
                {`Regulamin zostanie zmieniony w dniu ${format(
                  new Date(orgFutureTerms.activeSince),
                  "yyyy-MM-dd"
                )}. Prosimy o zapoznanie
                              się i akceptację warunków.`}

                <TermsAcceptance
                  terms={orgFutureTerms}
                  acceptance={true}
                  future={true}
                ></TermsAcceptance>
              </Alert>
            </Box>
          )}

          {scheduledForFutureForms?.length > 0 && isUser && (
            <Box mt={1}>
              <Alert
                severity="warning"
                className={`${
                  globalTheme === "high-contrast" && classes.alertHighContrast
                } ${classes.fontSize}`}
              >
                <AlertTitle>
                  Zbliżające się formularze do wypełnienia
                </AlertTitle>
                <ul
                  style={{ listStyleType: "circle", marginLeft: "15px" }}
                ></ul>
                {scheduledForFutureForms?.map(
                  ({ id, form, startDate }, index) => (
                    <li key={index}>
                      <span style={{ fontWeight: "bold" }}>{form.name} </span>
                      {parseISOString(startDate)}
                    </li>
                  )
                )}
              </Alert>
            </Box>
          )}

          {(plannedForms?.length > 0 || activeForms?.length > 0) && isUser && (
            <Box mt={1}>
              <Collapse in={openPlannedActiveFormsAlert}>
                <Alert
                  style={{
                    marginBottom: "0px",
                    // backgroundColor: "black",
                    // border: "1px solid yellow",
                  }}
                  className={`${
                    globalTheme === "high-contrast" && classes.alertHighContrast
                  } ${classes.fontSize}`}
                  severity="error"
                  action={
                    <IconButton
                      className={classes.closeIconOnTop}
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenPlannedActiveFormsAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Twoje formularze do wypełnienia:
                  <span>
                    <ul style={{ listStyleType: "circle", marginLeft: "15px" }}>
                      {plannedForms
                        ?.slice(0, 3)
                        .map(({ id, form, endDate }, index) => (
                          <li key={index}>
                            <span style={{ fontWeight: "bold" }}>
                              {form.name}{" "}
                            </span>
                            {new Duration(new Date(), new Date(endDate))
                              .seconds < 0 ? (
                              <span>Czas na wypełnienie minął</span>
                            ) : (
                              <a
                                className={`${
                                  globalTheme === "high-contrast" &&
                                  classes.aalertHighContrast
                                } ${classes.fontSize}`}
                                onClick={() =>
                                  history.push({
                                    pathname: `/formdata/fill-scheduled-test/${form.id}/${id}`,
                                    state: { endDate },
                                  })
                                }
                              >
                                WYPEŁNIJ
                              </a>
                            )}
                          </li>
                        ))}
                      {plannedForms?.length < 3 &&
                        activeForms
                          ?.slice(0, 3 - plannedForms.length)
                          .map((form, index) => (
                            <li key={index}>
                              <span style={{ fontWeight: "bold" }}>
                                {form.name}{" "}
                              </span>
                              <a
                                className={`${
                                  globalTheme === "high-contrast" &&
                                  classes.aalertHighContrast
                                } ${classes.fontSize}`}
                                onClick={() =>
                                  history.push(`/formdata/add/${form.id}`)
                                }
                              >
                                WYPEŁNIJ
                              </a>
                            </li>
                          ))}
                      <Collapse in={expandPlannedActiveFormsAlert}>
                        {plannedForms
                          ?.slice(3, plannedForms.length)
                          .map(({ id, form, endDate }, index) => (
                            <li key={index}>
                              <span style={{ fontWeight: "bold" }}>
                                {form.name}{" "}
                              </span>
                              {new Duration(new Date(), new Date(endDate))
                                .seconds < 0 ? (
                                <span>Czas na wypełnienie minął</span>
                              ) : (
                                <a
                                  className={`${
                                    globalTheme === "high-contrast" &&
                                    classes.aalertHighContrast
                                  } ${classes.fontSize}`}
                                  onClick={() =>
                                    history.push({
                                      pathname: `/formdata/fill-scheduled-test/${form.id}/${id}`,
                                      state: { endDate },
                                    })
                                  }
                                >
                                  WYPEŁNIJ
                                </a>
                              )}
                            </li>
                          ))}
                        {remainingActiveForms?.map((form, index) => (
                          <li key={index}>
                            <span style={{ fontWeight: "bold" }}>
                              {form.name}{" "}
                            </span>
                            <a
                              className={`${
                                globalTheme === "high-contrast" &&
                                classes.alertHighContrast
                              } ${classes.fontSize}`}
                              onClick={() =>
                                history.push(`/formdata/add/${form.id}`)
                              }
                            >
                              WYPEŁNIJ
                            </a>
                          </li>
                        ))}
                      </Collapse>
                    </ul>
                    {(plannedForms?.length > 3 ||
                      plannedForms?.length + activeForms?.length > 3) && (
                      <IconButton
                        className={classes.fontSize}
                        aria-label="close"
                        color="inherit"
                        size="small"
                        outline="true"
                        onClick={() => {
                          setExpandPlannedActiveFormsAlert(
                            !expandPlannedActiveFormsAlert
                          );
                        }}
                      >
                        {!expandPlannedActiveFormsAlert ? (
                          <ExpandMoreIcon fontSize="large" fontSize="inherit" />
                        ) : (
                          <ExpandLessIcon fontSize="large" fontSize="inherit" />
                        )}
                        <p style={{ fontSize: "0.8em" }}>
                          {!expandPlannedActiveFormsAlert
                            ? `Rozwiń listę`
                            : `Zwiń listę`}
                        </p>
                      </IconButton>
                    )}
                  </span>
                </Alert>
              </Collapse>
            </Box>
          )}
          {savedForms && savedForms?.length > 0 && isUser && (
            <Box mt={1}>
              <Collapse in={openSavedFormsAlert}>
                <Alert
                  style={{ marginBottom: "0px" }}
                  className={`${
                    globalTheme === "high-contrast" && classes.alertHighContrast
                  } ${classes.fontSize}`}
                  severity="success"
                  action={
                    <IconButton
                      className={classes.closeIconOnTop}
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenSavedFormsAlert(!openSavedFormsAlert);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Twoje zapisane formularze:
                  <ul style={{ listStyleType: "circle", marginLeft: "15px" }}>
                    {savedForms?.slice(0, 3).map((formData, index) => (
                      <li key={index}>
                        <span style={{ fontWeight: "bold" }}>
                          {formData.form.name}{" "}
                        </span>
                        <a
                          c
                          className={`${
                            globalTheme === "high-contrast" &&
                            classes.aalertHighContrast
                          } ${classes.fontSize}`}
                          onClick={() => {
                            history.push(
                              `/formdata/edit/${formData.form.id}/${formData.id}`
                            );
                          }}
                        >
                          EDYTUJ
                        </a>
                      </li>
                    ))}
                    <Collapse in={expandSavedFormsAlert}>
                      {savedForms
                        ?.slice(3, savedForms.length)
                        .map((formData, index) => (
                          <li key={index}>
                            <span style={{ fontWeight: "bold" }}>
                              {formData.form.name}{" "}
                            </span>
                            <a
                              className={`${
                                globalTheme === "high-contrast" &&
                                classes.aalertHighContrast
                              } ${classes.fontSize}`}
                              onClick={() => {
                                history.push(
                                  `/formdata/edit/${formData.form.id}/${formData.id}`
                                );
                              }}
                            >
                              EDYTUJ
                            </a>
                          </li>
                        ))}
                    </Collapse>
                  </ul>
                  {savedForms?.length > 3 && (
                    <IconButton
                      className={classes.fontSize}
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setExpandSavedFormsAlert(!expandSavedFormsAlert);
                      }}
                    >
                      {!expandSavedFormsAlert ? (
                        <ExpandMoreIcon fontSize="large" fontSize="inherit" />
                      ) : (
                        <ExpandLessIcon fontSize="large" fontSize="inherit" />
                      )}
                      <p style={{ fontSize: "0.8em" }}>
                        {!expandFilledFormsAlert
                          ? `Rozwiń listę`
                          : `Zwiń listę`}
                      </p>
                    </IconButton>
                  )}
                </Alert>
              </Collapse>
            </Box>
          )}
          {allFilledForms?.length > 0 && isUser && (
            <Box mt={1}>
              <Collapse in={openFilledFormsAlert}>
                <Alert
                  className={`${
                    globalTheme === "high-contrast" && classes.alertHighContrast
                  } ${classes.fontSize}`}
                  style={{ marginBottom: "0px" }}
                  severity="success"
                  action={
                    <IconButton
                      className={classes.closeIconOnTop}
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenFilledFormsAlert(!openFilledFormsAlert);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Twoje wypełnione formularze:
                  <ul style={{ listStyleType: "circle", marginLeft: "15px" }}>
                    {allFilledForms
                      .sort(function (a, b) {
                        return a.completedAt < b.completedAt
                          ? -1
                          : a.completedAt > b.completedAt
                          ? 1
                          : 0;
                      })
                      .reverse()
                      .slice(0, 3)
                      .map(({ form, completedAt, id }, index) => (
                        <li key={index} style={{ marginBottom: "5px" }}>
                          <span>
                            Nazwa formularza:{" "}
                            {
                              <strong style={{ color: "inherit" }}>
                                {form.name}
                              </strong>
                            }{" "}
                          </span>
                          <br />
                          <span>
                            Data wypełnienia: {parseISOString(completedAt)}
                          </span>
                          <br />
                          <a
                            className={`${
                              globalTheme === "high-contrast" &&
                              classes.aalertHighContrast
                            } ${classes.fontSize}`}
                            onClick={() => {
                              history.push(`/formdata/${id}`);
                            }}
                          >
                            Zobacz szczegóły wypełnienia
                          </a>
                          <br />
                        </li>
                      ))}
                    <Collapse in={expandFilledFormsAlert}>
                      {allFilledForms
                        .sort(function (a, b) {
                          return a.completedAt < b.completedAt
                            ? -1
                            : a.completedAt > b.completedAt
                            ? 1
                            : 0;
                        })
                        .reverse()
                        .slice(3, allFilledForms.length)
                        .map(({ form, completedAt, id }, index) => (
                          <li key={index} style={{ marginBottom: "5px" }}>
                            <span>
                              Nazwa formularza:{" "}
                              {
                                <strong style={{ color: "inherit" }}>
                                  {form.name}
                                </strong>
                              }{" "}
                            </span>
                            <br />
                            <span>
                              Data wypełnienia: {parseISOString(completedAt)}
                            </span>
                            <br />
                            <a
                              className={`${
                                globalTheme === "high-contrast" &&
                                classes.aalertHighContrast
                              } ${classes.fontSize}`}
                              onClick={() => {
                                history.push(`/formdata/${id}`);
                              }}
                            >
                              Zobacz szczegóły wypełnienia
                            </a>
                            <br />
                          </li>
                        ))}{" "}
                    </Collapse>
                  </ul>
                  {allFilledForms?.length > 3 && (
                    <IconButton
                      className={classes.fontSize}
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setExpandFilledFormsAlert(!expandFilledFormsAlert);
                      }}
                    >
                      {!expandFilledFormsAlert ? (
                        <ExpandMoreIcon fontSize="large" fontSize="inherit" />
                      ) : (
                        <ExpandLessIcon fontSize="large" fontSize="inherit" />
                      )}
                      <p style={{ fontSize: "0.8em" }}>
                        {!expandFilledFormsAlert
                          ? `Rozwiń listę`
                          : `Zwiń listę`}
                      </p>
                    </IconButton>
                  )}
                </Alert>
              </Collapse>
            </Box>
          )}
          {/* {archivedForms && archivedForms.length > 0 && isUser && (
            <Box mt={1}>
              <Alert severity="success">
                Twoje wypełnione formularze:
                <ul style={{ listStyleType: "circle", marginLeft: "15px" }}>
                  {archivedForms?.map((form, index) => (
                    <li key={index}>
                      <span style={{ fontWeight: "bold" }}>{form.name} </span>
                      <a
                        onClick={() => {
                          history.push(`/formdata/all/${form.id}`);
                        }}
                      >
                         {parseISOString(form.startDate)} 
                        ZOBACZ HISTORIĘ WYPEŁNIEŃ
                      </a>
                    </li>
                  ))}
                </ul>
              </Alert>
            </Box>
          )} */}
          {isUser && (
            <Box mt={1}>
              <Button
                className={`${
                  globalTheme === "high-contrast" && classes.buttonContrast
                } ${classes.fontSize}`}
                variant="contained"
                color="primary"
                href="/forms/active"
              >
                Formularze
              </Button>
            </Box>
          )}
          {my.firstName &&
            plannedForms?.length === 0 &&
            activeForms?.length === 0 &&
            archivedForms?.length === 0 && <span>Brak akcji</span>}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Can
        permission="main:view"
        ok={loggedUserMainPage}
        not={() => <Redirect to="/login" />}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  orgUsers: state.orgUsers,
  orgReports: state.orgReports,
  organization: state.organization,
  activeForms: state.activeFormsAll,
  plannedForms: state.plannedFormsAll,
  archivedForms: state.archivedForms,
  savedForms: state.savedForms,
  scheduledForFutureForms: state.scheduledForFutureForms,
  allFilledForms: state.allFilledForms,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgUsers: () => dispatch(fetchOrgUsers()),
  fetchForms: () => dispatch(fetchForms()),
  fetchOrganization: () => dispatch(fetchOrganization()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
