import { DONE, FAILED, START } from '../../constants'
import { getAxiosInstance } from '../common'

export const UPDATE_CONFIG_GLOBAL = 'UPDATE_CONFIG_GLOBAL'

export const updateConfigGlobal = (payload) => async dispatch => {
  dispatch({type: UPDATE_CONFIG_GLOBAL + START})
  try {
    const result = await getAxiosInstance().put(`/api/config_global/1`, payload);
    dispatch({type: UPDATE_CONFIG_GLOBAL + DONE, payload: result.data})

    return result.data
  } catch (payload) {
    dispatch({type: UPDATE_CONFIG_GLOBAL + FAILED, payload})
    return false
  }
}

