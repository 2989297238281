import {
  DONE,
  FAILED,
  START,
  REMOVE_TERM_DONE,
  REMOVE_TERM_FAILED,
  REMOVE_TERM_START,
  UPLOAD_FORM_DATA_FILE,
  FETCH_ORGANIZATION_CURRENT_TERMS,
  FETCH_ORGANIZATION_FUTURE_TERMS,
  ACCEPT_TERMS_START,
  ACCEPT_TERMS_DONE,
  ACCEPT_TERMS_FAILED,
  ACCEPT_FUTURE_TERMS,
  FETCH_TERMS_ACCEPTED_BY_USER,
  REVOKE_TERMS,
  REVOKE_FUTURE_TERMS,
} from "../../constants";
import { getAxiosInstance } from "../common";

export const UPDATE_MY_ORGANIZATION = "UPDATE_MY_ORGANIZATION";
export const UPLOAD_TERM_ORGANIZATION = "UPLOAD_TERM_ORGANIZATION";
export const FETCH_TERMS_ORGANIZATION = "FETCH_TERMS_ORGANIZATION";

export const updateMyOrganization = (payload) => async (dispatch) => {
  dispatch({ type: UPDATE_MY_ORGANIZATION + START });
  try {
    const result = await getAxiosInstance().put(`/api/organizations`, payload);
    dispatch({ type: UPDATE_MY_ORGANIZATION + DONE, payload: result.data });

    return result;
  } catch (error) {
    dispatch({ type: UPDATE_MY_ORGANIZATION + FAILED, error });
    return error.response;
  }
};

export const uploadOrganizationTerm = (payload) => async (dispatch) => {
  dispatch({ type: UPLOAD_TERM_ORGANIZATION + START });
  try {
    const { termFile, ...properties } = payload;

    const termData = new FormData();
    termData.append("file", termFile);
    termData.append(
      "properties",
      new Blob([JSON.stringify(properties)], {
        type: "application/json",
      })
    );
    const result = await getAxiosInstance().post(
      `/api/organizations/terms`,
      termData
    );
    dispatch({ type: UPLOAD_TERM_ORGANIZATION + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: UPLOAD_TERM_ORGANIZATION + FAILED, payload });
    return false;
  }
};

export const fetchOrganizationTerms = () => async (dispatch) => {
  dispatch({ type: FETCH_TERMS_ORGANIZATION + START });
  try {
    const result = await getAxiosInstance().get(`/api/organizations/terms`);
    dispatch({ type: FETCH_TERMS_ORGANIZATION + DONE, payload: result.data });

    return result;
  } catch (payload) {
    dispatch({ type: FETCH_TERMS_ORGANIZATION + FAILED, payload });
    return false;
  }
};

export const fetchOrganizationCurrentTerms = (orgId) => async (dispatch) => {
  dispatch({ type: FETCH_ORGANIZATION_CURRENT_TERMS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/organizations/${orgId}/current-terms`
    );
    dispatch({
      type: FETCH_ORGANIZATION_CURRENT_TERMS + DONE,
      payload: result.data,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ORGANIZATION_CURRENT_TERMS + FAILED, payload });
    return false;
  }
};

export const fetchOrganizationFutureTerms = () => async (dispatch) => {
  dispatch({ type: FETCH_ORGANIZATION_FUTURE_TERMS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/organizations/terms/future`
    );
    dispatch({
      type: FETCH_ORGANIZATION_FUTURE_TERMS + DONE,
      payload: result.data,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_ORGANIZATION_FUTURE_TERMS + FAILED, payload });
    return false;
  }
};

export const fetchTermsAcceptedByUser = () => async (dispatch) => {
  dispatch({ type: FETCH_TERMS_ACCEPTED_BY_USER + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/organizations/terms/accepted`
    );
    dispatch({
      type: FETCH_TERMS_ACCEPTED_BY_USER + DONE,
      payload: result.data,
    });

    return result;
  } catch (payload) {
    dispatch({ type: FETCH_TERMS_ACCEPTED_BY_USER + FAILED, payload });
    return false;
  }
};

export const removeTerm = (termId) => async (dispatch) => {
  dispatch(removeTermStart());
  try {
    const result = await getAxiosInstance().delete(
      `/api/organizations/terms/${termId}`
    );

    dispatch(removeTermDone(result));
    return true;
  } catch (e) {
    dispatch(removeTermFailed(e));
    throw e;
  }
};

export const removeTermStart = (payload) => ({
  type: REMOVE_TERM_START,
  payload,
});
export const removeTermDone = (payload) => ({
  type: REMOVE_TERM_DONE,
  payload: payload.data,
});
export const removeTermFailed = (payload) => ({
  type: REMOVE_TERM_FAILED,
  payload,
});

export const acceptTerms = (termsId) => async (dispatch) => {
  dispatch({ type: ACCEPT_TERMS_START });
  try {
    const result = await getAxiosInstance().put(
      `/api/organizations/terms/${termsId}/accept`
    );
    dispatch({ type: ACCEPT_TERMS_DONE, payload: result.data });

    return result.data;
  } catch (e) {
    dispatch({ type: ACCEPT_TERMS_FAILED, payload: e });
    throw e;
  }
};

export const acceptFutureTerms = (termsId) => async (dispatch) => {
  dispatch({ type: ACCEPT_FUTURE_TERMS + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/organizations/terms/${termsId}/future/accept`
    );
    dispatch({ type: ACCEPT_FUTURE_TERMS + DONE, payload: result.data });

    return result.data;
  } catch (e) {
    dispatch({ type: ACCEPT_FUTURE_TERMS + FAILED, payload: e });
    throw e;
  }
};

export const revokeTerms = (termsId) => async (dispatch) => {
  dispatch({ type: REVOKE_TERMS + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/organizations/terms/${termsId}/revoke`
    );
    dispatch({ type: REVOKE_TERMS + DONE, payload: result.data });

    return result.data;
  } catch (e) {
    dispatch({ type: REVOKE_TERMS + FAILED, payload: e });
    throw e;
  }
};

export const revokeFutureTerms = (termsId) => async (dispatch) => {
  dispatch({ type: REVOKE_FUTURE_TERMS + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/organizations/terms/${termsId}/future/revoke`
    );
    dispatch({ type: REVOKE_FUTURE_TERMS + DONE, payload: result.data });

    return result.data;
  } catch (e) {
    dispatch({ type: REVOKE_FUTURE_TERMS + FAILED, payload: e });
    throw e;
  }
};

export const uploadFormDataFile = ({ file }) => async (dispatch) => {
  dispatch({ type: UPLOAD_FORM_DATA_FILE + START });
  try {
    const formData = new FormData();
    formData.append("file", file);

    const result = await getAxiosInstance().post(
      `/api/form_data/upload_file`,
      formData
    );
    dispatch({ type: UPLOAD_FORM_DATA_FILE + DONE, payload: result.data });

    return result.data;
  } catch (e) {
    dispatch({ type: UPLOAD_FORM_DATA_FILE + FAILED, e });
    throw e;
  }
};
