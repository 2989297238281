import axios from "axios";

let axiosInstance;

export const refreshAxios = () => {
  axiosInstance = axios.create({
    // baseURL: 'https://some-domain.com/api/',
    // timeout: 1000,
    headers: {
      "x-tenantid-1": "main",
      // ...token ? {'X-Auth-Token': token} : {},
    },
  });
};

export const getAxiosInstance = () => {
  return axiosInstance;
};
