import React, { useEffect, useState } from "react";
import Can from "../../components/can";
import { useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  deactivateForm,
  fetchOldFormEditions,
  removeFormFromFormGroup,
} from "../../redux/actions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PersonIcon from "@material-ui/icons/Person";
import { NavLink } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormToGroup from "../form-to-project";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import FormPermissionsPanel from "../../components/form-permissions";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

const NO_ID_TO_REMOVE = -1;

const statusToText = (status) => {
  switch (status) {
    // case 'ZERO': return 'ZERO';
    // case 'TODO': return 'TODO';
    case "PAUSED":
      return "Wstrzymany";
    case "DRAFT":
      return "Szkic";
    case "SENT":
      return "Wysłany";
    case "ACTIVE":
      return "Aktywny";
    case "ARCHIVE":
      return "Zarchiwizowany";
    case "DELETED":
      return "Usunięty";
  }
};

const statusToTip = (status) => {
  switch (status) {
    // case 'ZERO':
    //   return 'nieuzywany w widoku';
    // case 'TODO':
    //   return 'nieuzywany w widoku';
    case "PAUSED":
      return "Aktywny formularz został zatrzymany i obecnie nie jest dostępny do wypełnienia";
    case "DRAFT":
      return "Formularz jest w trakcie tworzenia lub edycji";
    // case 'SENT':
    //   return 'nieuzywany w widoku'
    case "ACTIVE":
      return "Formularz jest dostępny do wypełnienia dla wskazanych osób";
    case " PREVIOUS_VERSION":
      return "Poprzednia wersja formularza";
    // case 'ARCHIVE':
    //   return 'nieuzywany w widoku'
    // case 'DELETED':
    //   return 'nieuzywany w widoku'
  }
};

const useStyles = makeStyles(() => ({
  nonCurrentRow: {
    backgroundColor: "#e4e4e4",
  },
}));

const SingleVersionRow = ({
  filledFormsDisplay,
  scheduledForm,
  form,
  setShowOtherVersions,
  showOtherVersions,
  setOpenPermissionsPanel,
  openPermissionsPanel,
  fill,
  isActivated,
  dispatch,
  activateForm,
  duplicateForm,
  edit,
  schedule,
  askToRemove,
  remove,
  isCurrent = false,
}) => {
  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const globalTheme = useSelector((s) => s.globalTheme);
  const [openModalPanel, setOpenModalPanel] = useState(false);
  const [openRemovingPanel, setOpenRemovingPanel] = useState(false);
  const [currentOptionValue, setCurrentOptionValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuItem = (option) => {
    if (option.key !== undefined && option.key !== "remove")
      setOpenModalPanel(true);
    else if (option.key !== undefined && option.key === "remove")
      setOpenRemovingPanel(true);
    // dispatch(removeFormFromFormGroup(form.id, form.formGroup.id));

    setCurrentOptionValue(option.key);
    setAnchorEl(null);
  };

  const doActivateForm = async (form) => {
    try {
      await activateForm(form);
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.message === "template-is-empty"
      ) {
        dispatch(
          alertAdd({
            text: "Błąd aktywacji formularza - formularz jest pusty",
            isError: true,
          })
        );
      }
    }
  };

  const options = [
    {
      key: "copy",
      value: "Skopiuj do projektu",
    },
    {
      key: "moveTo",
      value: "Przenieś do projektu",
    },
    {
      key: "remove",
      value: "Usuń z projektu",
    },
  ];

  return (
    <TableRow className={isCurrent ? "" : classes.nonCurrentRow}>
      {form ? (
        <TableCell
          style={{
            border: globalTheme === "high-contrast" && "1px solid #fff200",
          }}
          component="th"
          scope="row"
        >
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenRemovingPanel(false);
            }}
            open={openRemovingPanel}
            text={
              "Czy na pewno usunąć formularz " +
              form?.name +
              " z projektu " +
              form?.formGroup?.name +
              "?"
            }
            yesAction={() => {
              dispatch(removeFormFromFormGroup(form.id, form.formGroup.id));
            }}
            noAction={() => {}}
          />
          {form.name}
          {isCurrent && form.edition > 1 && (
            <Tooltip title="Dostępnych jest kilka wersji formularza. Kliknij aby pokazać dostępne opcje.">
              <Link
                onClick={(e) => {
                  setShowOtherVersions(!showOtherVersions);
                }}
              >
                {" "}
                (v.{form.edition})
              </Link>
            </Tooltip>
          )}
          {!isCurrent && <span> (v.{form.edition})</span>}
        </TableCell>
      ) : (
        <TableCell
          style={{
            border: globalTheme === "high-contrast" && "1px solid #fff200",
          }}
          component="th"
          scope="row"
        >
          ---
        </TableCell>
      )}

      <TableCell
        style={{
          border: globalTheme === "high-contrast" && "1px solid #fff200",
        }}
        align="right"
      >
        {form.formGroup && form.formGroup.name}
      </TableCell>

      <TableCell
        style={{
          border: globalTheme === "high-contrast" && "1px solid #fff200",
        }}
        align="right"
      >
        <Tooltip
          title={statusToTip(form.status) ? statusToTip(form.status) : ""}
        >
          <span>{statusToText(form.status)}</span>
        </Tooltip>
      </TableCell>

      <TableCell
        style={{
          border: globalTheme === "high-contrast" && "1px solid #fff200",
        }}
        align="right"
      >
        {form.numberOfUsers}
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*  <Tooltip*/}
      {/*    title={statusToTip(form.status) ? statusToTip(form.status) : ""}*/}
      {/*  >*/}
      {/*    <span>{statusToText(form.status)}</span>*/}
      {/*  </Tooltip>*/}
      {/*</TableCell>*/}
      {/*<TableCell align="right">*/}
      {/*  {form.entitledUsers !== undefined &&*/}
      {/*    (*/}
      {/*      form.entitledUsers.filter(*/}
      {/*        (entitledUser) => entitledUser.removed != true*/}
      {/*      ) || []*/}
      {/*    ).length}*/}
      {/*</TableCell>*/}
      <TableCell
        style={{
          border: globalTheme === "high-contrast" && "1px solid #fff200",
        }}
        align="right"
      >
        <NavLink
          to={`/formdata/all/${form.id}`}
          style={{
            color: globalTheme === "high-contrast" && "#fff200",
            fontWeight: globalTheme === "high-contrast" && "bold",
            fontFamily: globalTheme === "high-contrast" && "helvetica",
            textDecoration: globalTheme === "high-contrast" && "underline",
          }}
        >
          {form.filledFormCount}
        </NavLink>
      </TableCell>
      {!filledFormsDisplay && (
        <TableCell
          style={{
            border: globalTheme === "high-contrast" && "1px solid #fff200",
          }}
          align="right"
        >
          {isCurrent && (
            <>
              {scheduledForm && scheduledForm.readyToFill ? (
                <Tooltip placement="top" title="Wypełnij">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => fill(form.id, scheduledForm.id)}
                    >
                      <NoteAddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  placement="top"
                  title={
                    form.formGroup
                      ? "Wypełnij"
                      : "Przypisz formularz do projektu, aby wypełnić"
                  }
                >
                  <span>
                    <IconButton
                      size="small"
                      disabled={!form.formGroup}
                      onClick={() => fill(form.id)}
                    >
                      <NoteAddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

              <Can
                permission="form:deactivate"
                ok={() => {
                  const color = isActivated ? "green" : "red";
                  const text1 = "Formularz aktywny";
                  const text2 =
                    "Aktywuj formularz aby inne osoby mogły go zobaczyć";

                  if (isActivated) {
                    return (
                      <Tooltip placement="top" title="Wstrzymaj formularz">
                        <IconButton
                          size="small"
                          onClick={() => {
                            isActivated && dispatch(deactivateForm(form.id));
                          }}
                        >
                          <PauseIcon />
                        </IconButton>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Tooltip placement="top" title="Aktywuj formularz">
                        <IconButton
                          size="small"
                          onClick={() => {
                            !isActivated && doActivateForm(form);
                          }}
                        >
                          <PlayArrowIcon style={{ color: "green" }} />
                        </IconButton>
                      </Tooltip>
                    );
                  }
                }}
              />

              <Can
                permission="form:duplicate"
                ok={() => (
                  <Tooltip placement="top" title="Duplikuj">
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => duplicateForm(form.id)}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              />

              {edit && (
                <Can
                  permission="form:edit"
                  ok={() => (
                    <Tooltip
                      placement="top"
                      title={
                        isActivated
                          ? "Nie możesz aktualnie edytować formularz ponieważ jest aktywny. Dezaktywuj by edytować."
                          : "Edytuj formularz"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => edit(form.id)}
                          size="small"
                          disabled={isActivated}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                />
              )}

              {remove && (
                <Can
                  permission="form:remove"
                  ok={() => (
                    <Tooltip
                      placement="top"
                      title={
                        isActivated
                          ? "Nie możesz aktualnie usunąć formularzae - jest aktywny dla innych użytkowników"
                          : "Usuń formularz"
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            askToRemove(form.id);
                          }}
                          size="small"
                          disabled={isActivated}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                />
              )}

              <Tooltip
                placement="top"
                title="Zarządzanie uprawnieniami użytkowników mogących wypełnić ten formularz"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenPermissionsPanel(true);
                  }}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>

              <Can
                permission="form:add-to-project"
                ok={() => (
                  <>
                    <Tooltip
                      placement="top"
                      title="Zarządzanie przynależnością formularza do projektu"
                    >
                      <IconButton
                        size="small"
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClose}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="long-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={handleClickMenuItem}
                      PaperProps={{
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: "30ch",
                        },
                      }}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.key}
                          onClick={() => handleClickMenuItem(option)}
                          disabled={option.key === "remove" && !form.formGroup}
                        >
                          <ListItemIcon>
                            {option.key === "copy" && (
                              <FileCopyIcon fontSize="small" />
                            )}
                            {option.key === "moveTo" && (
                              <FlipToFrontIcon fontSize="small" />
                            )}
                            {option.key === "remove" && (
                              <DeleteIcon fontSize="small" />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={option.value} />
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                )}
              />
            </>
          )}
        </TableCell>
      )}
      <FormToGroup
        form={form}
        currentFormProject={form.formGroup}
        option={currentOptionValue}
        open={openModalPanel}
        setOpen={setOpenModalPanel}
      />
    </TableRow>
  );
};

const SingleForm = ({
  filledFormsDisplay = false,
  scheduledForm,
  form,
  fill,
  remove,
  edit,
  schedule,
  filledForms,
  updateForm,
  activateForm,
  history,
  duplicateForm,
}) => {
  const [openPermissionsPanel, setOpenPermissionsPanel] = useState(false);
  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);
  const [showOtherVersions, setShowOtherVersions] = useState(false);
  const [otherVersions, setOtherVersions] = useState(null);
  const dispatch = useDispatch();
  const oldVersions = useSelector((s) =>
    s.orgOldFromEditions.filter(
      (e) => form && form.bag && e.bag.id === form.bag.id && e.id !== form.id
    )
  );
  const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);

  useEffect(() => {
    idToRemove !== NO_ID_TO_REMOVE && setOpenFormRemovingPanel(true);
  }, [idToRemove]);

  useEffect(() => {
    if (showOtherVersions && otherVersions === null) {
      dispatch(fetchOldFormEditions(form.id));
    }
  }, [showOtherVersions, otherVersions, form]);

  const askToRemove = (formId) => {
    setIdToRemove(formId);
  };

  const isActivated = form ? form.status === "ACTIVE" : false;

  return (
    <>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenFormRemovingPanel(false);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        open={openFormRemovingPanel}
        text={"Czy na pewno usunąć formularz " + form.name + "?"}
        yesAction={() => {
          remove(idToRemove);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
      />

      <SingleVersionRow
        filledFormsDisplay={filledFormsDisplay}
        activateForm={activateForm}
        askToRemove={askToRemove}
        dispatch={dispatch}
        duplicateForm={duplicateForm}
        edit={edit}
        schedule={schedule}
        fill={fill}
        form={form}
        scheduledForm={scheduledForm}
        isActivated={isActivated}
        remove={remove}
        setShowOtherVersions={setShowOtherVersions}
        // setShowPermissions={setShowPermissions}
        setOpenPermissionsPanel={setOpenPermissionsPanel}
        showOtherVersions={showOtherVersions}
        openPermissionsPanel={openPermissionsPanel}
        // showPermissions={showPermissions}
        isCurrent={true}
      />

      {showOtherVersions &&
        oldVersions &&
        oldVersions.length > 0 &&
        oldVersions.map((f, index) => (
          <SingleVersionRow
            filledFormsDisplay={filledFormsDisplay}
            key={index}
            activateForm={activateForm}
            askToRemove={askToRemove}
            dispatch={dispatch}
            duplicateForm={duplicateForm}
            edit={edit}
            schedule={schedule}
            fill={fill}
            scheduledForm={scheduledForm}
            form={f}
            isActivated={isActivated}
            setShowOtherVersions={() => {}}
            setOpenPermissionsPanel={() => {}}
            showOtherVersions={false}
            openPermissionsPanel={() => {}}
          />
        ))}

      {openPermissionsPanel && (
        <FormPermissionsPanel
          form={form}
          hide={() => setOpenPermissionsPanel(false)}
          open={openPermissionsPanel}
        />
      )}
    </>
  );
};

export default SingleForm;
