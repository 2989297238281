import { checkPermission } from "../../rbac";
import { useSelector } from "react-redux";

const Can = ({ permission, permissions, ok, not }) => {
  const roles = useSelector((s) => s.roles);
  const store = useSelector((s) => s);

  const perms = permission ? [permission] : permissions;

  const result = perms.reduce(
    (result, cur) => result && checkPermission(cur, roles, store),
    true
  );
  if (result) {
    return ok ? ok() : null;
  } else {
    return not ? not() : null;
  }
};

export default Can;
