import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import { Box } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import {
  fetchAllFormsGroups,
  saveFormGroup,
  updateFormGroup,
} from "../../redux/actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Prompt from "react-router-dom/es/Prompt";
import MuiPhoneNumber from "material-ui-phone-number";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import s from "./index.module.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const AddNewFormGroupPage = ({
  orgFormsGroups,
  fetchAllFormsGroups,
  saveFormGroup,
  editFormGroup,
  updateFormGroup,
  history,
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();

  const [addMode, setAddMode] = useState();
  const [fetching, setFetching] = useState();

  const [editingFormGroup, setEditingFormGroup] = useState();

  const [name, setName] = useState("");
  const [internalDescription, setInternalDescription] = useState("");
  const [welcomeSmsMessage, setWelcomeSmsMessage] = useState("");
  const [welcomeEmailMessage, setWelcomeEmailMessage] = useState("");
  const [smsPhone, setSmsPhone] = useState("");
  const [enableAllFormAccess, setEnableAllFormAccess] = useState(true);

  const [phoneLostFocus, setPhoneLostFocus] = useState(false);

  const [valid, setValid] = useState(false);
  const [busy, setBusy] = useState(false);

  let formRef = useRef();

  const [dataUnsaved, setDataUnsaved] = useState(false);

  const formsGroups = useSelector((s) => s.orgFormsGroups);

  useEffect(() => {
    if (
      name === "" ||
      !name.replace(/\s/g, "").length
      // ||
      // (smsPhone && smsPhone.length !== 12 && smsPhone !== "+48")
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [name, smsPhone]);

  useEffect(() => {
    setAddMode(id === undefined);
  }, [id]);

  useEffect(() => {
    addMode === false && fetchAllFormsGroups();
  }, [addMode]);

  useEffect(() => {
    if (addMode !== undefined) {
      if (addMode) {
        setDataUnsaved(
          name !== "" ||
            internalDescription !== "" ||
            smsPhone !== "" ||
            welcomeSmsMessage !== "" ||
            welcomeEmailMessage !== "" ||
            !enableAllFormAccess ||
            enableAllFormAccess
        );
      }
      if (!addMode && formsGroups) {
        setEditingFormGroup(formsGroups.find((group) => group.id === id));
        setDataUnsaved(
          name !== editingFormGroup?.name ||
            internalDescription !== editingFormGroup.internalDescription ||
            smsPhone !== editingFormGroup.smsPhone ||
            welcomeSmsMessage !== editingFormGroup.welcomeSmsMessage ||
            welcomeEmailMessage !== editingFormGroup.welcomeEmailMessage ||
            enableAllFormAccess !== editingFormGroup.enableAllFormAccess
        );
      }
    }
  }, [
    name,
    internalDescription,
    welcomeSmsMessage,
    smsPhone,
    welcomeEmailMessage,
    enableAllFormAccess,
  ]);

  useEffect(() => {
    if (formsGroups) {
      const actualFormGroup = formsGroups.find((group) => group.id === id);
      if (!addMode) {
        setEditingFormGroup(actualFormGroup);
      }

      setName((actualFormGroup && actualFormGroup.name) || "");
      setInternalDescription(
        (actualFormGroup && actualFormGroup.internalDescription) || ""
      );
      setSmsPhone((actualFormGroup && actualFormGroup.smsPhone) || "");
      setWelcomeSmsMessage(
        (actualFormGroup && actualFormGroup.welcomeSmsMessage) || ""
      );
      setWelcomeEmailMessage(
        (actualFormGroup && actualFormGroup.welcomeEmailMessage) || ""
      );
      if (actualFormGroup?.enableAllFormAccess) {
        setEnableAllFormAccess(true);
      } else {
        setEnableAllFormAccess(false);
      }
      // setEnableAllFormAccess(
      //   (actualFormGroup && actualFormGroup.enableAllFormAccess) || true
      // );
    }
  }, [formsGroups, id]);

  const save = async (e) => {
    e.preventDefault();
    setBusy(true);
    const payload = {
      name,
      internalDescription,
      smsPhone: smsPhone?.replace(/[^+\d]+/g, ""),
      welcomeSmsMessage,
      welcomeEmailMessage,
      enableAllFormAccess,
    };
    if (addMode) {
      await saveFormGroup(payload);
      setDataUnsaved(false);
      setBusy(false);
      history.push(`/groups`);
    } else {
      await updateFormGroup(id, payload);
      setDataUnsaved(false);
      setBusy(false);
      history.push(`/groups`);
    }
  };

  const cancel = (e) => {
    history.push("/groups");
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      formRef.submit();
    }
  };

  const page = () => (
    <Box>
      <Prompt
        when={dataUnsaved}
        message="Edytowane dane nie zostały zapisane, czy mimo to chcesz opuścić stronę?"
      />

      <Box p={1}>
        {addMode ? (
          <PageTitle title="Dodawanie nowego projektu" />
        ) : (
          <PageTitle title={`Edycja projektu ${name}`} />
        )}
      </Box>
      <form ref={(el) => (formRef = el)} onSubmit={save}>
        <Box p={1}>
          <Grid container>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Box width="100%">
                  <Typography variant="h6" gutterBottom>
                    Pola projektu:
                  </Typography>
                  <Box mt={1} width={1 / 2}>
                    <TextField
                      autoFocus
                      required
                      label="Nazwa"
                      variant="outlined"
                      type="text"
                      value={name || ""}
                      fullWidth
                      onChange={({ target: { value } }) => setName(value)}
                    />
                  </Box>
                  <Box mt={1} width={1 / 2}>
                    <TextField
                      label="Wewnętrzny opis"
                      variant="outlined"
                      type="text"
                      multiline
                      value={internalDescription || ""}
                      fullWidth
                      rows={2}
                      rowsMax={4}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                          e.preventDefault();
                          save(e);
                        }
                      }}
                      onChange={({ target: { value } }) =>
                        setInternalDescription(value)
                      }
                    />
                  </Box>
                  <Box mt={1} width={1 / 2}>
                    <MuiPhoneNumber
                      name="phone"
                      label="Numer telefonu"
                      data-cy="user-phone"
                      defaultCountry={"pl"}
                      fullWidth
                      variant="outlined"
                      value={smsPhone || ""}
                      onChange={(e, { dialCode }) => {
                        if (e) {
                          setSmsPhone(
                            e.length > "+".concat(dialCode).length ? e : ""
                          );
                        }
                      }}
                    />
                    {/* <MuiPhoneNumber
                      name="phone"
                      label="Numer telefonu"
                      variant="outlined"
                      data-cy="user-phone"
                      defaultCountry={"pl"}
                      regions={"europe"}
                      autoFormat={false}
                      countryCodeEditable={false}
                      fullWidth
                      value={smsPhone || ""}
                      onChange={(e) => {
                        if (e !== "+48") {
                          setSmsPhone(e);
                        } else {
                          setSmsPhone("");
                        }
                        setPhoneLostFocus(false);
                      }}
                      onFocus={() => setPhoneLostFocus(false)}
                      onBlur={() => setPhoneLostFocus(true)}
                      error={
                        phoneLostFocus &&
                        smsPhone &&
                        smsPhone.length !== 12 &&
                        smsPhone !== "+48"
                      }
                      helperText={
                        phoneLostFocus &&
                        smsPhone &&
                        smsPhone.length !== 12 &&
                        smsPhone !== "+48" &&
                        "Nieprawidłowy format numeru telefonu"
                      }
                      inputProps={{
                        maxLength: 12,
                      }}
                    /> */}
                  </Box>
                  <Box mt={1} width={1 / 2}>
                    <TextField
                      label="Powitalna wiadomość SMS"
                      variant="outlined"
                      type="text"
                      multiline
                      value={welcomeSmsMessage || ""}
                      fullWidth
                      rows={2}
                      rowsMax={4}
                      // onKeyDown={(e) => {
                      //   if (e.keyCode == 13 && !e.shiftKey) {
                      //     e.preventDefault();
                      //     save(e);
                      //   }
                      // }}
                      onChange={({ target: { value } }) =>
                        setWelcomeSmsMessage(value)
                      }
                    />
                    <span className={s.hintText}>
                      {`Jedna wiadomość SMS zawiera około 140 znaków.`}
                    </span>
                    <br />
                    <span className={s.hintText}>
                      {`Ilośc znaków Twojej powitalnej wiadomości SMS: ${welcomeSmsMessage.length}`}
                    </span>
                  </Box>
                  <Box mt={1} width={1 / 2}>
                    <TextField
                      label="Powitalna wiadomość Email"
                      variant="outlined"
                      type="text"
                      multiline
                      value={welcomeEmailMessage || ""}
                      fullWidth
                      rows={2}
                      rowsMax={4}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                          e.preventDefault();
                          save(e);
                        }
                      }}
                      onChange={({ target: { value } }) =>
                        setWelcomeEmailMessage(value)
                      }
                    />
                  </Box>
                  <Box mt={1} width={1 / 2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enableAllFormAccess || false}
                          name="checkedB"
                          color="primary"
                          onChange={(e) =>
                            setEnableAllFormAccess(e.target.checked)
                          }
                        />
                      }
                      label="Automatyczne nadawanie dostępu użytkownikom do formularzy w ramach tego projektu"
                    />
                  </Box>
                </Box>
                <Box mt={1}>
                  <span className={s.formHint}>* Pole wymagane</span>
                </Box>
                <Box mt={1} display="center">
                  {addMode ? (
                    <Button
                      variant={"contained"}
                      color="primary"
                      type="submit"
                      disabled={!valid || busy}
                      // onClick={save}
                    >
                      {busy && (
                        <CircularProgress
                          size={24}
                          style={{ color: "white" }}
                        />
                      )}
                      {!busy && "Zapisz"}
                    </Button>
                  ) : (
                    <Button
                      variant={"contained"}
                      color="primary"
                      type="submit"
                      disabled={!valid}
                      // onClick={save}
                    >
                      Edytuj
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={cancel}
                  >
                    Anuluj
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );

  return (
    <Can
      permission="form-group-add-page:view"
      ok={page}
      not={() => <Redirect to="/" />}
    />
  );
};

const mapStateToProps = (state) => ({ orgFormsGroups: state.orgFormsGroups });

const mapDispatchToProps = (dispatch) => ({
  fetchAllFormsGroups: () => dispatch(fetchAllFormsGroups()),
  saveFormGroup: (payload) => dispatch(saveFormGroup(payload)),
  updateFormGroup: (id, payload) => dispatch(updateFormGroup(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewFormGroupPage);
