import React from 'react'
import * as classnames from 'classnames'

export const ConfirmDialog = ({
                                text, yesAction, noAction, busy,
                              }) => {
  return (
    <div className={classnames('modal', 'is-active')}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="panel">
          <div className="panel-heading m-b-sm">
            {text}
          </div>
          <div className="block">
            <div className="field is-grouped is-grouped-right">
              <div className="control ">
                <button className="button" disabled={busy} onClick={noAction}>Anuluj</button>
              </div>
              <div className="control">
                  <button className={classnames('button is-primary',
                      {'is-loading': busy})} onClick={yesAction}>Tak
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
