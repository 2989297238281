// noinspection ES6UnusedImports

import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAxiosInstance } from "../redux/common";
import { alertAdd, register_organization } from "../redux/actions";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Paper,
  Tabs,
  Box,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "./login";
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
  Switch,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";
import Loader from "../components/loader";
import Grid from "@material-ui/core/Grid";
import PageTitle from "../components/page-title";
import s from "./index.module.css";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Visibility } from "@material-ui/icons";
import ReCAPTCHA from "react-google-recaptcha";
import Alert from "@material-ui/lab/Alert";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

/*
 function CenteredTabs(props) {
 const classes = useStyles();

 const [value, setValue] = React.useState(props.value);

 if (value != props.value) {
 setValue(props.value);
 }

 return (
 <Paper className={classes.root}>
 <Tabs
 value={value}
 indicatorColor="primary"
 textColor="primary"
 centered
 >
 <Tab component={RouterLink} label="Użytkownicy" to="/owner-panel/tenants"  />
 <Tab component={RouterLink} label="Zamówienia" to="/owner-panel/orders"  />
 </Tabs>
 </Paper>
 );
 }
 */

function CenteredTabs() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  return (
    <Paper className={classes.root}>
      <Tabs value={value} indicatorColor="primary" textColor="primary" centered>
        {/* <Tab
          component={RouterLink}
          label="Użytkownicy"
          to="/admin-panel/tenants"
        /> */}
      </Tabs>
    </Paper>
  );
}

function Main(props) {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { userState } = props;
  const [user, setUser] = userState;
  const classes = useStyles();

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);

  const [isRegisterInProgress, setIsRegisterInProgress] = useState(false);
  const [registerCompleted, setRegisterCompleted] = useState(false);

  const [organizationCreated, setOrganizationCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setFormIsValid(
      name &&
        testEmailPattern(mail) &&
        password &&
        password2 &&
        passwordsMatch &&
        recaptchaValid &&
        passwordScore > 4
    );
  }, [
    name,
    mail,
    passwordsMatch,
    password,
    password2,
    recaptchaValid,
    passwordScore,
  ]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password2, password]);

  const testEmailPattern = (mail) => {
    let polishChars1 = String(mail)
      .toLowerCase()
      .normalize("NFD")
      .search(/[\u0300-\u036f]/g);
    let polishChars2 = String(mail)
      .toLowerCase()
      .normalize("NFD")
      .search(/\u0142/g);

    return (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(mail).toLowerCase()
      ) &&
      (polishChars2 !== 0
        ? polishChars1 + polishChars2 < 0
        : polishChars1 + polishChars2 < -1)
    );
  };

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: @ # $ % ^ & + =",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[@#$%^&+=]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: @ # $ % ^ & + ="
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  async function logout() {
    try {
      await getAxiosInstance().post("/api/auth/logout-owner", {});
      setUser(null);
    } catch (e) {
      console.error(e);
    }
  }

  const submit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      try {
        // const domain = window.location.hostname;
        const domain = "easymobile.it-learn.pl";
        const ws = new WebSocket(
          `wss://${domain}/tenants/v1/ws/registration?email=${mail.toLowerCase()}`
        );
        ws.onopen = async function (e) {
          setIsRegisterInProgress(true);
          const response = await dispatch(
            register_organization({
              name,
              mail: mail.toLowerCase(),
              password,
            })
          );

          if (response.status === 200) {
            setErrorMessage("");
            setOrganizationCreated(true);
            setName("");
            setMail("");
            setPassword("");
            setPassword2("");
            setRecaptchaValid(false);
            setPasswordScore(0);
            setPasswordMissingElements([]);
            setTypingTimer(null);

            setTimeout(() => {
              setOrganizationCreated(false);
            }, 3000);
          } else {
            const message = response.data.message;

            if (message.indexOf("Email is not unique") !== -1) {
              setErrorMessage("Email nie jest unikalny.");
            } else if (
              message.indexOf("Organization name is not unique") !== -1
            ) {
              setErrorMessage("Nazwa organizacji nie jest unikalna");
            }

            setIsRegisterInProgress(false);
          }
        };
        ws.onmessage = function (e) {
          setIsRegisterInProgress(
            e.data.includes("TenantCreatedWsPayload") && false
          );
          setRegisterCompleted(e.data.includes("TenantCreatedWsPayload"));
        };
      } catch (e) {
        let errorMessage;
        if (e.response.status === 409) {
          if (e.response.data.message.includes("Invalid email address")) {
            setErrorMessage(
              "Błąd rejestracji, niepoprawny format adresu email."
            );
          } else {
            setErrorMessage(
              "Błąd rejestracji, email jest już używany w systemie."
            );
          }
        } else {
          setErrorMessage(`Błąd rejestracji, (${e.message}).`);
        }

        dispatch(
          alertAdd({
            text: errorMessage,
            isError: true,
          })
        );
      }
    }
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Panel administracyjny
            </Typography>
            <Button color="inherit" onClick={logout}>
              Wyloguj się
            </Button>
          </Toolbar>
        </AppBar>
      </div>
      {organizationCreated && <Alert>Organizacja została założona</Alert>}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={6}>
          <Box p={1}>
            <Button size="small" variant="contained">
              Pobierz logi
            </Button>
          </Box>
          <Box p={1}>
            <PageTitle title={`Rejestracja organizacji`} />
            <form onSubmit={submit} noValidate autoComplete="off">
              <Box mt={1}>
                <TextField
                  label="Imię i nazwisko"
                  variant="outlined"
                  value={name}
                  required
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>

              <Box mt={1}>
                <TextField
                  error={!(mail === "" || testEmailPattern(mail))}
                  helperText={
                    !(mail === "" || testEmailPattern(mail)) &&
                    "Nieprawidłowy adres email"
                  }
                  label={"Email właściciela"}
                  variant="outlined"
                  required
                  value={mail}
                  fullWidth
                  onChange={(e) => setMail(e.target.value)}
                />
              </Box>
              <Box mt={1} display="flex">
                <TextField
                  label="Hasło"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  error={invalidPasswordInputChar}
                  helperText={
                    invalidPasswordInputChar ? "Spacja nie jest dozwolona." : ""
                  }
                  required
                  onChange={(e) => {
                    if (e.target.value.includes(" ")) {
                      setInvalidPasswordInputChar(true);
                      setTimeout(
                        () => setInvalidPasswordInputChar(false),
                        3000
                      );
                    } else {
                      setInvalidPasswordInputChar(false);
                    }
                    setPassword(e.target.value.split(" ").join(""));
                    testStrengthPassword(e);
                  }}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={password2 && !passwordsMatch}
                  helperText={
                    password2 && !passwordsMatch
                      ? "Powtórzone hasło nie jest zgodne"
                      : ""
                  }
                  label="Powtórz hasło"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={password2}
                  required
                  onChange={(e) =>
                    setPassword2(e.target.value.split(" ").join(""))
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={1}>
                <span className={s.formHint}>* Pola wymagane</span>
              </Box>
              <Box mt={1}>
                <span className={s.formHint}>
                  Aby spełnić zalożenie polityki Silnego Hasła prosi się o
                  podanie conajmniej jednej wielkiej litery, małej litery, cyfry
                  oraz znaku specjanlego. Hasło powinno być dłuższe niż 7
                  znaków.
                </span>
              </Box>
              {passwordScore > 0 && (
                <div>
                  <label className={s.labelStrengthPassword}>Siła hasła:</label>
                  <Box mt={1} display="flex">
                    <span
                      className={s.strengthPassword}
                      dataScore={passwordScore}
                    />
                  </Box>
                  {passwordMissingElements.length > 0 && (
                    <label
                      className={s.labelStrengthPassword}
                      style={{ marginTop: "10px" }}
                    >
                      Aby hasło było silne, należy zawrzeć:
                    </label>
                  )}
                  <span className={s.formHint}>
                    {passwordMissingElements.map((el, index) => {
                      return <li key={index}>{el}</li>;
                    })}
                  </span>
                </div>
              )}

              <Box mt={1}>
                <ReCAPTCHA
                  sitekey="6Lev2u4UAAAAAE0emXI5hh5W-VfQWagdCiqBpXRu"
                  onChange={onChange}
                />
              </Box>
              <Box mt={1} display="flex">
                <OrangeButton
                  type="submit"
                  variant={"contained"}
                  disabled={!formIsValid}
                >
                  Zarejestruj organizację
                </OrangeButton>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
      {/* <CenteredTabs /> */}
    </>
  );
}

function Protected(props) {
  const { userState } = props;
  const [user, setUser] = userState;

  async function checkLogin() {
    if (user === undefined) {
      try {
        const authResult = await getAxiosInstance().get(
          "/api/auth/check-owner"
        );
        setUser(authResult.data);
      } catch (e) {
        setUser(null);
      }
    }
  }

  useEffect(() => {
    checkLogin();
  });

  if (user === undefined) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  } else if (user === null) {
    return <Redirect to="/admin-panel/login" />;
  } else {
    return <Main userState={userState} />;
  }
}

function SuperAdminApp() {
  const userState = useState();
  const [user, setUser] = userState;

  return (
    <>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/admin-panel/login">
            <LoginForm onUserChange={setUser} />
          </Route>
          <Provider store={store}>
            <Route path="/admin-panel">
              <Protected userState={userState} />
            </Route>
          </Provider>
        </Switch>
      </Router>
    </>
  );
}

export default SuperAdminApp;
