import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { recoverPassword } from "../../redux/actions";
import { NavLink } from "react-router-dom";
import Loader from "../../components/loader";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import { Box } from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const WhiteButton = styled(Button)`
  background-color: white;
`;
const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "1px solid lightgrey",
    borderRadius: "4px",
  },
}))(TextField);

const rolesLogin = [
  {
    value: "patient",
    label:
      process.env.REACT_APP_USE_PATIENT === "true"
        ? "Jestem pacjentem"
        : "Jestem użytkownikiem",
  },
  { value: "employee", label: "Jestem pracownikiem" },
];

const PasswordResetPage = ({
  recoverPassword,
  recoveringPassword,
  history,
}) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const [userLogin, setUserLogin] = useState("");
  const [invalidUserLogin, setInvalidUserLogin] = useState(false);
  const [preventClick, setPreventClick] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [valid, setValid] = useState(false);
  const [sent, setSent] = useState(false);
  const [sentMessage, setSentMessage] = useState("");
  const input = useRef();

  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const [phoneLostFocus, setPhoneLostFocus] = useState(false);

  const [emailDisabled, setEmailDisabled] = useState(false);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  });

  useEffect(() => {
    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    const phoneValid = checkIfPhoneIsValid(userLogin);

    if (emailValid || phoneValid) {
      setPreventClick(false);
      setInvalidUserLogin(false);
    }
  }, [userLogin]);

  const checkIfPhoneIsValid = (phone) => {
    return /^(\d{9}|\+48\d{9}|48\d{9})$/.test(phone);
  };

  const validateUserLogin = (e) => {
    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    const phoneValid = checkIfPhoneIsValid(userLogin);

    if (emailValid || phoneValid) {
      setPreventClick(false);
      setInvalidUserLogin(false);
    } else {
      setInvalidUserLogin(true);
    }
  };

  const reset = async (e) => {
    e.preventDefault();

    let response = {};

    let isEmailProvided = false;
    let isPhoneProvided = false;

    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    const phoneValid = checkIfPhoneIsValid(userLogin);
    let validPhoneNumber = "";

    if (emailValid) {
      isEmailProvided = true;
    } else if (phoneValid) {
      switch (userLogin.length) {
        case 9:
          validPhoneNumber = `+48${userLogin}`;
          break;
        case 11:
          validPhoneNumber = `+${userLogin}`;
          break;
        case 12:
          validPhoneNumber = userLogin;
          break;
      }
      isPhoneProvided = true;
    }

    // await login(
    //   {
    //     ...(isEmailProvided ? { email: userLogin } : {}),
    //     ...(isPhoneProvided ? { phone: validPhoneNumber } : {}),
    //     password,
    //   },
    //   "patient"
    // );
    setSent(true);
    await recoverPassword({
      ...(isEmailProvided ? { email: userLogin } : {}),
      ...(isPhoneProvided ? { phone: validPhoneNumber } : {}),
      role: "patient",
    });
    if (isPhoneProvided) {
      setSentMessage(`Wiadomość z nowym hasłem została wysłana na
        telefon ${validPhoneNumber}`);
    } else if (isEmailProvided) {
      setSentMessage(`Wiadomość z nowym hasłem została wysłana na
        email ${userLogin}`);
    } else {
      setSentMessage(`Wiadomość z nowym hasłem nie została wysłana `);
    }
  };

  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={8}>
        <LeftComponentMui />
      </Grid>

      <Grid item sm={12} xs={12} md={4}>
        <Box p={1}>
          <Box textAlign="center">
            <LockOpen />
          </Box>

          <Box as="h1" fontSize={20} textAlign="center">
            Odzyskaj hasło
          </Box>

          <form onSubmit={reset} noValidate autoComplete="off">
            <Box mt={1}>
              <CssTextField
                required
                error={invalidUserLogin}
                helperText={
                  invalidUserLogin
                    ? "Nieprawidłowy format wprowadzonego loginu"
                    : ""
                }
                label="Adres email lub nr telefonu"
                variant="outlined"
                value={userLogin || ""}
                fullWidth
                onChange={(e) => setUserLogin(e.target.value)}
                onBlur={validateUserLogin}
              />
            </Box>

            <Box mt={1} display="flex">
              <WhiteButton
                style={{
                  marginLeft: "10px",
                  backgroundColor: globalTheme === "high-contrast" && "black",
                  color: globalTheme === "high-contrast" && "yellow",
                  border: globalTheme === "high-contrast" && "1px solid yellow",
                }}
                variant={"contained"}
                fullWidth
                md={5}
              >
                <NavLink
                  to="/login"
                  color="#000"
                  style={{
                    backgroundColor: globalTheme === "high-contrast" && "black",
                    color: globalTheme === "high-contrast" && "yellow",
                  }}
                >
                  Powrót do logowania
                </NavLink>
              </WhiteButton>

              <Button
                variant={"contained"}
                color="primary"
                onClick={reset}
                disabled={
                  !preventClick && (invalidUserLogin || userLogin === "")
                }
                fullWidth
                md={7}
              >
                Zresetuj hasło
              </Button>
            </Box>

            <Loader loading={recoveringPassword} text="Resetuję hasło..." />

            {/*<div className="field is-grouped is-grouped-right">*/}

            {/*  <div className="field is-grouped is-grouped-right">*/}
            {/*    <div className="control">*/}
            {/*      <input type="submit"*/}
            {/*             className="button is-primary is-right"*/}
            {/*             disabled={!valid}*/}
            {/*             value="Resetuj"/>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {sent && !recoveringPassword && (
              <div className="field is-grouped is-grouped-right">
                <div className="is-size-6">
                  <Alert severity="success">
                    {sentMessage}
                    {/* Wiadomość z nowym hasłem została wysłana na{" "}
                    {phone ? `telefon ${phone}` : `email ${email}`} */}
                  </Alert>
                </div>
              </div>
            )}
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  recoveringPassword: state.recoveringPassword,
});

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (args) => dispatch(recoverPassword(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetPage);
