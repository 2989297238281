import React from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { fetchUploadableFileTypes } from "../../../redux/actions";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const FileTypeTranslator = {
  Document: "Dokument",
  Image: "Obraz",
  Movie: "Film",
  Any: "Dowolny",
};

class FilePropertiesLocal extends React.Component {
  constructor() {
    super();
    this.state = {
      maxNumberOfFiles: null,
      fileType: "Any",
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.values.maxNumberOfFiles !== state.maxNumberOfFiles ||
      props.values.fileType !== state.fileType
    ) {
      return {
        maxNumberOfFiles: props.values.maxNumberOfFiles,
        fileType: props.values.fileType,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.uploadableFileTypes.length === 0) {
      this.props.fetchUploadableFileTypes();
    }
  }

  setMaxNumberOfFiles(maxNumberOfFiles) {
    this.props.formEdited(true);
    this.setState({ maxNumberOfFiles });
    this.props.setValues({
      ...this.props.values,
      maxNumberOfFiles,
    });
  }

  setFileType(fileType) {
    this.props.formEdited(true);
    this.setState({ fileType });
    this.props.setValues({
      ...this.props.values,
      fileType,
    });
  }

  render() {
    return (
      <Box>
        <Box mt={1}>
          <TextField
            error={
              this.state.maxNumberOfFiles === "" ||
              this.state.maxNumberOfFiles === null
            }
            disabled={this.props.displayMode}
            id="outlined-number"
            label="Maksymalna liczba plików:"
            type="number"
            size="small"
            fullWidth
            value={this.state.maxNumberOfFiles || ""}
            variant="outlined"
            onChange={(e) => {
              this.setMaxNumberOfFiles(
                e.target.value > 0 || e.target.value === ""
                  ? e.target.value.replace(/[^\d]/, "")
                  : "0"
              );
            }}
          />
        </Box>
        <Box mt={1}>
          <FormControl
            fullWidth
            variant="outlined"
            disabled={this.props.displayMode}
          >
            <InputLabel id="select-outlined-label">Typ pliku</InputLabel>
            <Select
              labelId="select-outlined-label"
              id="select-outlined"
              value={this.state.fileType}
              fullWidth
              onChange={({ target: { value: fileType } }) =>
                this.setFileType(fileType)
              }
              label="Typ pliku:"
            >
              {this.props.uploadableFileTypes.map((fileType) => {
                return (
                  <MenuItem key={fileType.name} value={fileType.name}>
                    {FileTypeTranslator[fileType.name] || fileType.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
  }
}

export const FileProperties = connect(
  (state) => ({
    uploadableFileTypes: state.uploadableFileTypes,
  }),
  (dispatch) => ({
    fetchUploadableFileTypes: () => dispatch(fetchUploadableFileTypes()),
  })
)(FilePropertiesLocal);
