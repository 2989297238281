import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Can from "../../components/can";
import Loader from "../../components/loader";
import {
  alertAdd,
  login,
  smallFontSize,
  mediumFontSize,
  largeFontSize,
  toggleGlobalTheme,
} from "../../redux/actions";
import Cookies from "js-cookie";
import s from "./index.module.css";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { LockOpen, Visibility } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getAxiosInstance } from "../../redux/common";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import ApiVersionSpan from "../../components/api-version-span/api-version-span";
import UnsupportedBrowserAlert from "../../components/unsupported-browser-alert";

const RegisterButton = styled(Button)`
  color: white;
  background-color: orange;
`;

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "1px solid lightgrey",
    borderRadius: "4px",
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  hintText: {
    fontSize: theme.typography.body2.hintText,
    lineHeight: "1.4",
    // margin: "-5px auto 5px",
    color:
      theme.palette.primary.main === "#fff200"
        ? theme.palette.primary.main
        : "#999",
  },
  iconHeader: {
    color: theme.palette.text.primary,
    padding: "5px",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  selectedIconHeader: {
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.text.primary,
    "&:hover": {
      border: `1px solid ${theme.palette.background.default}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
    padding: "5px",
  },
}));

const LoginPage = ({ login, history, alertAdd, loggingIn, loggedIn }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userLogin, setUserLogin] = useState("");
  const [invalidUserLogin, setInvalidUserLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [preventLogin, setPreventLogin] = useState(false);

  const [loginAttemptCounter, setLoginAttemptCounter] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [loginAttemptsLeft, setLoginAttemptsLeft] = useState(0);

  const rememberMeLogin = Cookies.get("rememberMeLogin");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const globalTheme = useSelector((s) => s.globalTheme);
  const baseFontSize = useSelector((s) => s.baseFontSize);
  const [isRegisterButtonVisible, setIsRegisterButtonVisible] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const [globalTosAccepted, setGlobalTosAccepted] = useState(false);
  const config = useSelector((s) => s.config);

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (loggedIn) {
      const firstUrl = localStorage.firstUrl;
      firstUrl?.startsWith("/formdata/fill-scheduled-test")
        ? history.push(firstUrl)
        : history.push("/");
    } else {
      setUserLogin(rememberMeLogin);
    }
  }, [loggedIn]);

  useEffect(() => {
    let valid = userLogin && password;
    let userLoginValid = true;
    if (userLogin) {
      const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(userLogin).toLowerCase()
      );

      const phoneValid = checkIfPhoneIsValid(userLogin);

      userLoginValid = emailValid || phoneValid;
      valid = valid && userLoginValid;
    }
    setPreventLogin(!valid);
    setInvalidUserLogin(!userLoginValid);
  }, [userLogin, password, globalTosAccepted]);

  useEffect(() => {
    getAxiosInstance()
      .get("/api/config")
      .then((result) => {
        setIsRegisterButtonVisible(result.data.hasAnyJoinableOrganization);
        setIsReady(true);
      });
  }, []);

  const doLogin = async (e) => {
    e.preventDefault();

    let response = {};

    let isEmailProvided = false;
    let isPhoneProvided = false;

    const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(userLogin).toLowerCase()
    );

    const phoneValid = checkIfPhoneIsValid(userLogin);
    let validPhoneNumber = "";

    if (emailValid) {
      isEmailProvided = true;
    } else if (phoneValid) {
      switch (userLogin.length) {
        case 9:
          validPhoneNumber = `+48${userLogin}`;
          break;
        case 11:
          validPhoneNumber = `+${userLogin}`;
          break;
        case 12:
          validPhoneNumber = userLogin;
          break;
      }
      isPhoneProvided = true;
    }

    response = await login(
      {
        ...(isEmailProvided ? { username: userLogin } : {}),
        ...(isPhoneProvided ? { phone: validPhoneNumber } : {}),
        password,
        ...(config.requireGlobalTosAcceptance
          ? {
              globalTosAccepted,
            }
          : {}),
      },
      "patient"
    );

    const message = response.data.message;
    const attemptsLeft =
      /^\d+$/.test(response.data.message) && response.data.message;

    if (response.status === 200) {
      // history.push('/')
    } else if (message === "not activated") {
      alertAdd({
        text: "Użytkownik nie został aktywowany",
        isError: true,
      });
    } else if (message === "global-tos-not-accepted") {
      alertAdd({
        text: "Wymagane zaakceptowanie regulaminu serwisu",
        isError: true,
      });
    } else if (message === "blocked") {
      setDisplayAlert(false);
      alertAdd({
        text: "Konto zostało czasowo zablokowane",
        isError: true,
      });
    } else if (attemptsLeft < 4 && /^\d+$/.test(attemptsLeft)) {
      setDisplayAlert(true);
      setLoginAttemptsLeft(attemptsLeft);
      // alertAdd({
      //   text: (
      //     <span>
      //       Spróbuj poprawnie wpisać hasło lub{" "}
      //       <a>
      //         <strong>Ustaw nowe hasło</strong>
      //       </a>
      //       pozostało prób przed zablokowaniem konta:{" "}
      //       <strong>{attemptsLeft}</strong>
      //     </span>
      //   ),
      //   isError: true,
      // });
    } else {
      if (invalidUserLogin) {
        alertAdd({
          text: "Nieprawidłowy format loginu",
          isError: true,
        });
      } else {
        setLoginAttemptCounter(loginAttemptCounter + 1);
        alertAdd({
          text: (
            <span>
              Spróbuj poprawnie wpisać hasło lub{" "}
              <a href="/password-reset">
                <strong>Ustaw nowe hasło</strong>
              </a>
            </span>
          ),
          isError: true,
          ...(loginAttemptCounter > 1 && { timeout: 7000 }),
        });
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/");
  };

  const checkIfPhoneIsValid = (phone) => {
    return /^(\d{9}|\+48\d{9}|48\d{9})$/.test(phone);
  };

  return (
    <Can
      permission="login:view"
      ok={() => {
        return !isReady ? (
          <Box style={{ height: "100vh" }}>
            <Loader loading={true} text="Ładowanie..." />
          </Box>
        ) : (
          <>
            <UnsupportedBrowserAlert />
            <Grid container>
              <Grid item sm={12} xs={12} md={8}>
                <LeftComponentMui />
              </Grid>

              <Grid item sm={12} xs={12} md={4}>
                <Box p={1}>
                  {displayAlert && (
                    <Box textAlign="center">
                      <Alert severity="error">
                        <span>
                          Spróbuj poprawnie wpisać hasło lub{" "}
                          <a href="/password-reset">
                            <strong>Ustaw nowe hasło</strong>
                          </a>
                        </span>
                        <br />
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          Pozostało prób przed zablokowaniem konta:{" "}
                          <strong>{loginAttemptsLeft}</strong>
                        </span>
                      </Alert>
                    </Box>
                  )}
                  <Box textAlign="center">
                    <LockOpen />
                  </Box>

                  <Box as="h1" fontSize={20} textAlign="center">
                    Zaloguj się
                  </Box>

                  <Box p={1}>
                    <form onSubmit={doLogin} noValidate autoComplete="off">
                      <Box display="flex">
                        <CssTextField
                          required
                          error={invalidUserLogin}
                          helperText={
                            invalidUserLogin
                              ? "Nieprawidłowy format wprowadzonego loginu"
                              : ""
                          }
                          label="Adres email lub nr telefonu"
                          variant="outlined"
                          value={userLogin || ""}
                          fullWidth
                          onChange={(e) => setUserLogin(e.target.value)}
                        />
                      </Box>
                      <Box mt={1}>
                        <CssTextField
                          label="Hasło"
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          fullWidth
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  aria-label="toggle password visibility"
                                >
                                  <Visibility
                                    className={`${
                                      globalTheme === "high-contrast" &&
                                      classes.icon
                                    } ${classes.iconSize}`}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {config.requireGlobalTosAcceptance ? (
                        <Box mt={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={globalTosAccepted}
                                onChange={({ target: { checked } }) =>
                                  setGlobalTosAccepted(checked)
                                }
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              <Typography variant="body1">
                                Akceptuję{" "}
                                <a
                                  href="/org-media/files/public/regulamin.pdf"
                                  target="_blank"
                                >
                                  regulamin
                                </a>{" "}
                                serwisu
                              </Typography>
                            }
                          />
                        </Box>
                      ) : null}

                      <Box
                        m={1}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <span className={classes.hintText}>
                          {"* Pole wymagane"}
                        </span>
                        {/* <Box textAlign="right" fontSize={12} m={1}> */}
                        <NavLink
                          className={`${
                            globalTheme === "high-contrast" &&
                            classes.aalertHighContrast
                          } ${classes.fontSize}`}
                          to="/password-reset"
                        >
                          Ustaw nowe hasło
                        </NavLink>
                        {/* </div></Box> */}
                      </Box>
                      <Button
                        type="submit"
                        variant={"contained"}
                        color="primary"
                        fullWidth
                        disabled={preventLogin}
                        style={{
                          backgroundColor:
                            preventLogin &&
                            globalTheme === "high-contrast" &&
                            "black",
                          color:
                            preventLogin &&
                            globalTheme === "high-contrast" &&
                            "yellow",
                          border:
                            preventLogin &&
                            globalTheme === "high-contrast" &&
                            "1px solid yellow",
                        }}
                      >
                        Zaloguj
                      </Button>
                      <Loader loading={loggingIn} text="Logowanie..." />
                      <hr />
                      {isRegisterButtonVisible && (
                        <div>
                          Nie masz konta?{" "}
                          <NavLink to="/register">
                            <RegisterButton
                              variant={"contained"}
                              size="small"
                              style={{
                                backgroundColor:
                                  globalTheme === "high-contrast"
                                    ? "yellow"
                                    : "blue",
                                color:
                                  globalTheme === "high-contrast"
                                    ? "black"
                                    : "white",
                                border:
                                  globalTheme === "high-contrast" &&
                                  "1px solid yellow",
                              }}
                            >
                              Dołącz do organizacji
                            </RegisterButton>
                          </NavLink>
                        </div>
                      )}
                    </form>
                    <a
                      href="/org-media/files/public/regulamin.pdf"
                      target="_blank"
                      className={`${
                        globalTheme === "high-contrast" &&
                        classes.aalertHighContrast
                      } ${classes.fontSize} "nav-link"`}
                    >
                      Regulamin
                    </a>
                  </Box>
                </Box>

                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                  m={2}
                >
                  <Tooltip title="Zmień rozmiar czcionki">
                    <IconButton
                      style={{ padding: "5px" }}
                      className={
                        baseFontSize === 0
                          ? classes.selectedIconHeader
                          : classes.iconHeader
                      }
                      style={{
                        // left: "12px",
                        color:
                          globalTheme === "high-contrast" ? "" : "lightgray",
                      }}
                      onClick={() => dispatch(smallFontSize())}
                    >
                      <FontDownloadIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Zmień rozmiar czcionki">
                    <IconButton
                      style={{ padding: "5px" }}
                      className={
                        baseFontSize === 1
                          ? classes.selectedIconHeader
                          : classes.iconHeader
                      }
                      style={{
                        // left: "5px",
                        color:
                          globalTheme === "high-contrast" ? "" : "lightgray",
                      }}
                      onClick={() => dispatch(mediumFontSize())}
                    >
                      <FontDownloadIcon fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Zmień rozmiar czcionki">
                    <IconButton
                      style={{ padding: "5px" }}
                      className={
                        baseFontSize === 2
                          ? classes.selectedIconHeader
                          : classes.iconHeader
                      }
                      style={{
                        // left: "5px",
                        color:
                          globalTheme === "high-contrast" ? "" : "lightgray",
                      }}
                      onClick={() => dispatch(largeFontSize())}
                    >
                      <FontDownloadIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Przełącz kontrastowy tryb strony">
                    <IconButton
                      className={`${classes.iconHeader} ${classes.fontSize}`}
                      style={{
                        marginLeft: "12px",
                        marginRight: "25px",
                        color:
                          globalTheme === "high-contrast" ? "" : "lightgray",
                      }}
                      onClick={() => dispatch(toggleGlobalTheme())}
                    >
                      <VisibilityIcon
                        className={`${
                          globalTheme === "high-contrast" && classes.icon
                        } ${classes.iconSize}`}
                      />
                    </IconButton>
                  </Tooltip>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="primary"
                    variant="contained"
                    onClick={handleInstructionClick}
                  >
                    INSTRUKCJA
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        window.open(
                          "/org-media/files/public/instrukcja_uzytkownika.pdf",
                          "_blank"
                        );
                      }}
                    >
                      Instrukcja dla{" "}
                      {process.env.REACT_APP_USE_PATIENT === "true"
                        ? "pacjenta"
                        : "użytkownika"}{" "}
                      PDF
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        window.open(
                          "/org-media/files/public/zrodlo.mp4",
                          "_blank"
                        );
                      }}
                    >
                      Film instruktażowy dla{" "}
                      {process.env.REACT_APP_USE_PATIENT === "true"
                        ? "pacjenta"
                        : "użytkownika"}
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
              <ApiVersionSpan />
            </Grid>
          </>
        );
      }}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  loggingIn: state.loggingIn,
  loggedIn: state.loggedIn,
  activeForms: state.activeForms,
  my: state.my,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data, loginType) => dispatch(login(data, loginType)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
