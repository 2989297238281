import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { alertAdd, resetByMailPassword, logout } from "../../redux/actions";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import { Box } from "@material-ui/core";
import { LockOpen, Visibility } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Can from "../../components/can";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import s from "../register-organization/index.module.css";
import Loader from "../../components/loader";
import { parse } from "qs";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "1px solid lightgrey",
    borderRadius: "4px",
  },
}))(TextField);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  hintText: {
    fontSize: theme.typography.body2.hintText,
    lineHeight: "1.4",
    // margin: "-5px auto 5px",
    color:
      theme.palette.primary.main === "#fff200"
        ? theme.palette.primary.main
        : "#999",
  },
}));

const PasswordResetMailPage = ({
  history,
  resettingByMailPassword,
  match: {
    params: { token },
  },
  location,
}) => {
  const classes = useStyles();
  const roles = useSelector((s) => s.roles);
  const globalTheme = useSelector((s) => s.globalTheme);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPassword2, setShowNewPassword2] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);

  const [encodedToken, setEncodedToken] = useState(null);

  const dispatch = useDispatch();

  const role = parse(location.search.substring(1)).role;

  useEffect(() => {
    const t = JSON.parse(atob(decodeURIComponent(token)));
    setEncodedToken(t);

    const now = new Date();

    const tokenIsValid =
      new Date(t.validUntil ? t.validUntil : `${t.expires}Z`) > now;

    if (role === "user") {
      if (!tokenIsValid) {
        history.push("/password-reset-link-expired");
      } else {
        history.push(`/password-set-new/${token}?role=user`);
      }
    } else {
      if (!tokenIsValid) {
        history.push("/password-reset-link-expired");
      } else {
        history.push(`/password-set-new/${token}`);
      }
    }
  }, [token]);

  useEffect(() => {
    setFormIsValid(
      newPassword &&
        newPassword2 &&
        newPassword === newPassword2 &&
        passwordScore > 4
    );
  }, [newPassword, newPassword2, passwordScore]);

  useEffect(() => {
    if (!(roles[0] === "GUEST")) {
      // history.push("/password-reset-link-expired");
      roles.find((role) => role === "ROLE_USER")
        ? dispatch(logout({ isUser: true }))
        : dispatch(logout({ isUser: false }));
    }
    clearTimeout(typingTimer);
  }, []);

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: @ # $ % ^ & + =",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[@#$%^&+=]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: @ # $ % ^ & + ="
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  const submit = async (e) => {
    e.preventDefault();
    const success = await dispatch(
      resetByMailPassword({
        email: encodedToken.email,
        resetId: encodedToken.resetId,
        role,
        newPassword,
      })
    );

    if (success) {
      dispatch(
        alertAdd({
          text: "Hasło zostało zmienione",
          isSuccess: true,
        })
      );
      history.push(role === "user" ? "/login" : "/login-employee");
    } else {
      dispatch(
        alertAdd({
          text: "Błąd zmiany hasła",
          isError: true,
        })
      );
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push(role === "user" ? "/login" : "/login-employee");
  };

  const page = () => {
    return (
      <Grid container>
        <Grid item sm={12} xs={12} md={8}>
          <LeftComponentMui />
        </Grid>

        <Grid item sm={12} xs={12} md={4}>
          <Box p={1}>
            <Box textAlign="center">
              <LockOpen />
            </Box>

            <Box as="h1" fontSize={20} textAlign="center">
              Resetowanie hasła
            </Box>

            <Loader
              loading={resettingByMailPassword}
              text="Resetuję hasło..."
            />

            <form onSubmit={submit} noValidate autoComplete="off">
              <Box>
                <CssTextField
                  label="Nowe hasło"
                  variant="outlined"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  fullWidth
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    testStrengthPassword(e);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility
                            className={`${
                              globalTheme === "high-contrast" && classes.icon
                            } ${classes.iconSize}`}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={2}>
                <CssTextField
                  label="Powtórz nowe hasło"
                  variant="outlined"
                  type={showNewPassword2 ? "text" : "password"}
                  value={newPassword2}
                  fullWidth
                  onChange={(e) => setNewPassword2(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword2(!showNewPassword2)}
                          aria-label="toggle password visibility"
                        >
                          <Visibility
                            className={`${
                              globalTheme === "high-contrast" && classes.icon
                            } ${classes.iconSize}`}
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={1} display="flex">
                <span
                  className={classes.hintText}
                  style={{ color: globalTheme === "high-contrast" && "yellow" }}
                >
                  Aby spełnić zalożenie polityki Silnego Hasła prosi się o
                  podanie conajmniej jednej wielkiej litery, małej litery, cyfry
                  oraz znaku specjanlego. Hasło powinno być dłuższe niż 7
                  znaków.
                </span>
              </Box>
              {passwordScore > 0 && (
                <div>
                  <label
                    className={s.labelStrengthPassword}
                    style={{
                      color: globalTheme === "high-contrast" && "yellow",
                    }}
                  >
                    Siła hasła:
                  </label>
                  <Box mt={1} display="flex">
                    <span
                      className={s.strengthPassword}
                      dataScore={passwordScore}
                    />
                  </Box>
                  {passwordMissingElements.length > 0 && (
                    <label
                      className={s.labelStrengthPassword}
                      style={{
                        color: globalTheme === "high-contrast" && "yellow",
                        marginTop: "10px",
                      }}
                    >
                      Aby hasło było silne, należy zawrzeć:
                    </label>
                  )}
                  <span
                    className={s.formHint}
                    style={{
                      color: globalTheme === "high-contrast" && "yellow",
                    }}
                  >
                    {passwordMissingElements.map((el, index) => {
                      return <li key={index}>{el}</li>;
                    })}
                  </span>
                </div>
              )}
              <Box mt={1} display="flex">
                <Button
                  variant={"contained"}
                  fullWidth
                  onClick={cancel}
                  className={classes.fontSize}
                  type="cancel"
                  style={{
                    marginLeft: "10px",
                    backgroundColor:
                      globalTheme === "high-contrast" ? "black" : "lightgrey",
                    color: globalTheme === "high-contrast" ? "yellow" : "black",
                    border:
                      globalTheme === "high-contrast" && "1px solid yellow",
                  }}
                >
                  Anuluj
                </Button>
                <OrangeButton
                  type="submit"
                  variant={"contained"}
                  fullWidth
                  disabled={!formIsValid}
                  className={classes.fontSize}
                  style={{
                    backgroundColor:
                      globalTheme === "high-contrast" ? "yellow" : "blue",
                    color: globalTheme === "high-contrast" ? "black" : "white",
                    border:
                      globalTheme === "high-contrast" && "1px solid yellow",
                  }}
                >
                  {newPassword === newPassword2
                    ? "Zmień hasło"
                    : "Hasła nie pasują do siebie"}
                </OrangeButton>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    );
  };
  return (
    <Can
      permission="password-reset-email:view"
      ok={page}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  resettingByMailPassword: state.resettingByMailPassword,
});

const mapDispatchToProps = (dispatch) => ({
  resetByMailPassword: (newPassword) =>
    dispatch(resetByMailPassword(newPassword)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetMailPage);
