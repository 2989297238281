import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "bulma/css/bulma.css";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import "./App.css";
import Header from "./components/header/header";
import _ from "lodash";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faDotCircle,
  faEye,
  faEyeSlash,
  faHeartbeat,
  faHome,
  faPen,
  faSignOutAlt,
  faSyncAlt,
  faTable,
  faTrash,
  faUserAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import {
  alertAdd,
  alertHideCustomId,
  checkApiVersion,
  checkAuth,
  checkMyIdentity,
  fetchConfig,
  fetchOrganization,
  readGlobalTheme,
  setUnsupportedBrowser,
} from "./redux/actions";
import Loader from "./components/loader";
import { noHeaderRoutes, routes } from "./routes";
import { fetchMyProfile } from "./redux/person/actions";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import Alerts from "./components/alerts";
import PrivateRoute from "./privateRoute";
import IdleTimerContainer from "./components/idle-timer";
import ModalTermsAcceptance from "./components/modal-terms-acceptance";
import InstructionsInfo from "./components/instructions-info";

import {
  fontSize1Mixin,
  fontSize2Mixin,
  fontSize3Mixin,
  highContrastTheme,
  lightTheme,
} from "./theme";
import { createMuiTheme } from "@material-ui/core";

library.add(
  faSignOutAlt,
  faUserAlt,
  faSyncAlt,
  faUserFriends,
  faPen,
  faTrash,
  faDotCircle,
  faTable,
  faHome,
  faCog,
  faHeartbeat,
  faEye,
  faEyeSlash,
  faCheck,
  faAngleUp,
  faAngleDown
);

const DISABLE_AUTOLOGOUT = process.env.REACT_APP_DISABLE_AUTOLOGOUT === "true";
const MIN_SUPPORTED_UC_BROWSER_VERSION = "12.12";

const App = ({
  checkAuth,
  loggedIn,
  fetchOrganization,
  // fetchCartItems,
  loggingIn,
  checkingAuth,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const me = useSelector((s) => s.my);
  const globalTheme = useSelector((s) => s.globalTheme);
  const baseFontSize = useSelector((s) => s.baseFontSize);
  const [theme, setTheme] = useState(createMuiTheme(lightTheme));
  const configFetched = useSelector((s) => s.configFetched);
  const apiVersionFetched = useSelector((s) => s.apiVersionFetched);
  const unsupportedBrowser = useSelector((s) => s.unsupportedBrowser);

  useEffect(() => {
    let mixin;
    switch (baseFontSize) {
      case 0:
        mixin = fontSize1Mixin;
        break;
      case 1:
        mixin = fontSize2Mixin;
        break;
      case 2:
        mixin = fontSize3Mixin;
        break;
    }

    let th;
    switch (globalTheme) {
      case "light":
        th = lightTheme;
        break;
      case "high-contrast":
        th = highContrastTheme;
        break;
    }

    const themeConf = _.merge({}, th, mixin);

    setTheme(createMuiTheme(themeConf));
  }, [baseFontSize, globalTheme]);

  useEffect(() => {
    if (loggedIn === undefined && loggingIn === undefined) {
      checkAuth();
    }
  }, [loggedIn, loggingIn]);

  useEffect(() => {
    if (loggedIn) {
      fetchOrganization();
      // fetchCartItems();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (!configFetched) {
      dispatch(fetchConfig());
    }
  }, [configFetched]);

  useEffect(() => {
    if (!apiVersionFetched) {
      dispatch(checkApiVersion());
    }
  }, [apiVersionFetched]);

  const checkIfSessionChanged = useCallback(async (event) => {
    if (event.storageArea !== localStorage) return;

    if (event.key === "loggedOut" || event.key === "loggedIn") {
      const response = await dispatch(checkMyIdentity());
      const isNowLoggedOut = response.status === 403;

      const customId = "SESSION_TYPE";

      dispatch(alertHideCustomId(customId));

      if (isNowLoggedOut) {
        dispatch(
          alertAdd({
            text:
              "Uwaga: wykryto wylogowanie z poziomu innej zakładki przeglądarki.",
            isError: true,
            persistent: true,
            customId,
          })
        );
      } else {
        const isSessionChanged = response.data.id !== me.id;

        if (isSessionChanged) {
          dispatch(
            alertAdd({
              text:
                "Uwaga: wykryto inną sesję w osobnej zakładce przeglądarki.",
              isError: true,
              persistent: true,
              customId,
            })
          );
        }
      }
    }
  });

  useEffect(() => {
    dispatch(readGlobalTheme());
  }, []);

  useEffect(() => {
    if (unsupportedBrowser === null) {
      try {
        const supportedBrowsers = require("./supportedBrowsers.js");
        var unsupported = !supportedBrowsers.test(navigator.userAgent);

        if (unsupported) {
          //regex for UC Browser is not currently supported by browserslist-useragent-regexp, so we have to check compatibility manually
          if (/ucbrowser/i.test(navigator.userAgent)) {
            const version1 = navigator.userAgent
              .match(/(ucbrowser)\/([\d\.]+)/i)[2]
              .split(".")[0];
            const version2 = navigator.userAgent
              .match(/(ucbrowser)\/([\d\.]+)/i)[2]
              .split(".")[1];
            const supportedUCVersion = MIN_SUPPORTED_UC_BROWSER_VERSION.split(
              "."
            );
            if (parseInt(version1) === parseInt(supportedUCVersion[0])) {
              unsupported =
                parseInt(version2) < parseInt(supportedUCVersion[1]);
            } else if (parseInt(version1) > parseInt(supportedUCVersion[0])) {
              unsupported = false;
            }
          }
        }
        dispatch(setUnsupportedBrowser(unsupported));
      } catch (error) {}
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      window?.localStorage &&
        window.addEventListener("storage", checkIfSessionChanged);
    }

    return () => {
      window?.localStorage &&
        window.removeEventListener("storage", checkIfSessionChanged);
    };
  }, [loggedIn]);

  useEffect(() => {
    localStorage.firstUrl = location.pathname;
  }, [location.pathname]);

  if (checkingAuth || !configFetched) {
    return <Loader loading={true} text="Uwierzytelnianie" />;
  }

  const HeaderWithContent = (props) => {
    return (
      <Header {...props}>
        {routes.map((route, i) => {
          const Wrapper = (props) => (
            <Box key={i}>
              <route.component {...props} />
            </Box>
          );

          if (route.public) {
            return (
              <Route
                key={route.path}
                path={route.path}
                component={Wrapper}
                exact={true}
              />
            );
          } else {
            return (
              <React.Fragment key={route.path}>
                <PrivateRoute
                  path={route.path}
                  component={Wrapper}
                  exact={true}
                />
              </React.Fragment>
            );
          }
        })}
      </Header>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        {loggedIn && !DISABLE_AUTOLOGOUT && <IdleTimerContainer />}
        <Router>
          <Route path="" component={HeaderWithContent} />
          <Route path="" component={Alerts} />
          <ModalTermsAcceptance />
          <InstructionsInfo />

          {/*<AppComment />*/}

          {noHeaderRoutes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                exact={true}
              />
            );
          })}
        </Router>
      </DndProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn,
  loggingIn: state.loggingIn,
  checkingAuth: state.checkingAuth,
});
const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth()),
  fetchOrganization: () => dispatch(fetchOrganization()),
  fetchMy: () => dispatch(fetchMyProfile()),
  // fetchCartItems: () => dispatch(fetchCartItems()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
