import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";

const PrivateRoute = ({ loggedIn, ...props }) => {
  const employeeLogout = useSelector((s) => s.isEmployeeLastLogout);
  return (
    <Route
      path={props.path}
      exact={props.exact}
      component={
        loggedIn
          ? props.component
          : () => (
              <Redirect to={employeeLogout ? "/login-employee" : "/login"} />
            )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.loggedIn,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
