import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemSubtypes, ItemTypes } from "../../../DnDTypes";
import "rc-time-picker/assets/index.css";
import TimeField from "react-simple-timefield";
import * as classnames from "classnames";
import s from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "solid lightgrey",
  },
}))(TextField);
const useStyles = makeStyles((theme) => ({
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  fontSizeHintText: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body2.hintText,
  },
}));

// dragaMode = either 'clone' or 'move'

const TimeControl = ({
  name,
  id,
  disabled,
  isSelected = false,
  dragMode = "move",
  configuration = {},
  markSelected,
  onChange,
  showSelfDescription,
  sidebarMode,
  formCreatorMode,
  removable,
  onRemove,
  rowIndex,
  index,
  setIsDragging,
  filledValue,
  filledFormMode,
  blankedRequiredFields,
  checkBlankedFields,
  displayMode,
}) => {
  const [, drag] = useDrag({
    item: {
      type: ItemTypes.COMPONENT,
      subtype: ItemSubtypes.TIME,
      dragMode,
      defaultLabel: "Czas",
      id,
      rowIndex,
      index,
    },
    collect: (monitor, props) => {
      setIsDragging && setIsDragging(!!monitor.isDragging());

      return {
        isDragging: !!monitor.isDragging(),
      };
    },
    canDrag: () => {
      return dragMode === "move" || dragMode === "clone";
    },
  });
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const isJustUser = useSelector((s) => s.my.user.authorities === "ROLE_USER");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (filledValue) {
      setValue(filledValue);
    }
  }, [filledValue]);

  const clicked = () =>
    markSelected &&
    markSelected({
      id,
      dataType: ItemSubtypes.TIME,
      configuration,
    });

  const onTimeChange = (event, value) => {
    const newTime = value.replace(/-/g, ":");
    const time = newTime.substr(0, 5);

    setValue(value);
    if (onChange) {
      onChange({
        id,
        value: time,
      });
    }
  };

  const updateValue = (value) => {
    setValue(value);

    const s = moment(value).format("HH:mm");

    if (onChange) {
      onChange({
        id,
        value: s,
      });
    }
  };

  if (sidebarMode) {
    return (
      <div ref={drag}>
        <input
          readOnly={true}
          type="text"
          className="input"
          placeholder="Czas"
        />
      </div>
    );
  }

  const placeholder = showSelfDescription
    ? "Czas"
    : (configuration && configuration.text) || "";
  const label = (configuration && configuration.label) || "";

  if (formCreatorMode) {
    return (
      <div ref={drag}>
        {label && <label className={classnames("label")}>{label}</label>}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}
        <div ref={drag} className={s.relative}>
          <input
            placeholder={placeholder}
            className={classnames("input", {
              [s.hasActiveConfiguration]: isSelected,
            })}
            name={name}
            id={id}
            onClick={clicked}
            readOnly
          />
          {!displayMode && (
            <FontAwesomeIcon
              className={classnames(s.trashIcon, "has-text-grey")}
              onClick={onRemove}
              icon="trash"
              title="Usuń"
            />
          )}
        </div>
      </div>
    );
  }
  if (filledFormMode) {
    return (
      <div>
        {label && (
          <label
            className={`label ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            {label}
          </label>
        )}
        {!label && (
          <label
            dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
            className={classnames("label")}
          ></label>
        )}

        {!isJustUser && !filledValue ? (
          <div
            className={`${s.hintText} ${classes.fontSize}`}
            style={{ color: globalTheme === "high-contrast" && "yellow" }}
          >
            Pole niewypełnione przez{" "}
            {process.env.REACT_APP_USE_PATIENT === "true"
              ? "pacjenta"
              : "użytkownika"}
          </div>
        ) : (
          // <input
          //   style={{ color: disabled ? "black" : "" }}
          //   disabled={disabled}
          //   type="text"
          //   className={classnames("input")}
          //   name={name}
          //   id={id}
          //   value={value}
          // />
          <CssTextField
            // style={{ color: disabled ? "black" : "" }}
            fullWidth
            variant={"outlined"}
            size="small"
            disabled={disabled}
            type="text"
            name={name}
            id={id}
            value={value}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label
          className={`label ${classes.fontSize}`}
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
        >
          {label}
          {configuration.required && " *"}
        </label>
      )}
      {!label && (
        <label
          style={{ color: globalTheme === "high-contrast" && "yellow" }}
          dangerouslySetInnerHTML={{ __html: "&nbsp;" }}
          className={classnames("label")}
        ></label>
      )}

      <>
        <TimeField
          style={{
            // border: "2px solid #666",
            width: 100,
            padding: "5px 8px",
            // color: "#333",
            borderRadius: 3,
            color: disabled
              ? "black"
              : globalTheme === "high-contrast"
              ? "yellow"
              : "",
            border: globalTheme === "high-contrast" && "1px solid yellow",
            backgroundColor: globalTheme === "high-contrast" && "black",
          }}
          onChange={onTimeChange}
          placeholder={placeholder}
          disabled={disabled}
          className={`${
            checkBlankedFields &&
            blankedRequiredFields.find((fieldId) => fieldId === id)
              ? "input is-danger"
              : "input"
          } ${classes.fontSize}`}
          name={name}
          id={id}
          onClick={clicked}
          value={value || ""}
        />
        {checkBlankedFields &&
          blankedRequiredFields.find((fieldId) => fieldId === id) && (
            <p
              class={globalTheme !== "high-contrast" && "help is-danger"}
              style={{
                color: globalTheme === "high-contrast" && "#df9700",
              }}
              className={`${classes.fontSize} ${
                globalTheme !== "high-contrast" && "help is-danger"
              }`}
            >
              To pole musi zostać wypełnione
            </p>
          )}
      </>
    </div>
  );
};

export default TimeControl;
