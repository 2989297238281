import React from "react";
import { ItemSubtypes } from "../../../DnDTypes";
import { ColorPicker } from "../../color-picker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import s from "./index.module.css";

export const SupportedType = ItemSubtypes.DICTIONARY;

export const STRING_TYPE = "string";
export const NUMBER_TYPE = "number";

/**
 * values format:
 * {
 *   type: "string|number"
 *   dictionaryValues: ["opt1", "opt2"...] or [1,2,3...]
 * }
 * @param values
 * @param setValues
 * @returns {*}
 * @constructor
 */

export class DictionaryProperties extends React.Component {
  constructor() {
    super();

    this.state = {
      type: STRING_TYPE,
      allowMultipleAnswers: false,
      allowAlternativeAnswer: false,
      alternativeAnswerLabel: "",
      saveDictionaryGlobally: false,
      dictionaryValues: [],
      weight: "",
      color: "",
      value: "",
      text: "",
      addFirstDictValueDisplay: true,
      addNewDictValueFlag: false,
      showColorPicker: false,
      editValueNumber: null,
      tmpDictValueString: "",
      tmpDictValueInt: null,
      tmpDictValueColor: "",
      tmpDictValueWeight: null,
      tmpDictValueText: "",
    };

    this.addValue = this.addValue.bind(this);
    this.removeValue = this.removeValue.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (props.values.dictionary || {}).dictionaryValues !==
        state.dictionaryValues ||
      props.values.allowMultipleAnswers !== state.allowMultipleAnswers ||
      props.values.allowAlternativeAnswer !== state.allowAlternativeAnswer ||
      props.values.alternativeAnswerLabel !== state.alternativeAnswerLabel
      //  ||
      // props.values.addFirstDictValueDisplay !== state.addFirstDictValueDisplay
    ) {
      return {
        dictionaryValues:
          (props.values.dictionary || {}).dictionaryValues || [],
        allowMultipleAnswers: props.values.allowMultipleAnswers,
        allowAlternativeAnswer: props.values.allowAlternativeAnswer,
        alternativeAnswerLabel: props.values.alternativeAnswerLabel,
        saveDictionaryGlobally: props.values.saveDictionaryGlobally,
        // addFirstDictValueDisplay: props.values.addFirstDictValueDisplay,
        type: STRING_TYPE,
      };
    }
    return null;
  }

  setType(type) {
    this.props.formEdited(true);
    this.setState({ type });
    this.props.setValues({
      ...this.props.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: type,
      },
    });
  }

  setAllowMultipleAnswers(allowMultipleAnswers) {
    this.props.formEdited(true);
    if (allowMultipleAnswers) {
      this.setState({
        allowMultipleAnswers,
        allowAlternativeAnswer: false,
      });
      this.props.setValues({
        ...this.props.values,
        allowMultipleAnswers: allowMultipleAnswers,
        allowAlternativeAnswer: false,
      });
    } else {
      this.setState({
        allowMultipleAnswers,
      });
      this.props.setValues({
        ...this.props.values,
        allowMultipleAnswers: allowMultipleAnswers,
      });
    }
  }

  setAllowAlternativeAnswer(allowAlternativeAnswer) {
    this.props.formEdited(true);
    this.setState({ allowAlternativeAnswer, alternativeAnswerLabel: "" });
    this.props.setValues({
      ...this.props.values,
      allowAlternativeAnswer: allowAlternativeAnswer,
      alternativeAnswerLabel: "",
    });
  }

  setAlternativeAnswerLabel(alternativeAnswerLabel) {
    this.props.formEdited(true);
    this.setState({ alternativeAnswerLabel });
    this.props.setValues({
      ...this.props.values,
      alternativeAnswerLabel: alternativeAnswerLabel,
    });
  }

  setAddFirstDictValueDisplay(addFirstDictValueDisplay) {
    this.props.formEdited(true);
    this.setState({ addFirstDictValueDisplay });
    this.props.setValues({
      ...this.props.values,
      addFirstDictValueDisplay: addFirstDictValueDisplay,
    });
  }

  setSaveDictionaryGlobally(saveDictionaryGlobally) {
    this.props.formEdited(true);
    this.setState({ saveDictionaryGlobally });
    this.props.setValues({
      ...this.props.values,
      saveDictionaryGlobally: saveDictionaryGlobally,
    });
  }

  addValue() {
    this.props.formEdited(true);
    this.setState({ addNewDictValueFlag: false });
    let weight;
    try {
      weight = this.state.weight;
    } catch {}

    let intValue;
    try {
      if (this.state.type === NUMBER_TYPE) {
        intValue = parseInt(this.state.value, 10);
      }
    } catch {}

    let stringValue;
    try {
      if (this.state.type === STRING_TYPE) {
        stringValue = this.state.value.trim();
      }
    } catch {}

    let text;
    try {
      if (this.state.text && this.state.text.trim()) {
        text = this.state.text.trim();
      }
    } catch {}

    const pack = {
      intValue,
      stringValue,
      ...(this.state.color.length > 0 ? { color: this.state.color } : {}),
      weight,
      text,
    };

    this.props.setValues({
      ...this.props.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: [...(this.state.dictionaryValues || []), pack],
      },
    });
  }

  saveEditedDictValue = (index) => {
    this.props.formEdited(true);
    if (this.state.type === STRING_TYPE) {
      this.state.dictionaryValues[
        index
      ].stringValue = this.state.tmpDictValueString;
    } else if (this.state.type === NUMBER_TYPE) {
      this.state.dictionaryValues[index].intValue = this.state.tmpDictValueInt;
    }
    this.state.dictionaryValues[index].weight = this.state.tmpDictValueWeight;
    this.state.dictionaryValues[index].color = this.state.tmpDictValueColor;
    this.state.dictionaryValues[index].text = this.state.tmpDictValueText;

    this.setState({ editValueNumber: false });

    this.props.setValues({
      ...this.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: this.state.dictionaryValues,
      },
    });
  };

  removeValue(v) {
    this.props.formEdited(true);
    this.state.dictionaryValues.length === 1 &&
      this.setState({ addFirstDictValueDisplay: true });
    this.setState({ addNewDictValueFlag: false });
    this.props.setValues({
      ...this.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: this.state.dictionaryValues.filter(
          ({ intValue, stringValue }) => {
            return this.state.type === STRING_TYPE
              ? stringValue !== v
              : intValue !== v;
          }
        ),
      },
    });
  }

  setTmpDictValue(v, color, weight, text) {
    this.state.type === STRING_TYPE
      ? this.setState({ tmpDictValueString: v })
      : this.setState({ tmpDictValueInt: v });
    this.setState({
      tmpDictValueColor: color,
      tmpDictValueWeight: weight,
      tmpDictValueText: text,
    });
  }

  moveDictValue(old_index, new_index) {
    this.props.formEdited(true);
    let arr = this.state.dictionaryValues;
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    this.props.setValues({
      ...this.values,
      dictionary: {
        ...this.props.values.dictionary,
        type: this.state.type,
        dictionaryValues: arr,
      },
    });
  }

  render() {
    return (
      <div>
        {this.props.addMode && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.saveDictionaryGlobally || false}
                onChange={({ target: { checked: enable } }) =>
                  this.setSaveDictionaryGlobally(enable)
                }
                name="checkedC"
                color="primary"
              />
            }
            label="Globalny zapis słownika"
            disabled={this.props.displayMode}
          />
        )}
        {
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.allowMultipleAnswers || false}
                onChange={({ target: { checked: enable } }) =>
                  this.setAllowMultipleAnswers(enable)
                }
                name="checkedB"
                color="primary"
              />
            }
            disabled={this.props.displayMode}
            label="Możliwość wielokrotnych odpowiedzi"
          />
        }
        {!this.state.allowMultipleAnswers && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.allowAlternativeAnswer || false}
                onChange={
                  ({ target: { checked: enable } }) =>
                    this.setAllowAlternativeAnswer(enable)
                  // this.setAlternativeAnswerLabel("");
                }
                name="checkedC"
                color="primary"
              />
            }
            disabled={this.props.displayMode}
            label="Możliwość alternatywnej odpowiedzi"
          />
        )}
        {!this.state.type ? (
          <>
            <div className="is-size-6">Zdefiniuj typ słownika:</div>
            {/* <div>
              <button
                className="button"
                onClick={() => this.setType(STRING_TYPE)}
              >
                Tekst
              </button>
              <button
                className="button"
                onClick={() => this.setType(NUMBER_TYPE)}
              >
                Liczba
              </button>
            </div> */}
          </>
        ) : (
          <div>
            {this.state.dictionaryValues.length === 0 ||
            this.state.addNewDictValueFlag ? (
              this.state.addFirstDictValueDisplay ? (
                !this.props.displayMode && (
                  <div>
                    <span className={s.text}>
                      Brak wartości.
                      <a
                        className={s.clickText}
                        style={{ color: "#3273DC" }}
                        onClick={() => {
                          this.setAddFirstDictValueDisplay(false);
                          this.setState({
                            addFirstDictValueDisplay: false,
                            weight: "",
                            color: "",
                            value: "",
                            text: "",
                          });
                        }}
                      >
                        {" "}
                        Dodaj pierwszą wartość.
                      </a>
                    </span>
                  </div>
                )
              ) : (
                <div>
                  <span>Wartości:</span>
                  <div style={{ backgroundColor: "#F7F7F7" }}>
                    <label className={s.editValueLabel}>Nowa wartość</label>
                    <br></br>
                    <table>
                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Wartość:</label>
                        </td>
                        <td>
                          <input
                            className="input is-small"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            placeholder="Dodaj wartość"
                            type={
                              this.state.type === STRING_TYPE
                                ? "text"
                                : "number"
                            }
                            value={this.state.value}
                            onChange={({ target: { value } }) =>
                              this.setState({ value })
                            }
                          />
                        </td>
                      </tr>
                      <br></br>

                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Kolor:</label>
                        </td>
                        <td>
                          <div
                            className={s.editColorArea}
                            style={{
                              cursor: "pointer",
                              background: this.state.color
                                ? this.state.color
                                : "white",
                            }}
                            onClick={() => {
                              this.setState({
                                showColorPicker: !this.state.showColorPicker,
                              });
                            }}
                          ></div>
                        </td>
                      </tr>

                      <br></br>
                      <tr>
                        <td>
                          <label style={{ marginLeft: 10 }}>Waga:</label>
                        </td>
                        <td>
                          <input
                            className={"input is-small"}
                            type="number"
                            placeholder="Brak"
                            min="0"
                            value={this.state.weight}
                            onChange={({ target: { value: weight } }) =>
                              this.setState({ weight })
                            }
                            onWheel={(event) => event.target.blur()}
                          />
                        </td>
                      </tr>
                    </table>
                    {this.state.showColorPicker && (
                      <div>
                        <ColorPicker
                          required={false}
                          value={this.state.color}
                          onChange={(color) => {
                            this.setState({ color });
                          }}
                        />
                      </div>
                    )}
                    {this.state.showColorPicker && (
                      <a
                        onClick={() => {
                          this.setState({
                            showColorPicker: false,
                          });
                        }}
                      >
                        Wybierz
                      </a>
                    )}
                    <br></br>
                    <label style={{ marginLeft: 10 }}>Komunikat:</label>
                    <textarea
                      className={"textarea is-small"}
                      placeholder="Brak"
                      type="textarea"
                      rows={3}
                      value={this.state.text}
                      onChange={({ target: { value: text } }) =>
                        this.setState({ text })
                      }
                    />

                    <button
                      className="button is-primary is-small"
                      style={{ float: "right", marginLeft: 5, marginTop: 10 }}
                      onClick={this.addValue}
                    >
                      Dodaj
                    </button>

                    <button
                      className="button is-small"
                      style={{ float: "right", marginTop: 10 }}
                      onClick={() => {
                        this.state.dictionaryValues.length === 0 &&
                          this.setAddFirstDictValueDisplay(true);
                        this.setState({ addNewDictValueFlag: false });
                      }}
                    >
                      Anuluj
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="control">
                <br></br>
                <div>Wartości: ({this.state.dictionaryValues.length})</div>
                {this.state.dictionaryValues.map(
                  ({ stringValue, intValue, weight, color, text }, index) =>
                    this.state.editValueNumber === index ? (
                      <div key={index}>
                        <div style={{ backgroundColor: "#F7F7F7" }}>
                          <label className={s.editValueLabel}>
                            Edycja wartości
                          </label>
                          <br />
                          <table>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>
                                  Wartość:
                                </label>
                              </td>
                              <td>
                                <input
                                  className="input is-small"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                  placeholder="Dodaj wartość"
                                  type={
                                    this.state.type === STRING_TYPE
                                      ? "text"
                                      : "number"
                                  }
                                  value={
                                    this.state.type === STRING_TYPE
                                      ? this.state.tmpDictValueString
                                      : this.state.tmpDictValueInt
                                  }
                                  onChange={({ target: { value } }) =>
                                    this.setState(
                                      this.state.type === STRING_TYPE
                                        ? { tmpDictValueString: value }
                                        : { tmpDictValueInt: value }
                                    )
                                  }
                                />
                              </td>
                            </tr>
                            <br></br>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>Kolor:</label>
                              </td>
                              <td>
                                <div
                                  className={s.editColorArea}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: this.state
                                      .tmpDictValueColor
                                      ? this.state.tmpDictValueColor
                                      : "white",
                                  }}
                                  onClick={() => {
                                    this.setState({
                                      showColorPicker: !this.state
                                        .showColorPicker,
                                    });
                                  }}
                                ></div>
                              </td>
                            </tr>
                            <br></br>
                            <tr>
                              <td>
                                <label style={{ marginLeft: 10 }}>Waga:</label>
                              </td>
                              <td>
                                <input
                                  className={"input is-small"}
                                  type="number"
                                  placeholder="Brak"
                                  value={this.state.tmpDictValueWeight}
                                  onChange={({ target: { value } }) => {
                                    this.setState({
                                      tmpDictValueWeight: value,
                                    });
                                  }}
                                  onWheel={(event) => event.target.blur()}
                                />
                              </td>
                            </tr>
                          </table>

                          {this.state.showColorPicker && (
                            <div>
                              <ColorPicker
                                required={false}
                                value={this.state.tmpDictValueColor}
                                onChange={(color) => {
                                  this.setState({
                                    tmpDictValueColor: color,
                                    showColorPicker: false,
                                  });
                                }}
                              />
                            </div>
                          )}
                          <br />
                          <label style={{ marginLeft: 10 }}>Komunikat:</label>
                          <textarea
                            className={"textarea is-small"}
                            placeholder="Brak"
                            type="textarea"
                            rows={3}
                            value={this.state.tmpDictValueText}
                            onChange={({ target: { value: text } }) =>
                              this.setState({ tmpDictValueText: text })
                            }
                          />
                          <button
                            className="button is-primary is-small"
                            style={{
                              float: "right",
                              marginLeft: 5,
                              marginTop: 10,
                            }}
                            onClick={() => {
                              this.saveEditedDictValue(index);
                            }}
                          >
                            Zapisz
                          </button>

                          <button
                            className="button is-small"
                            style={{ float: "right", marginTop: 10 }}
                            onClick={() => {
                              this.setState({ editValueNumber: false });
                            }}
                          >
                            Anuluj
                          </button>
                          <br></br>
                          <br></br>
                        </div>
                        <hr style={{ marginTop: 5 }} />
                      </div>
                    ) : (
                      <div key={index}>
                        <li
                          key={index}
                          style={{ color: "#000000", fontWeight: "bold" }}
                        >
                          {`Odpowiedź: ${
                            this.state.type === STRING_TYPE
                              ? stringValue
                              : intValue
                          }`}
                          <div
                            style={{
                              color: "#4A4A4A",
                              marginLeft: 30,
                              fontWeight: "normal",
                            }}
                          >
                            {text}
                          </div>
                        </li>
                        <div
                          style={{
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <label>Kolor: </label>
                          <label
                            className={color && s.colorAreaSmall}
                            style={{ background: color && color }}
                          >
                            {!color && (
                              <label style={{ marginLeft: 5 }}>Brak</label>
                            )}
                          </label>
                        </div>
                        <div
                          style={{
                            fontSize: 15,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          Waga: {weight ? weight : "Brak"}
                        </div>
                        {!this.props.displayMode && (
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {index === 0 &&
                              this.state.dictionaryValues.length !== 1 && (
                                <a
                                  className={s.buttonDictValue}
                                  onClick={() =>
                                    this.moveDictValue(index, index + 1)
                                  }
                                >
                                  {" "}
                                  Przesuń niżej
                                </a>
                              )}

                            {this.state.dictionaryValues.length === index + 1 &&
                              this.state.dictionaryValues.length !== 1 && (
                                <a
                                  className={s.buttonDictValue}
                                  onClick={() =>
                                    this.moveDictValue(index, index - 1)
                                  }
                                >
                                  {" "}
                                  Przesuń wyżej
                                </a>
                              )}

                            {index !== 0 &&
                              this.state.dictionaryValues.length !==
                                index + 1 &&
                              this.state.dictionaryValues.length !== 1 && (
                                <div
                                  style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <a
                                    className={s.buttonDictValue}
                                    onClick={() =>
                                      this.moveDictValue(index, index - 1)
                                    }
                                  >
                                    {" "}
                                    Przesuń wyżej
                                  </a>
                                  <a
                                    className={s.buttonDictValue}
                                    onClick={() =>
                                      this.moveDictValue(index, index + 1)
                                    }
                                  >
                                    {" "}
                                    Przesuń nizej
                                  </a>
                                </div>
                              )}

                            <a
                              className={s.buttonDictValue}
                              onClick={() => {
                                this.setState({ editValueNumber: index });
                                this.setTmpDictValue(
                                  this.state.type === STRING_TYPE
                                    ? stringValue
                                    : intValue,
                                  color,
                                  weight,
                                  text
                                );
                              }}
                            >
                              {" "}
                              Zmień
                            </a>
                            <a
                              className={s.buttonDictValue}
                              onClick={() => {
                                this.removeValue(
                                  this.state.type === STRING_TYPE
                                    ? stringValue
                                    : intValue
                                );
                              }}
                            >
                              {" "}
                              Usuń
                            </a>
                          </div>
                        )}
                        <hr style={{ marginTop: 3, marginDown: 3 }} />
                      </div>
                    )
                )}
                {this.state.allowAlternativeAnswer && (
                  <div>
                    <li
                      style={{ color: "#000000", fontWeight: "bold" }}
                    >{`Odpowiedź alternatywna`}</li>
                    <div
                      style={{
                        fontSize: 15,
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <label>Etykieta: </label>
                      <input
                        disabled={this.props.displayMode}
                        className="input is-small"
                        placeholder="Przykład"
                        type={"text"}
                        value={this.state.alternativeAnswerLabel}
                        onChange={({ target: { value } }) =>
                          this.setAlternativeAnswerLabel(value)
                        }
                      />
                    </div>
                    <hr />
                  </div>
                )}
                {!this.props.displayMode && (
                  <a
                    style={{
                      color: "#3273DC",
                      fontSize: 10,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    onClick={() => {
                      this.setState({
                        addFirstDictValueDisplay: false,
                        addNewDictValueFlag: true,
                        weight: "",
                        color: "",
                        value: "",
                        text: "",
                      });
                    }}
                  >
                    {" "}
                    Dodaj nową wartość
                  </a>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
