import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { version } from "../../../package.json";

const useStyles = makeStyles((theme) => ({
  appVersionSpan: {
    position: "fixed",
    bottom: "0px",
    right: theme.spacing(1),
    fontSize: `calc(${theme.typography.body2.fontSize}/5*4)`,
  },
}));

const ApiVersionSpan = ({ apiVersion }) => {
  const classes = useStyles();

  return (
    <span className={classes.appVersionSpan}>
      Wersja API: {apiVersion}, wersja aplikacji: {version}
    </span>
  );
};

const mapStateToProps = (state) => ({
  apiVersion: state.apiVersion,
});

export default connect(mapStateToProps, null)(ApiVersionSpan);
