import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  activateUser,
  alertAdd,
  editUser,
  fetchOrgUsersWithTerms,
  fetchOrgUsersPaged,
  removeUser,
  fetchMatchingUsers,
  fetchOrgUsers,
  fetchUserAllAvailableForms,
} from "../redux/actions";
import { fetchOrganizationCurrentTerms } from "../redux/organization/actions";
import { Prompt, useHistory } from "react-router-dom";
import { checkPermission } from "../rbac";
import PageTitle from "../components/page-title";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TablePagination from "@material-ui/core/TablePagination";
import { normalizeZIP } from "../components/address-form/shipping-info";
import { PersonForm } from "../components/person-form";
import { ConfirmDialogMUI } from "../components/confirm-dialog-mui";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "../components/loader";
import IconButton from "@material-ui/core/IconButton";

const stateToProps = (state) => ({
  persons: state.orgUsers,
  personsLength: state.orgUsersLength,
  roles: state.roles,
  userAllAvailableForms: state.userAllAvailableForms,
  removedUser: state.removedUser,
});

const dispatchToProps = (dispatch) => ({
  fetchOrgUsersWithTerms: (payload) =>
    dispatch(fetchOrgUsersWithTerms(payload)),
  fetchOrgUsersPaged: (payload) => dispatch(fetchOrgUsersPaged(payload)),
  removeUser: (id, canSeeTerms) => dispatch(removeUser(id, canSeeTerms)),
  editUser: (user) => dispatch(editUser(user)),
  activateUser: (id) => dispatch(activateUser(id)),
  fetchUserAllAvailableForms: (id) => dispatch(fetchUserAllAvailableForms(id)),
});

const roleToDisplay = (authorities) => {
  return authorities
    .split(",")
    .map((r) => {
      switch (r) {
        case "ROLE_OWNER":
          return "właściciel organizacji";
        case "ROLE_EDITOR":
          return process.env.REACT_APP_USE_PATIENT === "true"
            ? "lekarz"
            : "redaktor";
        case "ROLE_USER":
          return process.env.REACT_APP_USE_PATIENT === "true"
            ? "pacjent"
            : "użytkownik";
        default:
          return "";
      }
    })
    .filter((name) => !!name)
    .join(", ");
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const Person = ({
  key,
  data,
  removeFunction,
  setOpenFormRemovingPanel,
  setOpenFormActivationPanel,
  setOpenFormEditionPanel,
  editFunction,
  activateFunction,
  index,
  profileDataChangeHandler,
  addressDataChangeHandler,
  handleChecbox,
}) => {
  const history = useHistory();

  const isUser = data.user.authorities.localeCompare("ROLE_USER") === 0;
  const my = useSelector((s) => s.my);
  const canSeeTerms = !!my?.user.authorities.includes("ROLE_OWNER");
  const orgCurrentTerms = useSelector((s) => s.orgCurrentTerms);

  const [valid, setValid] = useState(
    data.user.email || data.user.phone ? true : false
  );

  const [openConfirmPanel, setOpenConfirmPanel] = useState(false);

  const [userToEdit, setUserToEdit] = useState(data);
  const [editedPerson, setEditedPerson] = useState({});

  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  const [isMailInvalid, setIsMailInvalid] = useState(false);

  const [expanededPersonPanel, setExpanededPersonPanel] = useState([]);

  const [checkIfExist, setCheckIfExist] = useState(false);
  const [checked, setChecked] = useState(true);
  const [
    isHospitalIdentifierInvalid,
    setIsHospitalIdentifierInvalid,
  ] = useState(false);

  const [dataToCheck, setDataToCheck] = useState({});

  const dispatch = useDispatch();

  const dataIsUsed = async (value, fieldProperty) => {
    if (userToEdit.role !== "") {
      if (fieldProperty === "mail") {
        setDataToCheck({
          ...dataToCheck,
          ...(value || value === "" ? { email: value } : {}),
        });
      } else {
        setDataToCheck({
          ...dataToCheck,
          ...(value || value === "" ? { [fieldProperty]: value } : {}),
        });
      }
    }
  };

  useEffect(() => {
    async function fetchMatchingUser() {
      if (
        dataToCheck &&
        !(Object.keys(dataToCheck).length === 0) &&
        checkIfExist
      ) {
        const response = await dispatch(
          fetchMatchingUsers({
            ...(userToEdit.user.phone !== dataToCheck.phone
              ? {
                  phone: dataToCheck.phone,
                }
              : {}),
            ...(userToEdit.user.email !== dataToCheck.email
              ? {
                  email: dataToCheck.email,
                }
              : {}),
            ...(userToEdit.hospitalIdentifier !== dataToCheck.hospitalIdentifier
              ? {
                  hospitalIdentifier: dataToCheck.hospitalIdentifier,
                }
              : {}),
          })
        );
        if (response.status === 200 && response.data.length !== 0) {
          setOpenConfirmPanel(true);
          setEditedPerson(response.data[0]);
          setValid(false);
          setChecked(true);
        } else if (response.status === 409) {
          setIsMailInvalid(response.data.message.includes("email-exists"));
          setIsHospitalIdentifierInvalid(
            response.data.message.includes("hospital-identifier")
          );
          setIsPhoneInvalid(response.data.message.includes("phone-exists"));
          setChecked(true);
        } else if (response.status === 200 && response.data.length === 0) {
          setIsMailInvalid(false);
          setIsPhoneInvalid(false);
          setIsHospitalIdentifierInvalid(false);
          setChecked(true);
        }
      }
      if (dataToCheck?.phone === "+48") {
        setIsPhoneInvalid(false);
      }
    }
    fetchMatchingUser();
  }, [dataToCheck]);

  const prevAmount = usePrevious(data);
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (data && prevAmount) {
      if (
        prevAmount?.user.email !== data?.user.email ||
        prevAmount?.hospitalIdentifier !== data?.hospitalIdentifier ||
        prevAmount?.user.phone !== data?.user.phone
      ) {
        setChecked(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      userToEdit?.user.email !== data?.user.email ||
      userToEdit?.hospitalIdentifier !== data?.hospitalIdentifier ||
      userToEdit?.user.phone !== data?.user.phone
    ) {
      setCheckIfExist(true);
    } else {
      setCheckIfExist(false);
    }
    if (!(data.address?.zipCode?.length > 0)) {
      setValid(
        (
          data.user.phone
            ? data.user.phone.length > 3 || data.user.phone.length < 4
            : data.user.email
        )
          ? true
          : false
      );
    } else {
      setValid(
        data.user.email || (data.user.phone && data.user.phone.length > 3)
          ? true
          : false
      );

      setValid(
        (data.user.email || data.user.phone) &&
          data.address.zipCode.length === 6
          ? true
          : false
      );
    }
  }, [data]);

  return (
    <Accordion key={index} square>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={data.user.email}
        style={{
          backgroundColor: "rgba(0, 0, 0, .03)",
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
        }}
        onClick={() => {
          setExpanededPersonPanel(
            expanededPersonPanel.find((id) => id === data.id)
              ? expanededPersonPanel.filter((id) => id !== data.id)
              : [...expanededPersonPanel, data.id]
          );
        }}
      >
        <Grid container>
          {" "}
          <Grid
            item
            xs={12}
            container
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <span style={{ fontFamily: "inherit" }}>
                {data.user.authorities.includes(",") ? "Role: " : "Rola: "}
                <b>{roleToDisplay(data.user.authorities)}</b>
              </span>{" "}
              {!data.user.activated && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    activateFunction(data.id);
                    setOpenFormActivationPanel(true);
                  }}
                >
                  Aktywuj
                </Button>
              )}
            </Grid>
            {data.firstName && data.surname && (
              <Grid item>
                <span style={{ fontFamily: "inherit" }}>
                  Imię i nazwisko:{" "}
                  <b>
                    {data.firstName} {data.surname}
                  </b>
                  <br />
                </span>
              </Grid>
            )}
            <Grid item>
              {data.user.email ? (
                <label style={{ fontFamily: "inherit" }}>
                  Adres email: <b>{data.user.email}</b>
                </label>
              ) : (
                <label style={{ fontFamily: "inherit" }}>
                  Telefon: <b>{data.user.phone}</b>
                </label>
              )}
            </Grid>

            <Grid item>
              {data.user.authorities === "ROLE_USER" && (
                <Button
                  variant={"contained"}
                  size="small"
                  color="default"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/filled-forms/all/user/${data.id}`);
                  }}
                >
                  Pokaż wszystkie wyniki
                </Button>
              )}
            </Grid>

            {canSeeTerms && isUser && orgCurrentTerms && (
              <Grid item>
                <Box
                  component="span"
                  style={{
                    justifyItems: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontFamily: "inherit" }}>
                    Zaakceptowany regulamin?
                  </span>
                  {data.currentTermsAccepted ? (
                    <DoneIcon
                      size="small"
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <ClearIcon
                      size="small"
                      style={{
                        color: "red",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <Typography style={{ marginLeft: 15, fontWeight: "bold" }} variant="h6">
        Panel edycji
      </Typography>
      <Box p={1} display="flex" justifyContent="start" alignItems="start">
        {
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenConfirmPanel(false);
            }}
            open={openConfirmPanel}
            text={"Taki użytkownik już istnieje. Czy edytować jego dane?"}
            yesAction={() => {
              history.push(`/persons/${editedPerson.id}`);
              setUserToEdit(editedPerson);
            }}
            noAction={() => setOpenConfirmPanel(false)}
          />
        }
        <Tooltip title={"Identyfikator systemowy"}>
          <span
            style={{
              fontSize: "0.8rem",
              lineHeight: "1.4",
              color: "#999",
              marginLeft: 15,
            }}
          >
            {data?.id}
          </span>
        </Tooltip>
      </Box>
      {/* )} */}
      <AccordionDetails>
        <Grid>
          {expanededPersonPanel.find((id) => data.id === id) && (
            <PersonForm
              data={data}
              profileDataChangeHandler={profileDataChangeHandler}
              addressDataChangeHandler={addressDataChangeHandler}
              handleChecbox={handleChecbox}
              edit={true}
              dataIsUsed={dataIsUsed}
              isPhoneInvalid={isPhoneInvalid}
              isMailInvalid={isMailInvalid}
              isHospitalIdentifierInvalid={isHospitalIdentifierInvalid}
              setIsPhoneInvalid={setIsPhoneInvalid}
              setIsMailInvalid={setIsMailInvalid}
              setIsHospitalIdentifierInvalid={setIsHospitalIdentifierInvalid}
            />
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">Powiadomienia:</Typography>
            {data.preferredChannel === "mail" && (
              <Tooltip
                title="Preferowany kanał komunikacji - email"
                style={{ marginLeft: "4px" }}
              >
                <MailIcon />
              </Tooltip>
            )}
            {data.preferredChannel === "phone" && (
              <Tooltip
                title="Preferowany kanał komunikacji - telefon"
                style={{ marginLeft: "4px" }}
              >
                <PhoneIcon />
              </Tooltip>
            )}
            {!data.enableReminders && (
              <Tooltip title="Przypomnienia wyłączone">
                <NotificationsOffIcon />
              </Tooltip>
            )}
          </div>
          <Box mt={1} display="center">
            <Button
              variant={"contained"}
              color="primary"
              disabled={
                !checked ||
                !valid ||
                isPhoneInvalid ||
                isMailInvalid ||
                isHospitalIdentifierInvalid
              }
              onClick={() => {
                setOpenFormEditionPanel(true);
                editFunction(data);
              }}
            >
              Edytuj
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant={"contained"}
              color="secondary"
              onClick={() => {
                setOpenFormRemovingPanel(true);
                removeFunction(data);
              }}
            >
              Usuń
            </Button>
            {!data.user.activated && (
              <>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant={"contained"}
                  color="secondary"
                  onClick={() => {
                    dispatch(activateUser(data.id));
                  }}
                >
                  Aktywuj
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export const Persons = connect(
  stateToProps,
  dispatchToProps
)(
  ({
    persons,
    personsLength,
    fetchOrgUsersWithTerms,
    fetchOrgUsersPaged,
    roles,
    history,
    removeUser,
    activateUser,
    editUser,
    fetchUserAllAvailableForms,
    userAllAvailableForms,
    removedUser,
  }) => {
    const classes = useStyles();

    const [users, setUsers] = useState(
      persons.sort((a, b) =>
        a.user.authorities.localeCompare(b.user.authorities)
      ) || []
    );
    const my = useSelector((s) => s.my);
    const [pageNr, setPageNr] = useState(Cookies.get(`p[${my.user.id}]`) || 1);
    const [rowsPerPage, setRowsPerPage] = useState(
      Cookies.get(`users-per-page[${my.user.id}]`) || 5
    );
    const [orderBy, setOrderBy] = useState("user.authorities,-createdAt");
    const [order, setOrder] = useState("asc");

    // const [currentPage, setCurrentPage] = useState(1);
    // const [usersPerPage, setUsersPerPage] = useState(5);
    const [userRoleFilter, setuserRoleFilter] = useState("any");
    const [userCredentialFilter, setUserCredentialFilter] = useState("");
    const filterRequestId = useRef(0);

    const [busy, setBusy] = useState(false);
    const [userToDelete, setUserToDelete] = useState();
    const [userToActivateId, setUsertoActivateId] = useState();
    const [userToActivate, setUsertoActivate] = useState();
    const [userToEdit, setUserToEdit] = useState();
    const removeFunction = setUserToDelete;
    const activateFunction = setUsertoActivateId;
    const editFunction = setUserToEdit;

    const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);
    const [openFormEditionPanel, setOpenFormEditionPanel] = useState(false);
    const [openFormActivationPanel, setOpenFormActivationPanel] = useState(
      false
    );
    const [open, setOpen] = useState(false);
    const [roleFilterOpen, setRoleFilterOpen] = useState(false);
    const dispatch = useDispatch();
    const store = useSelector((s) => s);
    const canSeeTerms = !!my?.user.authorities.includes("ROLE_OWNER");
    const organization = useSelector((s) => s.organization);

    const [allReady, setAllReady] = useState(false);
    const [userForms, setUserForms] = useState([]);
    const [formsReady, setFormsReady] = useState(false);
    const isEditor = my.user.authorities.localeCompare("ROLE_EDITOR") === 0;

    useEffect(() => {
      const fetchUsers = async () => {
        setAllReady(false);
        if (checkPermission("admin:fetchOrgUsers", roles, store)) {
          if (canSeeTerms) {
            await fetchOrgUsersWithTerms({
              page:
                personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          } else {
            await fetchOrgUsersPaged({
              page:
                personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          }
          setAllReady(true);
        } else {
          setAllReady(true);
          history.push("/");
        }
      };
      if (removedUser) {
        fetchUsers();
      }
    }, [removedUser]);
    useEffect(() => {
      const fetchUsers = async () => {
        setAllReady(false);
        if (checkPermission("admin:fetchOrgUsers", roles, store)) {
          if (canSeeTerms) {
            await fetchOrgUsersWithTerms({
              page:
                personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          } else {
            await fetchOrgUsersPaged({
              page:
                personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          }
          setAllReady(true);
        } else {
          setAllReady(true);
          history.push("/");
        }
      };
      fetchUsers();
      if (userRoleFilter !== "any" || userCredentialFilter.length > 0) {
        Cookies.set(`p[${my.user.id}]`, 1, {
          sameSite: "strict",
        });
        setPageNr(1);
      }
    }, [userRoleFilter, userCredentialFilter]);

    useEffect(() => {
      if (
        Number.isInteger(personsLength / rowsPerPage) &&
        personsLength / rowsPerPage < pageNr &&
        pageNr > 1
      ) {
        Cookies.set(
          `p[${my.user.id}]`,
          parseInt(
            personsLength < rowsPerPage
              ? 0
              : personsLength == 0
              ? pageNr - 2
              : pageNr - 1
          ),
          {
            sameSite: "strict",
          }
        );
        setPageNr(
          parseInt(
            personsLength < rowsPerPage
              ? 0
              : personsLength == 0
              ? pageNr - 2
              : pageNr - 1
          )
        );
      }
    }, [personsLength]);

    useEffect(() => {
      setUsers(
        persons.sort((a, b) =>
          a.user.authorities.localeCompare(b.user.authorities)
        )
      );
    }, [persons]);

    useEffect(() => {
      if (userToActivateId) {
        setUsertoActivate(users.find(({ id }) => id === userToActivateId));
      }
    }, [userToActivateId]);

    useEffect(() => {
      organization && dispatch(fetchOrganizationCurrentTerms(organization.id));
    }, [organization?.id]);

    const profileDataChange = (value, user, fieldProperty) => {
      setUsers((prevState) =>
        prevState.map((tmpUser) =>
          user.id === tmpUser.id
            ? {
                ...tmpUser,
                [fieldProperty]: value,
                ...(fieldProperty === "phone" && {
                  address: {
                    ...tmpUser.address,
                    phone: value,
                  },
                }),
              }
            : tmpUser
        )
      );
    };

    const addressDataChange = (value, user, fieldProperty) => {
      setUsers((prevState) =>
        prevState.map((tmpUser) =>
          user.id === tmpUser.id
            ? {
                ...tmpUser,
                address: {
                  ...tmpUser.address,
                  [fieldProperty]:
                    fieldProperty.localeCompare("zipCode") === 0
                      ? normalizeZIP(value, tmpUser.address?.zipCode)
                      : value,
                },
              }
            : tmpUser
        )
      );
    };

    const handleChecbox = (event, user) => {
      setUsers(
        users.map((tmpUser) =>
          tmpUser.id === user.id
            ? {
                ...tmpUser,
                [event.target.name]: event.target.checked,
              }
            : tmpUser
        )
      );
    };

    const deleteUser = async () => {
      if (userToDelete) {
        setBusy(true);
        const result = await removeUser(userToDelete.id, canSeeTerms);
        setUserToDelete(null);
        setBusy(false);
      }
    };

    const editUserHandler = async (e) => {
      if (userToEdit) {
        setBusy(true);
        const response = await editUser({
          ...userToEdit,
          ...(userToEdit.phone
            ? userToEdit.phone.length > 3
              ? { phone: userToEdit.phone?.replace(/[^+\d]+/g, "") }
              : ""
            : {}),
          ...(userToEdit?.address?.phone
            ? userToEdit?.address?.phone.length > 3
              ? {
                  address: {
                    ...userToEdit?.address,
                    phone: userToEdit?.address?.phone?.replace(/[^+\d]+/g, ""),
                  },
                }
              : ""
            : {}),
        });
        const message = response.data.message;

        if (response.status === 200) {
          if (canSeeTerms) {
            await fetchOrgUsersWithTerms({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          } else {
            await fetchOrgUsersPaged({
              page: pageNr,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          }
          dispatch(
            alertAdd({
              text: "Edycja użytkownika przebiegła pomyślnie.",
              isSuccess: true,
            })
          );
          setUserToEdit(null);
        } else if (message.includes("Phone is not unique")) {
          dispatch(
            alertAdd({
              text:
                "Nie udało się dokonać edycji użytkownika. Podany numer telefonu istnieje już w systemie.",
              isError: true,
            })
          );
        } else if (message === "hospital-identifier-exists") {
          dispatch(
            alertAdd({
              text: "Wprowadzony identyfikator już istnieje",
              isError: true,
            })
          );
        } else if (message === "hospital-identifier-empty") {
          dispatch(
            alertAdd({
              text: "Wprowadzony identyfikator jest pusty",
              isError: true,
            })
          );
        }

        setBusy(false);
      }
    };

    const setUserActive = async () => {
      if (userToActivateId) {
        setBusy(true);
        const result = await activateUser(userToActivateId);
        setUsertoActivateId(null);
        setBusy(false);
      }
    };

    const handleChangePage = (event, newPage) => {
      Cookies.set(`p[${my.user.id}]`, parseInt(newPage) - 1, {
        sameSite: "strict",
      });
      setPageNr(parseInt(newPage) - 1);
    };

    const handleChangePageBack = () => {
      Cookies.set(`p[${my.user.id}]`, parseInt(pageNr) - 1, {
        sameSite: "strict",
      });
      setPageNr(parseInt(pageNr) - 1);
    };

    const handleChangePageForward = () => {
      Cookies.set(`p[${my.user.id}]`, parseInt(pageNr) + 1, {
        sameSite: "strict",
      });
      setPageNr(parseInt(pageNr) + 1);
    };

    const handleChangePageSelect = (event) => {
      Cookies.set(`p[${my.user.id}]`, parseInt(event.target.value), {
        sameSite: "strict",
      });
      setPageNr(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
      Cookies.set(`p[${my.user.id}]`, 1, {
        sameSite: "strict",
      });
      Cookies.set(
        `users-per-page[${my.user.id}]`,
        parseInt(event.target.value, 10),
        {
          sameSite: "strict",
        }
      );
      setRowsPerPage(parseInt(event.target.value, 10));

      setPageNr(1);
    };

    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    ///////////////////////
    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    const handleChangeRoleFilterSelect = (event) => {
      filterRequestId.current++;
      setuserRoleFilter(event.target.value);
    };

    const handleCloseRoleFilterSelect = () => {
      setRoleFilterOpen(false);
    };

    const handleOpenRoleFilterSelect = () => {
      setRoleFilterOpen(true);
    };

    const handleChangeUserCredentialFilter = (event) => {
      const filterQuery = event.target.value;
      filterRequestId.current++;
      const currentRequestId = filterRequestId.current;
      setTimeout(
        () =>
          currentRequestId == filterRequestId.current &&
          setUserCredentialFilter(filterQuery),
        1500
      );
    };

    useEffect(() => {
      const fetchUsers = async () => {
        setAllReady(false);
        if (checkPermission("admin:fetchOrgUsers", roles, store)) {
          if (canSeeTerms) {
            await fetchOrgUsersWithTerms({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          } else {
            await fetchOrgUsersPaged({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(userRoleFilter !== "any"
                ? { filterRole: userRoleFilter }
                : {}),
              ...(userCredentialFilter
                ? { filterProperty: userCredentialFilter }
                : {}),
            });
          }
          setAllReady(true);
        } else {
          setAllReady(true);
          history.push("/");
        }
      };
      fetchUsers();
    }, [roles, pageNr, rowsPerPage, order, orderBy]);

    useEffect(() => {
      if (userToDelete?.id) {
        setFormsReady(false);
        fetchUserAllAvailableForms(userToDelete.id);
      }
    }, [userToDelete]);

    useEffect(() => {
      if (userToDelete) {
        setUserForms(userAllAvailableForms);
        setFormsReady(true);
      }
    }, [userAllAvailableForms]);

    return (
      <Box>
        {userToDelete && formsReady && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormRemovingPanel(false);
              setUserToDelete(null);
              setUserForms([]);
            }}
            open={openFormRemovingPanel}
            text={
              <div>
                {`Czy na pewno usunąć ${
                  process.env.REACT_APP_USE_PATIENT === "true"
                    ? userToDelete.user.authorities.localeCompare(
                        "ROLE_EDITOR"
                      ) === 0
                      ? "lekarza "
                      : "pacjenta "
                    : userToDelete.user.authorities.localeCompare(
                        "ROLE_EDITOR"
                      ) === 0
                    ? "edytora "
                    : "użytkownika "
                }${userToDelete.firstName ? userToDelete.firstName : ""} ${
                  userToDelete.surname ? userToDelete.surname : ""
                } ${
                  userToDelete.user.email ? `(${userToDelete.user.email})` : ""
                }`}
                {userForms.length > 0
                  ? `, który należy do następujących aktywnych formularzy:`
                  : "?"}
                <ul style={{ listStyleType: "circle", marginLeft: "15px" }}>
                  {userForms.map((form, index) => (
                    <li key={index} style={{ fontSize: "14pt" }}>
                      {form}
                    </li>
                  ))}
                </ul>
                {isEditor &&
                  `${
                    process.env.REACT_APP_USE_PATIENT === "true"
                      ? "Pacjent"
                      : "Użytkownik"
                  } może należeć do ${
                    userForms.length > 0 ? "innych " : ""
                  }aktywnych formularzy.`}
              </div>
            }
            yesAction={deleteUser}
            noAction={() => {
              setUserToDelete(null);
              setUserForms([]);
            }}
          />
        )}
        {userToActivateId && userToActivate && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormActivationPanel(false);
              setUsertoActivateId(null);
            }}
            open={openFormActivationPanel}
            text={`Czy na pewno aktywować użytkownika (${
              userToActivate.user.email || userToActivate.user.phone
            })?`}
            yesAction={setUserActive}
            noAction={() => setUsertoActivateId(null)}
          />
        )}
        {userToEdit && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormEditionPanel(false);
              setUserToDelete(null);
            }}
            open={openFormEditionPanel}
            text={`Czy na pewno edytować użytkownika ${userToEdit.firstName} ${userToEdit.surname} (${userToEdit.user.email})?`}
            yesAction={editUserHandler}
            noAction={() => setUserToEdit(null)}
          />
        )}
        <Prompt
          when={JSON.stringify(users) !== JSON.stringify(persons)}
          message="Edytowane dane nie zostały zapisane, czy mimo to chcesz opuścić stronę?"
        />
        <Box p={1}>
          <PageTitle title="Członkowie organizacji" />
        </Box>

        <Box p={1}>
          <Grid
            item
            xs={12}
            container
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid
              item
              container
              xs={8}
              lg={10}
              justify="flex-start"
              alignItems="center"
            >
              {my.user?.authorities.indexOf("ROLE_OWNER") !== -1 && (
                <Grid item>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel id="open-role-filter-label">
                      Filtruj po roli
                    </InputLabel>
                    <Select
                      labelId="open-role-filter-label"
                      id="open-role-filter"
                      open={roleFilterOpen}
                      label={"Filtruj po roli"}
                      onClose={handleCloseRoleFilterSelect}
                      onOpen={handleOpenRoleFilterSelect}
                      onChange={handleChangeRoleFilterSelect}
                      value={userRoleFilter}
                    >
                      <MenuItem value="any">Dowolna</MenuItem>
                      <MenuItem value="ROLE_OWNER">
                        Właściciel organizacji
                      </MenuItem>
                      <MenuItem value="ROLE_EDITOR">
                        {process.env.REACT_APP_USE_PATIENT === "true"
                          ? "Lekarz"
                          : "Redaktor"}
                      </MenuItem>
                      <MenuItem value="ROLE_USER">
                        {process.env.REACT_APP_USE_PATIENT === "true"
                          ? "Pacjent"
                          : "Użytkownik"}
                      </MenuItem>
                    </Select>
                  </FormControl>{" "}
                </Grid>
              )}
              <Grid item>
                <TextField
                  // labelId="input-user-filter-label"
                  id="input-user-filter"
                  label="Filtruj po nazwie"
                  variant="outlined"
                  onChange={handleChangeUserCredentialFilter}
                ></TextField>
              </Grid>{" "}
              <Grid item> </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              lg={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {" "}
              <Button
                variant={"contained"}
                size="small"
                color="default"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push(`/person/add`);
                }}
              >
                Dodaj nowego użytkownika
              </Button>
              {/* <NavLink to="/person/add">Dodaj nowego użytkownika</NavLink> */}
            </Grid>
          </Grid>
          {!allReady ? (
            <Loader loading={true} text="Ładowanie" />
          ) : (
            users?.map(
              (user, index) =>
                !user.user.removedAt && (
                  <Person
                    key={user.id}
                    data={user}
                    removeFunction={removeFunction}
                    activateFunction={activateFunction}
                    editFunction={editFunction}
                    index={index}
                    profileDataChangeHandler={profileDataChange}
                    addressDataChangeHandler={addressDataChange}
                    handleChecbox={handleChecbox}
                    setOpenFormRemovingPanel={setOpenFormRemovingPanel}
                    setOpenFormEditionPanel={setOpenFormEditionPanel}
                    setOpenFormActivationPanel={setOpenFormActivationPanel}
                  />
                )
            )
          )}
          <br />
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <IconButton
              disabled={parseInt(pageNr) === 1}
              onClick={handleChangePageBack}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="open-select-label">Strona</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={"Strona"}
                value={parseInt(pageNr)}
                onChange={handleChangePageSelect}
              >
                {Array.apply(null, {
                  length: Math.ceil(personsLength / rowsPerPage),
                })
                  .map(Number.call, Number)
                  .map((element, index) => (
                    <MenuItem value={index + 1}>{element + 1}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <IconButton
              disabled={
                parseInt(pageNr) === Math.ceil(personsLength / rowsPerPage)
              }
              onClick={handleChangePageForward}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="open-select-label">
                Liczba użytkowników
              </InputLabel>
              <Select
                labelId="open-select-label"
                id="open-select"
                open={open}
                label={"Liczba użytkowników"}
                onClose={handleClose}
                onOpen={handleOpen}
                value={parseInt(rowsPerPage)}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"20"}>20</MenuItem>
                <MenuItem value={"50"}>50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Box>
      </Box>
    );
  }
);
